import type { ReactNode } from "react"
import { Modal as RNModal, View } from "react-native"

export function FastModal({ children, isOpen, backdropOpacity = 0.6 }: { children: ReactNode; isOpen: boolean; backdropOpacity?: number }) {
    return (
        <RNModal visible={isOpen} transparent>
            <View style={{ justifyContent: "center", alignItems: "center", display: "flex", height: "100%", width: "100%", backgroundColor: `rgba(0,0,0,${backdropOpacity})` }}>{children}</View>
        </RNModal>
    )
}
//{..._backdrop}
