export type ValueOf<T> = T extends any[] ? T[number] : T[keyof T]

type Constructor<T> = new (...args: any[]) => T

export class FmkUtils {
    static DefField(target: any, propName: string) {
        target.fields = target.fields ?? {}
        target.fields[propName] = undefined
    }

    static getFields<T>(fielsClass: Constructor<T>): { [K in keyof T]: K }
    static getFields<T>(fieldsArray: T): { [K in ValueOf<T> as K extends string ? K : never]: K }
    static getFields(xxx: any) {
        const obj = {} as any

        if (Array.isArray(xxx)) {
            xxx.forEach((key) => {
                obj[key] = key
            })
        } else if (xxx.prototype?.fields) {
            Object.keys(xxx.prototype?.fields).forEach((key) => {
                obj[key] = key
            })
        } else {
            throw new Error("defineFormikFields expext a class constructor or an array")
        }

        return obj
    }
}
