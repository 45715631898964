import { Lesson, rasmik } from "@core/services/rasmik"
import { DryLessonStatusEntries } from "@core/services/swaggerClient"
import { getShowedMonthStr } from "@core/utils"
import { DayDate } from "@core/utils/DayDate"
import { find, groupBy, map, sortBy } from "lodash"

import { arrayQueryHelpers, defineQuery } from "./utils"

/**
 * Les lessons DRAFT, Cancelled, Aborted, Expired du mois
 */
export const current_proposition_lessons_query = defineQuery("currentPropositionLessonsQuery", ({ teacherId }: { teacherId: number | null | undefined }) => ({
    key: ["current_proposition_lessons", { teacherId }],
    queryFn: async (qryCtx, auth) => {
        // console.debug('current_lessons start-' + (cpt++, cpt))
        await auth!.ensureValidToken()
        // console.debug('current_lessons token-checked-' + cpt)

        const currentMonth = getShowedMonthStr()
        let currentLessons = await rasmik
            .readMany(Lesson)
            .where({
                Match: { Teacher: teacherId },

                //On affiche soit les propositions annulées ou abandonnées du mois et des mois a venir
                //Ou les propositions en attente à venir et passées
                $or: [
                    {
                        monthPlannedFormula: { $gte: currentMonth },
                        status: [DryLessonStatusEntries.ABORTED, DryLessonStatusEntries.CANCELLED],
                    },
                    {
                        status: [DryLessonStatusEntries.DRAFT, DryLessonStatusEntries.BOOKED],
                    },
                ],
                startedAtPlanned: { $ne: null },
            })
            .run()
        currentLessons = sortBy(currentLessons, (lsn) => lsn.startedAtOfficial)
        const grouped = map(
            groupBy(currentLessons, (lsn) => lsn.Match),
            (matchLessons, matchId$) => ({ matchId: +matchId$, lessons: matchLessons })
        )
        return grouped
    },
    defaultOptions: { enabled: !!teacherId, useErrorBoundary: true, initialData: [] as any },
})).withHelpers((query) => ({
    findByMatchId(matchId: number) {
        return find(query.data, { matchId })?.lessons || []
    },
    findLessonById(lessonId: number) {
        return find(query.data?.flatMap((item) => item.lessons), { id: lessonId })
    },
    getAllLessons() {
        const currentLessons = query.data?.flatMap((item) => item.lessons)
        return sortBy(currentLessons, (lsn) => lsn.startedAtOfficial)
    },
    ...arrayQueryHelpers(query),
}))

export type CurrentPropositionLessonsQueryData = (typeof current_proposition_lessons_query)["dataShape"]
