import { getDeepestRoute } from "@core/config/check-deep-links"
import type { GlobStackParamsList } from "@core/config/Navigator"
import { useAuthContext } from "@core/context/auth"
import { user_info_query } from "@core/queries"
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs"
import { type NavigationProp, useLinkTo, useNavigation, useNavigationState, useRoute } from "@react-navigation/native"
import { createStackNavigator, type StackScreenProps } from "@react-navigation/stack"
import { DateTime } from "luxon"
import React, { useEffect } from "react"

import { AmbiantContainer } from "./ambiant/AmbiantContainer"
import { currentScreenStore } from "./ambiant/currentScreenStore"
import { EditSurveyScreen } from "./bilans-screen/EditSurveyScreen"
import { SurveysListScreen } from "./bilans-screen/SurveysListScreen"
import { History } from "./matchs-list-screen/HistoryScreen"
import { MatchListScreen } from "./matchs-list-screen/MatchListScreen"
import { MessagesScreen } from "./messages-screen/MessagesScreen"
import { NewProcessScreen } from "./NewProcessScreen"
import { OfferDetailScreen } from "./offers-screen/OfferDetailScreen"
import { OffersScreen } from "./offers-screen/OffersScreen"
import { AddLessonSelectMatchScreen } from "./saisie-screen/AddLessonSelectMatchScreen"
import { SaisieScreen } from "./saisie-screen/SaisieScreen"
import { SplashNotificationsScreen, useSplashScreenNotifications } from "./splash-notifs-screen"
import { TutoScreen } from "./TutoScreen"
import { EditInfoFormScreen } from "./user-screen/EditInfoForm"
import { EditScolarityFormScreen } from "./user-screen/EditScolarityScreen"
import { type Day, type DraftWeekDispo, EditWeekDispoFormScreen } from "./user-screen/EditWeekDispoForm"
import { EditYearDispoFormScreen } from "./user-screen/EditYearDispoForm"
import { PayslipsScreen } from "./user-screen/Payslips"
import { UserScreen } from "./user-screen/UserScreen"
import { Time, TimePickerScreen } from "./user-screen/WeekDispoScreenComponentAndUtils/TimePicker"

export type MainStackParamsList = {
    MainTabs: { screen?: keyof MainTabsParamList } | undefined
    EditSurvey: { surveyId: number }
    History: { matchId: number }
    Payslips: undefined
    Tuto: undefined
    Tuto_Auto: undefined
    Messages: undefined
    AddLessonSelectMatch: undefined
    NewProcess: undefined
    EditInfoForm: undefined
    EditScolarityForm: undefined
    EditWeekDispoForm: undefined
    EditYearDispoForm: undefined
    SplashNotifications: undefined
    TimePickerScreen: {
        handleDelete: (id: number) => void
        handleOnPress: (time: Time, day: Day, id: number | undefined) => void
        // label: string
        day: Day
        id: number | undefined
        fistPress: Time | null
        originalDispo: { start: string; end: string } | null
        sameDayDispos: DraftWeekDispo[]
    }
    OfferDetail: { matchId: number }

    // Login: { login?: string; password?: string }
    // ForgetPassword: { email: string }
    // LoginReset: { login?: string; password?: string }
}

export type MainTabsParamList = {
    SaisieHeures: undefined
    Offres: undefined
    SurveysList: undefined
    MatchList: undefined
    User: undefined
}

const MainStackNav = createStackNavigator<MainStackParamsList>()
const MainTabsNav = createMaterialTopTabNavigator<MainTabsParamList>()

export interface MainStackProps {}
export function MainStack(props: MainStackProps) {
    // const {} = props

    // const [lightText, darkText] = useToken("colors", ["lightText", "darkText"]);
    // const finishedTuto = useEzState((s) => s.persisted.finishedTuto)

    const auth = useAuthContext()
    const { userInfoQuery } = user_info_query.use({ teacherId: auth.userId })

    const shouldShowTuto = userInfoQuery.data && !userInfoQuery?.data.tutoViewedAt

    const showNewProcess = userInfoQuery.isFetched && !userInfoQuery.data?.newProcessEnabledAt && DateTime.now().toISODate()! < "2022-12-31"

    const navigation = useNavigation<NavigationProp<GlobStackParamsList>>()
    const route = useRoute()

    useEffect(
        () => {
            if (!auth.isLoggedIn) {
                console.log("Navigating to AuthStack from MainStack", route, navigation.getState(), getDeepestRoute(navigation), getDeepestRoute(navigation)?.path)
                navigation.navigate("AuthStack", { prev: getDeepestRoute(navigation)?.path })
            }
        } /* No deps array */
    )

    return (
        <MainStackNav.Navigator
            screenOptions={{ headerShown: false }}
            // initialRouteName='MainTabs'
            screenListeners={{
                state: (event) => {
                    // console.log("state event", event)
                },
            }}
        >
            {showNewProcess && <MainStackNav.Screen name="NewProcess" component={NewProcessScreen} />}

            {!showNewProcess && !shouldShowTuto && <MainStackNav.Screen name="MainTabs" component={MainTabs} />}

            {!showNewProcess && !shouldShowTuto && (
                <MainStackNav.Group screenOptions={{ headerShown: false }}>
                    <MainStackNav.Screen name="SplashNotifications" component={SplashNotificationsScreen} />
                    <MainStackNav.Screen name="EditSurvey" component={EditSurveyScreen} />
                    <MainStackNav.Screen name="History" component={History} />
                    <MainStackNav.Screen name="Payslips" component={PayslipsScreen} />
                    <MainStackNav.Screen name="EditInfoForm" component={EditInfoFormScreen} />
                    <MainStackNav.Screen name="EditScolarityForm" component={EditScolarityFormScreen} />
                    <MainStackNav.Screen name="EditWeekDispoForm" component={EditWeekDispoFormScreen} />
                    <MainStackNav.Screen name="EditYearDispoForm" component={EditYearDispoFormScreen} />
                    <MainStackNav.Screen name="Tuto" component={TutoScreen} />
                    <MainStackNav.Screen name="Messages" component={MessagesScreen} />
                    <MainStackNav.Screen name="AddLessonSelectMatch" component={AddLessonSelectMatchScreen} />
                    <MainStackNav.Screen name="TimePickerScreen" component={TimePickerScreen} />
                    <MainStackNav.Screen name="OfferDetail" component={OfferDetailScreen} />
                </MainStackNav.Group>
            )}

            {!showNewProcess && shouldShowTuto && <MainStackNav.Screen name="Tuto_Auto" component={TutoScreen} />}

            {/*
            <MainStackNav.Screen name="Login" component={LoginScreen} />
            <MainStackNav.Screen name="ForgetPassword" component={ForgetPasswordScreen} />
             */}
            {/* Ici aussi pour un login manuel */}
            {/* <MainStackNav.Screen name="LoginReset" component={LoginResetScreen} /> */}
            {/* <MainStackNav.Screen name="ForgetPassword" component={ForgetPasswordScreen} /> */}
        </MainStackNav.Navigator>
    )
}

function MainTabs(props: StackScreenProps<MainStackParamsList, "MainTabs">) {
    const { navigation } = props

    const { currentType } = useSplashScreenNotifications(navigation)
    const userAgent = navigator.userAgent

    // console.log(userAgent)

    useEffect(() => {
        if (currentType) {
            navigation.navigate("SplashNotifications")
        }
    })

    return (
        <AmbiantContainer>
            <MainTabsNav.Navigator
                tabBar={function TabBarCustom(props) {
                    return null
                }}
                screenListeners={{
                    swipeStart: () => {
                        console.debug("swipeStart")
                        currentScreenStore.mutateState((s) => {
                            s.isChanging = true
                        })
                    },
                    swipeEnd: () => {
                        console.debug("swipeEnd")
                        currentScreenStore.mutateState((s) => {
                            s.isChanging = false
                        })
                    },
                }}
            >
                <MainTabsNav.Screen
                    name="SaisieHeures"
                    component={SaisieScreen}
                    listeners={{
                        focus: () => {
                            currentScreenStore.mergeState({ isChanging: false, name: "SaisieHeures" })
                        },
                    }}
                />
                <MainTabsNav.Screen
                    name="Offres"
                    component={OffersScreen}
                    listeners={{
                        focus: () => {
                            currentScreenStore.mergeState({ isChanging: false, name: "Offres" })
                        },
                    }}
                />
                <MainTabsNav.Screen
                    name="SurveysList"
                    component={SurveysListScreen}
                    listeners={{
                        focus: () => {
                            currentScreenStore.mergeState({ isChanging: false, name: "SurveysList" })
                        },
                    }}
                />
                <MainTabsNav.Screen
                    name="MatchList"
                    component={MatchListScreen}
                    listeners={{
                        focus: () => {
                            currentScreenStore.mergeState({ isChanging: false, name: "MatchList" })
                        },
                    }}
                />
                <MainTabsNav.Screen
                    name="User"
                    component={UserScreen}
                    listeners={{
                        focus: () => {
                            currentScreenStore.mergeState({ isChanging: false, name: "User" })
                        },
                    }}
                />
            </MainTabsNav.Navigator>
        </AmbiantContainer>
    )
}
