import { HStack, Switch, Text, useColorMode } from "native-base"
import React from "react"

// Color Switch Component
export function ToggleDarkMode() {
    const { colorMode, toggleColorMode } = useColorMode()
    return (
        <HStack space={2} alignItems="center">
            <Text>Sombre</Text>
            <Switch isChecked={colorMode === "light"} onToggle={toggleColorMode} aria-label={colorMode === "light" ? "thème sombre" : "thème clair"} />
            <Text>Clair</Text>
        </HStack>
    )
}
