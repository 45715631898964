import { getActionFromState, getPathFromState, getStateFromPath, type NavigationAction, type NavigationContainerRef, type NavigationProp, type NavigationState, type PartialState } from "@react-navigation/native"
import { createRef } from "react"
// import { linking } from './linking';
// export const DeepLinkSchema = 'RNDeepLinkAuth://';
// export const navigationRef = createRef<NavigationContainerRef<any>>();
// const cleanPathStr = (path: string) => {
//     const queryVairablesIndex = path.indexOf('?');
//     if (queryVairablesIndex === -1) {
//         return path;
//     }
//     return path.substr(0, queryVairablesIndex);
// };
// export const checkDeepLinkResult = (url: string) => {
//     const extractedUrl = url.replace(DeepLinkSchema, '');
//     const currentState = navigationRef.current?.getRootState() as NavigationState;
//     const linkState = getStateFromPath(
//         extractedUrl,
//         linking.config as any,
//     ) as PartialState<NavigationState>;
//     const currentPath = cleanPathStr(getPathFromState(currentState));
//     const linkPath = cleanPathStr(getPathFromState(linkState));
//     const action = getActionFromState(linkState) as NavigationAction;
//     return {
//         action,
//         linkPath,
//         didDeepLinkLand: currentPath === linkPath,
//     };
// };

type NavigationRoute = NavigationState["routes"][number]
export const getDeepestRoute = (navigation: NavigationProp<any>) => {
    const state = navigation.getState()

    const currentRoute = state.routes[state.index]
    const deepestRoute = recursiveRoute(currentRoute)
    return deepestRoute
}

const recursiveRoute = (route: NavigationRoute): NavigationRoute => {
    const nestedRoute = route.state?.routes?.[route.state.routes.length - 1] as NavigationRoute
    if (nestedRoute) {
        return recursiveRoute(nestedRoute)
    } else return route
}
