import type { IButtonProps } from "native-base"
import React, { type ReactNode } from "react"

export type MsgBoxButtons = {
    [result: string]: string | MsgBoxButtonConfig
}

export type MsgBoxButtonConfig = {
    label: string
    buttonProps: IButtonProps
}

export type InitMsgBoxPayload = {
    title: string
    msg: string | ReactNode
    buttons: MsgBoxButtons
}

export type MsgBoxContextValue = {
    state: MsgBoxState
    actions: MsgBoxActions
}

export type MsgBoxState = {
    isOpen: boolean
    title: string
    msg: string | ReactNode
    buttons: MsgBoxButtons
}

export type MsgBoxActions = {
    init<T extends Partial<InitMsgBoxPayload>>(payload: T): Promise<T["buttons"] extends object ? Extract<keyof T["buttons"], string> : "cancel" | "accept">
    // init(payload: Partial<InitMsgBoxPayload>): Promise<unknown>
    handleClick(result: string): void
}

export const MsgBoxContext = React.createContext<MsgBoxContextValue>({ state: {}, actions: {} } as any)
