import { ScrollViewGrow } from "@core/atoms"
import { useAuthContext } from "@core/context/auth"
import { useBoolean, useCatchToBoundary, useSpinner } from "@core/hooks"
import { useAccordion } from "@core/hooks/useAccordion"
import { ContentContainer, ContentContainerColumn } from "@core/misc-components/ContentContainer"
import { LoadingScreen } from "@core/organism/LoadingScreen"
import { match_infos_list_query } from "@core/queries/match_infos_list"
import { propositions_list_query } from "@core/queries/propositions_list"
import type { MatchStatus } from "@core/services/rasmik-client"
import type { StackScreenProps } from "@react-navigation/stack"
import { sortBy } from "lodash"
import { Box, Center, HStack, Switch, Text, VStack } from "native-base"
import React from "react"
import { RefreshControl } from "react-native-gesture-handler"

import type { MainTabsParamList } from "../MainStack"
import { MatchListCard } from "./MatchListCard"
// import { TPropMatchCard } from "./TPropMatchCard"

/**
 * Liste des matchs que le tuteur a accepté (tuteur attribué)
 * Ils peuvent être actifs, terminés ou en attente du cient ou abandonnés
 */

export function MatchListScreen({ navigation }: StackScreenProps<MainTabsParamList, "MatchList">) {
    const auth = useAuthContext()

    const { matchInfosListQuery } = match_infos_list_query.use({ teacherId: auth.userId })
    const { propositionsListQuery } = propositions_list_query.use({ teacherId: auth.userId })

    const { expandedIndex, expand, collapse } = useAccordion({ multiple: false })

    const [showAll, { toggle: toggleShowAll }] = useBoolean(false)

    const scrollRefreshSpinner = useSpinner()
    const onRefresh = useCatchToBoundary(async () => {
        // console.debug('MatchListScreen scroll refresh')
        try {
            await auth.ensureValidToken()
            scrollRefreshSpinner.start()
            await Promise.all([matchInfosListQuery.refetch(), propositionsListQuery.refetch()])
        } finally {
            scrollRefreshSpinner.stop()
        }
    }, [auth, scrollRefreshSpinner, matchInfosListQuery, propositionsListQuery])

    if (matchInfosListQuery.isLoading || propositionsListQuery.isLoading) return <LoadingScreen />
    const tprops = propositionsListQuery.data!.pendingPropositions.concat(propositionsListQuery.data!.deadPropositions)

    let filteredMatches = showAll ? matchInfosListQuery.data?.allMatches : matchInfosListQuery.data?.realMatches.filter((match) => match.status === "ACTIVE").concat(matchInfosListQuery.data?.draftAcceptedMatches)

    filteredMatches = sortBy(filteredMatches, [(match) => sortStatusValue(match.status), (match) => -(match.lastLessonAt?.valueOf() ?? 0), (match) => -(match.teacherActivatedAt?.valueOf ?? 0)])

    return (
        <VStack safeAreaBottom width="100%" h="100%">
            <ContentContainer>
                <HStack paddingTop="20px" paddingBottom="5px">
                    <Switch isChecked={showAll} onToggle={toggleShowAll} marginRight="10px" />
                    <Text>Afficher les matchs inactifs</Text>
                </HStack>
            </ContentContainer>

            {filteredMatches?.length ? (
                <ScrollViewGrow refreshControl={<RefreshControl refreshing={scrollRefreshSpinner.loading} onRefresh={onRefresh} />}>
                    <ContentContainer space="15px" paddingY="15px" alignItems="center">
                        {/* {tprops.map((tprop, tpropMatchIndex) => {
                        return <TPropMatchCard key={tprop.id} tprop={tprop} isCollapsed={tpropMatchIndex !== expandedIndex} expand={expand} collapse={collapse} listIndex={tpropMatchIndex} />
                    })} */}

                        {filteredMatches.map((matchInfo, matchIndex) => {
                            return (
                                <MatchListCard
                                    navigation={navigation}
                                    key={matchInfo.id}
                                    matchInfo={matchInfo}
                                    isCollapsed={matchIndex + tprops.length - 1 !== expandedIndex}
                                    listIndex={matchIndex + tprops.length - 1}
                                    expand={expand}
                                    collapse={collapse}
                                />
                            )
                        })}
                    </ContentContainer>
                </ScrollViewGrow>
            ) : (
                <NoMatchMessage showAll={showAll} />
            )}
        </VStack>
    )
}

function sortStatusValue(status: MatchStatus) {
    switch (status) {
        case "ACTIVE":
            return 1
        case "DRAFT":
            return 2
        case "PAUSED":
        case "FINISHED":
            return 3
        default:
            return 4
    }
}

function NoMatchMessage({ showAll }: { showAll: boolean }) {
    return (
        <ContentContainerColumn paddingY="15px" alignItems="center" flex={1}>
            <Center flexGrow={1}>
                <Box p="20px" borderRadius="10px" _light={{ bgColor: "light.200" }} _dark={{ bgColor: "dark.200" }}>
                    <Text textAlign="center" _light={{ color: "light.700" }} _dark={{ color: "dark.700" }}>
                        Aucun match{showAll ? " " : " actif "}pour le moment.
                    </Text>
                </Box>
            </Center>
        </ContentContainerColumn>
    )
}
