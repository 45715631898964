import { FastModal } from "@core/atoms"
import { type DialogLogic, useDialog } from "@core/hooks"
import { ContentContainerColumn, ContentContainerRow } from "@core/misc-components/ContentContainer"
import { FontAwesome5 } from "@expo/vector-icons"
import type { StackScreenProps } from "@react-navigation/stack"
import { isNil } from "lodash"
import { Duration } from "luxon"
import { Badge, Button, type ColorMode, Heading, HStack, type IButtonProps, Icon, type IInputProps, type IModalProps, Modal, Pressable, Spacer, useColorMode, useColorModeValue, useTheme, ZStack } from "native-base"
import { ChevronDownIcon, CloseIcon, IconButton, Input } from "native-base"
import { ScrollView } from "react-native"

import { ItemOption } from "./ComboBox"

const START_HOUR = 8
const END_HOUR = 23

const colorScheme = "blue"
const colors = {
    text: {
        lm: `#535353`,
        dm: `${colorScheme}.100`,
    },
    textSelected: {
        lm: `${colorScheme}.900`,
        dm: `${colorScheme}.50`,
    },
    normal: {
        lm: `#00000008`,
        dm: `#313131`,
    },
    hover: {
        lm: `${colorScheme}.400`,
        dm: `${colorScheme}.700`,
    },
    inRange: {
        lm: `${colorScheme}.200`,
        dm: `${colorScheme}.800`,
    },
    selected: {
        lm: `${colorScheme}.400`,
        dm: `${colorScheme}.700`,
    },
}

export type TimePickerInputProps = {
    /** minutes from midnight */
    isDisabled?: boolean
    minutes: number | null
    onChange: (newValue: number | null) => void
    modalHeader?: string
    clearable?: boolean
    _input?: IInputProps
    _modal?: IModalProps
}

export function TimePickerInput({ minutes, onChange, _input, _modal, clearable, modalHeader, isDisabled }: TimePickerInputProps) {
    const modalLogic = useDialog()

    return (
        <>
            <ZStack>
                <Input
                    value={isNil(minutes) ? "" : formatTimeMin(minutes)}
                    InputRightElement={
                        <HStack flexGrow={1} justifyContent="flex-end">
                            <ChevronDownIcon size={6} style={{ color: "rgb(163, 163, 163)", padding: 4, marginRight: 4 }} />
                        </HStack>
                    }
                    {..._input}
                />
                <Pressable isDisabled={isDisabled} onPress={() => modalLogic.open()} w="full" h="full" style={{ backgroundColor: "white", opacity: 0 }} />
            </ZStack>
            {modalLogic.isOpen && <TimePickerInputDialog onChange={onChange} minutes={minutes} modalHeader={modalHeader} _modal={_modal} clearable={clearable} modalLogic={modalLogic} />}
        </>
    )
}

type TimePickerInputDialogProps = {
    minutes: number | null
    onChange: (newValue: number | null) => void
    modalHeader?: string
    clearable?: boolean
    _modal?: IModalProps
    modalLogic: DialogLogic
}

function TimePickerInputDialog({ minutes, onChange, modalHeader, modalLogic }: TimePickerInputDialogProps) {
    const eligibleTimes = getEligibleTimes()
    const getHandlePress = (timeMin: number) => {
        return () => {
            onChange(timeMin)
            modalLogic.hide()
        }
    }
    const theme = useTheme()
    const iconColor = useColorModeValue(theme.colors.darkText, theme.colors.lightText)
    const bgColorSelected = useColorModeValue(colors.selected.lm, colors.selected.dm)
    const bgColorNormal = useColorModeValue(colors.normal.lm, colors.normal.dm)
    return (
        <FastModal isOpen backdropOpacity={0.6}>
            <Modal.Content width="350px" position="relative">
                <IconButton icon={<CloseIcon size="xs" color={iconColor} />} onPress={modalLogic.hide} position="absolute" top="10px" right="5px" zIndex={1} />
                {modalHeader && <Modal.Header>{modalHeader}</Modal.Header>}

                <Modal.Body>
                    {/* <ScrollView style={{ width: "100%", height: 0 }} contentContainerStyle={{ flexGrow: 1, width: "100%" }}> */}
                    <ContentContainerColumn space={2} marginTop={1}>
                        {eligibleTimes.map((value) => {
                            return (
                                <HStack mt="2" key={value[0].toString()} space={2} justifyContent="space-between">
                                    {value[0] && <TimeButton selected={minutes === value[0]} timeMin={value[0]} onPress={getHandlePress(value[0])} time={value[0]} />}
                                    {value[1] && <TimeButton selected={minutes === value[1]} timeMin={value[1]} onPress={getHandlePress(value[1])} time={value[1]} />}
                                    {value[2] && <TimeButton selected={minutes === value[2]} timeMin={value[2]} onPress={getHandlePress(value[2])} time={value[2]} />}
                                    {value[3] && <TimeButton selected={minutes === value[3]} timeMin={value[3]} onPress={getHandlePress(value[3])} time={value[3]} />}
                                </HStack>
                            )
                        })}
                    </ContentContainerColumn>
                    {/* </ScrollView> */}
                </Modal.Body>
            </Modal.Content>
        </FastModal>
    )
}

function TimeButton({
    timeMin,
    selected,
    ...props
}: IButtonProps & {
    selected?: boolean
    timeMin: number
}) {
    const { colorMode } = useColorMode()
    // const variant = props.firstPress?.equalsTo(props.time) ? "solid" : "subtle"
    // const textStyle = { color: isInTimeRange(props.originalDispo, props.time) ? getThemedColor("textSelected", colorMode) : getThemedColor("text", colorMode) }
    // const isInSameDayTimeRangeVar = isInSameDayTimeRange(props.time, props.sameDayDispo, props.id)
    // const isOverlappingVar = isOverlapping(props.time, props.sameDayDispo, props.id, props.firstPress)
    // const isDisabled = props.time.compareTo(props.firstPress) < 0 || isOverlappingVar
    // const hoverStyle = colorMode === "light" ? colors.hover.lm : colors.hover.dm
    // const bgColor = getButtonColorVariant(props.originalDispo, props.time, props.firstPress, colorMode, isInSameDayTimeRangeVar)
    const bgColor = colors.normal

    return (
        <Button
            isPressed={selected}
            {...props}
            // variant={variant}
            // bgColor={bgColor}
            // _text={textStyle}
            width="20%"
            height="32px"
            flexGrow={1}
        >
            {formatTimeMin(timeMin)}
        </Button>
    )
}

/**
 * retourne la couleur souhaitée selon le colormode (light ou dark)
 * @param kind type de la couleur voulue
 * @param colorMode light ou dark
 * @returns la couleur selon le mode
 */
export function getThemedColor(kind: keyof typeof colors, colorMode: ColorMode) {
    if (colorMode === "dark") return colors[kind].dm
    else return colors[kind].lm
}

//

/**
 * retourne toutes les heures à afficher dans les boutons, de 8h à 22h
 * @returns un tableau à deux dimension de toute les boutons à afficher
 */
function getEligibleTimes(): number[][] {
    const res: number[][] = []
    const startTimeMin = START_HOUR * 60
    const endTimeMin = END_HOUR * 60

    let currentTime = startTimeMin
    do {
        const tempRes: number[] = []
        for (let index = 0; index < 4; index++) {
            tempRes.push(currentTime)
            currentTime += 15
        }
        res.push(tempRes)
    } while (currentTime < endTimeMin)
    return res
}

// export class Time {
//     seconds: number
//     minutes: number
//     hours: number

//     constructor(init:{hours?: number, minutes?: number, seconds?: number}) {

//         if ((init.hours ?? 0) > 24) {
//             throw new Error("Invalid Time : hours must be below 24")
//         }

//         this.hours = init.hours ?? 0

//         this.hours += Math.floor((init.minutes ?? 0) / 60)
//         this.minutes += (init.minutes ?? 0) % 60

//         const hourSeconds = Math.floor((init.seconds ?? 0) / 3600)
//         this.hours += hourSeconds
//         this.minutes = Math.floor(((init.seconds ?? 0) / 60) - hourSeconds * 60)
//         this.seconds += (init.seconds ?? 0) % 60
//     }

//     toString(): string {
//         let hoursString = ""
//         let minutesString = ""
//         let secondsStrings = ""

//         if (this.hours < 10) {
//             hoursString = `0${this.hours}`
//         } else {
//             hoursString = this.hours.toString()
//         }

//         if (this.minutes < 10) {
//             minutesString = `0${this.minutes}`
//         } else {
//             minutesString = this.minutes.toString()
//         }

//         if (this.seconds < 10) {
//             secondsStrings = `0${this.seconds}`
//         } else {
//             secondsStrings = this.seconds.toString()
//         }

//         return `${hoursString}:${minutesString}:${secondsStrings}`
//     }

//     toStringISO(): string {
//         return formatISOTime(this.toString())
//     }

//     add15Minutes(): void {
//         if (this.minutes + 15 < 60) {
//             this.minutes += 15
//             return
//         }
//         this.minutes = 0
//         if (this.hours + 1 < 24) {
//             this.hours += 1
//             return
//         }
//         this.hours = 0
//     }

//     equalsTo(time: Time | null): boolean {
//         if (!time) return false
//         return this.hours === time.hours && this.minutes === time.minutes && this.seconds === time.seconds
//     }

//     compareTo(time: Time | null): number {
//         if (!time) return 1
//         if (this.hours - time.hours !== 0) return this.hours - time.hours
//         if (this.minutes - time.minutes !== 0) return this.minutes - time.minutes
//         if (this.seconds - time.seconds !== 0) return this.seconds - time.seconds
//         return 0
//     }

//     copy(): Time {
//         return new Time({ hours: this.hours, minutes: this.minutes, seconds: this.seconds })
//     }
// }

export function formatTimeMin(timeMin: number | null | undefined) {
    if (timeMin === null || timeMin === undefined) return ""
    else return Duration.fromObject({ minutes: timeMin }).toFormat("h'h'mm").replace("h00", "h")
}
