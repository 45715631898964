import { Card } from "@core/atoms/Card"
import { useAuthContext } from "@core/context/auth"
import { useCatchToBoundary, useSpinner } from "@core/hooks"
import { ContentContainer, ContentContainerColumn } from "@core/misc-components/ContentContainer"
import { LoadingScreen } from "@core/organism/LoadingScreen"
import { match_infos_list_query } from "@core/queries"
import { monthly_match_surveys_query } from "@core/queries/monthly_match_surveys"
import { de_ } from "@core/utils/textUtils"
import { toFrenchRelativeCalendar } from "@core/utils/toFrenchRelativeCalendar"
import { FontAwesome5 } from "@expo/vector-icons"
import { type NavigationProp, useNavigation } from "@react-navigation/native"
import type { StackScreenProps } from "@react-navigation/stack"
import { DateTime } from "luxon"
import { Alert, Box, Center, Heading, HStack, Icon, Skeleton, Stack, Text, useTheme, VStack } from "native-base"
import React from "react"
import { RefreshControl, ScrollView } from "react-native"

import type { MainStackParamsList, MainTabsParamList } from "../MainStack"

export function SurveysListScreen({ navigation, route }: StackScreenProps<MainTabsParamList, "SurveysList">) {
    const auth = useAuthContext()

    const { monthlyMatchSurveysQuery } = monthly_match_surveys_query.use({ status: "DRAFT", teacherId: auth.userId })
    const scrollRefreshSpinner = useSpinner()
    const onRefresh = useCatchToBoundary(async () => {
        console.debug("MatchListScreen scroll refresh")
        try {
            await auth.ensureValidToken()
            scrollRefreshSpinner.start()
            await monthlyMatchSurveysQuery.refetch()
        } finally {
            scrollRefreshSpinner.stop()
        }
    }, [auth, scrollRefreshSpinner, monthlyMatchSurveysQuery])
    if (monthlyMatchSurveysQuery.isLoading) return <LoadingScreen />

    return (
        <VStack safeAreaBottom width="100%" h="100%" display="flex" alignItems="stretch" justifyContent="flex-start">
            <ScrollView
                style={{ width: "100%", height: 0 }}
                contentContainerStyle={{ flexGrow: 1, width: "100%", paddingVertical: 10 /*, marginBottom:"50px" */ }}
                refreshControl={<RefreshControl refreshing={scrollRefreshSpinner.loading} onRefresh={onRefresh} />}
            >
                <ContentContainer my="5px" space="15px">
                    {/** @ts-ignore */}
                    <InfoAlert>
                        La saisie des bilans mensuels est attendue du 25 au dernier jour du mois.{"\n"}Si tu as cessé les cours avec un élève, tu pourras le renseigner dans le bilan.{"\n"}Ainsi nous ne te demanderons plus de
                        bilans les mois suivants.
                    </InfoAlert>
                </ContentContainer>
                {monthlyMatchSurveysQuery.data?.length ? monthlyMatchSurveysQuery.data?.map((mms) => <SurveyCard key={mms.id} surveyId={mms.id} />) : <NoRequiredBilanMessage />}
            </ScrollView>
        </VStack>
    )
}

function CardSkeletton() {
    return (
        <ContentContainer>
            <Card my="10px" height="170px">
                <Skeleton.Text px="4" />
            </Card>
        </ContentContainer>
    )
}

function InfoAlert({ children }: { children: string }) {
    return (
        <Alert /* status="info"  */ colorScheme="info" variant="left-accent">
            <VStack space={2} flexShrink={1} w="100%">
                <HStack flexShrink={1} space={2} alignItems="center" justifyContent="space-between">
                    <HStack flexShrink={1} space={2} alignItems="center">
                        <Alert.Icon />
                        <Text>{children}</Text>
                    </HStack>
                </HStack>
            </VStack>
        </Alert>
    )
}

function SurveyCard({ surveyId }: { surveyId: number }) {
    const auth = useAuthContext()
    const theme = useTheme()

    const { matchInfosListQuery } = match_infos_list_query.use({ teacherId: auth.userId })
    const { monthlyMatchSurveysQuery } = monthly_match_surveys_query.use({ status: "DRAFT", teacherId: auth.userId })

    const navigation = useNavigation<NavigationProp<MainTabsParamList>>()
    const parentNavigation = navigation.getParent<NavigationProp<MainStackParamsList>>()

    const openEditScreen = useCatchToBoundary(() => parentNavigation.navigate("EditSurvey", { surveyId }))

    if (matchInfosListQuery.isLoading || monthlyMatchSurveysQuery.isLoading) return <CardSkeletton />

    const mms = monthlyMatchSurveysQuery.helpers.findBy({ id: surveyId })
    const match = matchInfosListQuery.helpers.findById(mms?.Match)

    if (!mms || !match) return <CardSkeletton />

    const surveyMonthDt = DateTime.fromISO(mms.month)
    const nextMonthDt = surveyMonthDt.plus({ month: 1 })
    const isLocked = monthlyMatchSurveysQuery.helpers.isMonthLocked(mms.month)

    return (
        <ContentContainer>
            <Card isDisabled={isLocked} my="10px" onPress={openEditScreen} style={{ position: "relative" }}>
                <Box style={{ position: "absolute", right: 15, top: 15 }}>
                    {isLocked ? (
                        <HStack space="10px">
                            <Text>A partir du 25</Text>
                            <Icon as={FontAwesome5} name="hourglass-start" size="20px" color="light.600" _dark={{ color: "dark.600" }} />
                        </HStack>
                    ) : (
                        <Icon as={FontAwesome5} name="pen" size="20px" color="light.600" _dark={{ color: "dark.600" }} />
                    )}
                </Box>

                <Stack /* p="4" */ space={3}>
                    <Stack space={2}>
                        <Heading size="md">
                            {match?.Program.Learner.firstName} {match?.Program.Learner.lastName}
                        </Heading>
                        <Text>Bilan {de_(surveyMonthDt.toFormat("MMMM"))}</Text>
                        <Text fontSize="xs" _light={{ color: "primary.500" }} _dark={{ color: "primary.400" }} fontWeight="500" mt="-1">
                            {match?.label}
                        </Text>

                        <Stack>
                            <>
                                {mms?.isComplete && (
                                    <HStack space="5px">
                                        <Icon as={FontAwesome5} name="check" size="18px" color={theme.colors.success[500]} />
                                        <Text>Questionnaire complet</Text>
                                    </HStack>
                                )}
                                {!mms?.isComplete && (
                                    <HStack space="5px">
                                        <Icon as={FontAwesome5} name="times" size="24px" color={theme.colors.error[500]} />
                                        <Text>Questionnaire à compléter</Text>
                                    </HStack>
                                )}
                            </>
                            <>
                                {!mms?.isFinishing && (
                                    <HStack space="5px">
                                        <Icon as={FontAwesome5} name="step-forward" size="18px" color={theme.colors.info[500]} />
                                        <Text>Les cours se poursuivront en {nextMonthDt.toFormat("MMMM")}</Text>
                                    </HStack>
                                )}
                                {mms?.isFinishing && (
                                    <HStack space="5px">
                                        <Icon as={FontAwesome5} name="pause" size="18px" color={theme.colors.warning[500]} />
                                        <Text>Les cours se sont arretés en {surveyMonthDt.toFormat("MMMM")}</Text>
                                    </HStack>
                                )}
                            </>
                        </Stack>
                    </Stack>

                    <Text fontSize="xs" color="coolGray.600" _dark={{ color: "warmGray.200" }} fontWeight="400">
                        {match.lastLessonAt ? `Dernier cours ${toFrenchRelativeCalendar(DateTime.fromJSDate(match.lastLessonAt))}` : "Nouveau"}
                    </Text>
                </Stack>
            </Card>
        </ContentContainer>
    )
}

function NoRequiredBilanMessage() {
    return (
        <ContentContainerColumn paddingY="15px" alignItems="center" flex={1}>
            <Center flexGrow={1}>
                <Box p="20px" borderRadius="10px" _light={{ bgColor: "light.200" }} _dark={{ bgColor: "dark.200" }}>
                    <Text textAlign="center" _light={{ color: "light.700" }} _dark={{ color: "dark.700" }}>
                        Aucun bilan à compléter pour le moment.
                    </Text>
                </Box>
            </Center>
        </ContentContainerColumn>
    )
}
