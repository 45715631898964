export function s(nb: number) {
    return nb === 1 ? "" : "s"
}

export function plural(singularForm: string, pluralForm: string, nb: number) {
    return nb === 1 ? singularForm : pluralForm
}

export function genderal(masculineForm: string, feminineForm: string, gender: "M" | "F") {
    return gender === "M" ? masculineForm : feminineForm
}

export function conditional(str: string, condition: boolean) {
    return condition ? str : ""
}

export function de_(word: string) {
    return ["a", "e", "i", "o", "u", "h"].some((letter) => letter.localeCompare(word[0], "fr", { sensitivity: "base" }) === 0) ? `d'${word}` : `de ${word}`
}

export function txtmap<T extends string>(key: T, map: Record<T, string>) {
    return map[key] ?? ""
}
