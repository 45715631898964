import type { GlobStackParamsList } from "@core/config/Navigator"
import { useAuthContext } from "@core/context/auth"
import type { StackScreenProps } from "@react-navigation/stack"
import { useEffect } from "react"

export function LoginResetScreen({ navigation, route }: StackScreenProps<GlobStackParamsList, "LoginReset">) {
    const { params } = route

    const auth = useAuthContext() //?? authCtx

    if (params && params.login && params.password) {
        auth.autofillRef.current = { email: params.login, password: params.password }
    }

    useEffect(() => {
        if (auth.isLoggedIn) {
            // navigation.popToTop()
            // navigation.navigate('MainTabs')
            auth.eraseToken()
        }
        // console.log("Navigating to AuthStack from LoginResetScreen", auth.autofillRef.current)
        navigation.reset({
            index: 0,
            routes: [{ name: "MainStack" }],
        })
    })

    return null
    // // if(!auth ) throw new Error('auth is ' + auth + ' in ' + type)
    // // console.debug('LoginScreenContent -> auth is ' + (auth ? 'ok' : auth) +  ' in ' + type + ' at ' + Math.round((new Date().valueOf() - new Date('2022-11-29T15:27:00').valueOf()) / 100))

    // console.log('rendering LoginResetScreen')
    // //auto logout
    // useEffect(() => {
    //     console.log("effect in LoginResetScreen", { params })
    //     if (params && params.login && params.password) {
    //         auth.autofillRef.current = { email: params.login, password: params.password }
    //     }
    //     if (auth.isLoggedIn) {
    //         // navigation.popToTop()
    //         // navigation.navigate('MainTabs')
    //         auth.eraseToken()
    //     }
    //     // } else {
    //     //     navigation.navigate('AuthStack', { login: params.login, password: params.password })
    //     // }
    // }, [auth, auth.isLoggedIn, params, navigation])

    // return (
    //     <Center w="100%" h="100%" safeArea>
    //         <Heading size="lg" p="30" fontWeight="600" color="coolGray.800" _dark={{ color: "warmGray.50" }} alignSelf="center">
    //             Déconnexion ...
    //         </Heading>
    //     </Center>
    // )
}
