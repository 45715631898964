import { rasmik, Teacher } from "@core/services/rasmik"
import { sortBy } from "lodash"
import { DateTime } from "luxon"

import { defineQuery } from "./utils"

// class CustomArray<T> extends Array<T> {
//     get array(){return this as Array<T>}
//     getLessonsByMatchId(matchId: number) {
//         return find(this.array, { matchId })?.lessons ?? []
//     }
// }

const teacherReadOptions = rasmik.define.readOptions(Teacher).val({
    include: ["*", "teachingsLevelsGroupedPopulated"],
    children: {
        MainPhoneNumber: { include: ["id", "number", "role"] },
        WeekDispos: { include: ["id", "dayId", "startTime", "endTime"] },
        Addresses: true,
        PhoneNumbers: { include: ["id", "number", "role"] },
        MainCity: true,
        MainAddress: true,
        YearDispos: { include: ["id", "startOn", "endOn"] },
        ScolarityMain: true,
        ScolarityComplement: true,
        StudyLevel: true,
        Mobilities: {
            include: ["id", "name"],
        },
    },
})

export const user_info_query = defineQuery("userInfoQuery", ({ teacherId }: { teacherId: number | null | undefined }) => ({
    key: ["user_info", { teacherId }],
    queryFn: async (qryCtx, auth) => {
        console.debug("userInfoQuery")

        await auth!.ensureValidToken()

        const teacher = await rasmik.readOne(Teacher).where(teacherId!).options(teacherReadOptions).run()
        return teacher
    },
    defaultOptions: { enabled: !!teacherId, useErrorBoundary: true },
})).withHelpers((query) => ({
    //Dispos de l'année scolaire en cours (pour ne pas polluer avec les dispos des années passées)
    currentYearDispos() {
        const today = DateTime.now().toISODate()!
        return sortBy(query.data?.YearDispos?.filter((dispo) => !dispo.endOn || dispo.endOn >= today), (dispo) => dispo.startOn) || []
    },
}))

export type UserInfoQueryData = (typeof user_info_query)["dataShape"]
