import { FormControl, type IFormControlProps, type IInputProps, type IModalProps, useTheme } from "native-base"

import { TimePickerInput } from "./TimePickerInput"

export interface TimePickerControlProps {
    error?: string
    helperText?: string
    label?: string
    isDisabled?: boolean

    minutes: number | null
    onChange: (minutes: number | null) => void
    modalHeader?: string
    clearable?: boolean
    _formControl?: IFormControlProps
    _input?: IInputProps
    _modal?: IModalProps
}

export function TimePickerControl(props: TimePickerControlProps) {
    const { onChange, minutes: value, modalHeader, clearable, error, helperText, isDisabled, label, _formControl, _input, _modal } = props

    const theme = useTheme()

    return (
        <FormControl isInvalid={!!error} isDisabled={isDisabled} {..._formControl}>
            {label && <FormControl.Label>{label}</FormControl.Label>}

            <TimePickerInput onChange={onChange} minutes={value} _input={_input} _modal={_modal} clearable={clearable} isDisabled={isDisabled} modalHeader={modalHeader} />
            {helperText && <FormControl.HelperText>{helperText}</FormControl.HelperText>}
            {error && <FormControl.ErrorMessage>{error}</FormControl.ErrorMessage>}
        </FormControl>
    )
}
