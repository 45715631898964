import { Button, Center, Container } from "native-base"
import type { FallbackProps } from "react-error-boundary"
import { Linking, Platform, StyleSheet, Text, View } from "react-native"

const styles: any = StyleSheet.create({
    container: {
        // backgroundColor: "#fafafa",
        // flex: 1,
        // justifyContent: "center",
    },
    content: {
        marginHorizontal: 20,
    },
    title: {
        fontSize: 48,
        fontWeight: "300",
        paddingBottom: 16,
        color: "#000",
    },
    subtitle: {
        fontSize: 32,
        fontWeight: "800",
        color: "#000",
    },
    error: {
        paddingVertical: 20,
    },
    // button: {
    //     alignSelf: "center",
    //     backgroundColor: "#e68312",
    //     borderRadius: 50,
    //     padding: 10,
    //     width: 200,
    //     marginBottom: 10,
    // },
    // buttonText: {
    //     color: "#fff",
    //     fontWeight: "600",
    //     textAlign: "center",
    // },
})

const checkIfNetworkError = (error: Error) => error.message === "Network Error"

export function ErrorFallback({ error, resetErrorBoundary }: FallbackProps) {
    const isNetworkError = checkIfNetworkError(error)
    return (
        <Center flex={1} safeArea>
            <Container>
                <View style={styles.content}>
                    {/* <Text style={styles.title}>Oups!</Text> */}
                    <Text style={styles.subtitle}>{isNetworkError ? "Connexion internet perdue 🛰️" : "Une erreur est survenue 🤔"}</Text>
                    {!isNetworkError && <Text style={styles.error}>{error.message ?? ""}</Text>}
                    <Center mb="20px" mt={isNetworkError ? "20px" : null}>
                        <Button variant="solid" colorScheme="primary" onPress={resetErrorBoundary}>
                            Réessayer
                        </Button>
                    </Center>

                    {/* {<Text style={styles.error}>Pour nous aider a résoudre le problème, tu peux nous le signaler <Link onPress={() => Linking.openURL(`sms:+33761768664${Platform.OS === "android" ? "?" : "&"}body=Une erreur s'est produite.\n Voici le message d'erreur :\n${error.message}\n${error.stack}`)}>en envoyant un sms</Link></Text>} */}
                    {/* {<Text style={styles.error}>Pour nous aider a résoudre le problème, tu peux nous le signaler en <Text style={{ color: 'rgb(99, 102, 241)',textDecorationLine: 'underline'}} onPress={() => Linking.openURL(`sms:+33761768664${Platform.OS === "android" ? "?" : "&"}body=Une erreur s'est produite.\n Voici le message d'erreur :\n${error.message}\n${error.stack}`)}>en envoyant un sms au 07 61 76 86 64</Text></Text>} */}

                    {!isNetworkError && (Platform.OS === "android" || Platform.OS === "ios") && (
                        <Text style={styles.error}>
                            Pour nous aider a résoudre le problème, tu peux nous le signaler{" "}
                            <Text
                                style={{ color: "rgb(99, 102, 241)", textDecorationLine: "underline" }}
                                onPress={() => Linking.openURL(`sms:+33761768664${Platform.OS === "android" ? "?" : "&"}body=Bonjour, l'erreur suivante s'est produite :\n${error.message}\n${error.stack}`)}
                            >
                                en envoyant un sms
                            </Text>
                        </Text>
                    )}
                    {!isNetworkError && Platform.OS === "web" && <Text>Pour nous aider a résoudre le problème, tu peux nous le signaler en envoyant un sms au 07 61 76 86 64</Text>}
                </View>
            </Container>
        </Center>
    )
}
