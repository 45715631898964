import { useAuthContext } from "@core/context/auth"
import { useCatchToBoundary, useSpinner } from "@core/hooks"
import { useBoolean } from "@core/hooks/useBoolean"
import { ToggleDarkMode } from "@core/misc-components/ToggleDarkMode"
import { openapi } from "@core/services/openapi"
import type { StackScreenProps } from "@react-navigation/stack"
import { Box, Button, Center, FormControl, Heading, HStack, Image, Input, Link, Text, VStack } from "native-base"
import React, { useCallback, useEffect, useState } from "react"

import type { AuthStackParamList } from "./AuthStack"

export function ForgetPasswordScreen({ navigation, route: { params } }: StackScreenProps<AuthStackParamList, "ForgetPassword">) {
    return <ForgetPasswordScreenContent goToLogin={() => navigation.navigate("Login", {})} prefilledEmail={params.email} />
}

export function ForgetPasswordScreenContent({ goToLogin, prefilledEmail }: { goToLogin: () => any; prefilledEmail: string }) {
    const [email, setEmail] = useState<string>("")
    const [emailTouched, setEmailTouched] = useState(false)

    const auth = useAuthContext()

    useEffect(() => {
        prefilledEmail && setEmail(prefilledEmail)
        prefilledEmail && setEmailTouched(true)
    }, [prefilledEmail])

    const [showSent, showSentActions] = useBoolean(false)

    const [hasUnknonwnUserError, setHasUnknonwnUserError] = useState(false)

    const hasMissingFields = !email
    const hasErrors = hasUnknonwnUserError

    const handleEmailChange = useCallback((text: string) => {
        setEmail(text)
        setEmailTouched(true)
    }, [])

    const spinner = useSpinner()

    const askForget = useCatchToBoundary(async () => {
        try {
            spinner.start()
            await openapi.teachersApp.resendPassword({ email })
            showSentActions.setTrue()
            setHasUnknonwnUserError(false)
        } finally {
            // await signIn({ email, password })
            spinner.stop()
            setEmailTouched(false)
        }
    }, [email, showSentActions, spinner])

    const fulfilled = Boolean(email)
    return (
        <Center w="100%" h="100%" safeArea>
            <Box p="2" py="8" w="100%" maxW="290">
                <Image source={require("../assets/images/forgot-password.png")} alt="login-illustration" size="xl" alignSelf="center" />

                <Heading size="lg" p="30" fontWeight="600" color="coolGray.800" _dark={{ color: "warmGray.50" }} alignSelf="center">
                    Un oubli ?
                </Heading>

                <VStack space={3} mt="0">
                    {showSent && (
                        <>
                            <Text>Email envoyé à {email}</Text>
                            <HStack>
                                <Button variant="link" _text={{ fontSize: "xs", fontWeight: "500", color: "primary.500" }} mt="0" onPress={goToLogin}>
                                    Retour à l'écran de connexion
                                </Button>
                            </HStack>
                        </>
                    )}

                    {!showSent && (
                        <>
                            <FormControl>
                                <FormControl.Label>Email</FormControl.Label>
                                <Input
                                    value={email}
                                    autoCapitalize="none"
                                    // autoCompleteType="off"
                                    autoCorrect={false}
                                    keyboardType="email-address"
                                    onChangeText={handleEmailChange}
                                />
                                {hasUnknonwnUserError && !emailTouched && (
                                    <Text color="error.500" fontSize="xs" alignSelf="flex-start" mt="1">
                                        email inconnu
                                    </Text>
                                )}
                                <HStack>
                                    <Button variant="link" _text={{ fontSize: "xs", fontWeight: "500", color: "primary.500" }} mt="0" onPress={goToLogin}>
                                        Retour à l'écran de connexion
                                    </Button>
                                </HStack>
                            </FormControl>

                            <Button
                                isDisabled={(hasMissingFields && emailTouched) || (hasErrors && !emailTouched)}
                                /* disabled={(hasMissingFields && emailTouched)  || (hasErrors && !emailTouched)} */ mt="2"
                                colorScheme="pedagome"
                                _text={{ color: "lightText" }}
                                onPress={() => askForget()}
                                isLoadingText="Envoi en cours"
                            >
                                Renvoyer
                            </Button>
                        </>
                    )}
                </VStack>
            </Box>
            <Center>
                {" "}
                <ToggleDarkMode />
            </Center>
        </Center>
    )
}
