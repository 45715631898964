import * as rax from "@core/utils/retry-axios"
import axios from "axios"

function getAxiosRetryInstance() {
    const axiosRetry = axios.create()

    axiosRetry.defaults.raxConfig = {
        // Retry 3 times on requests that return a response (500, etc) before giving up.  Defaults to 3.
        retry: 4,

        // Retry twice on errors that don't return a response (ENOTFOUND, ETIMEDOUT, etc).
        noResponseRetries: 4,

        // Milliseconds to delay at first.  Defaults to 100. Only considered when backoffType is 'static'
        retryDelay: 100,

        // HTTP methods to automatically retry.  Defaults to:
        // ['GET', 'HEAD', 'OPTIONS', 'DELETE', 'PUT']
        httpMethodsToRetry: ["GET", "HEAD", "OPTIONS", "DELETE", "PUT", "POST"],

        // The response status codes to retry.  Supports a double
        // array with a list of ranges.  Defaults to:
        // [[100, 199], [429, 429], [500, 599]]
        statusCodesToRetry: [
            [100, 199],
            [429, 429],
            [500, 599],
        ],

        // If you are using a non static instance of Axios you need
        // to pass that instance here (const ax = axios.create())
        instance: axiosRetry,

        // You can set the backoff type.
        // options are 'exponential' (default), 'static' or 'linear'
        backoffType: "exponential",

        // You can detect when a retry is happening, and figure out how many
        // retry attempts have been made
        onRetryAttempt: (err) => {
            const cfg = rax.getConfig(err)

            if (axios.isAxiosError(err)) {
                if (!err.config) {
                    throw err
                }
                // retry while Network timeout or Network Error
                if (err.message.includes("timeout") || err.message.includes("Network Error")) {
                    if (cfg!.currentRetryAttempt === cfg!.retry) {
                        throw err
                    } else {
                        console.debug(`Retry attempt #${cfg!.currentRetryAttempt}`)
                    }
                }
            }
        },
    }

    rax.attach(axiosRetry)

    return axiosRetry
}

export { getAxiosRetryInstance }

export class MaxRetryError extends Error {}
