import { Card } from "@core/atoms/Card"
import { useAuthContext } from "@core/context/auth"
import { ContentContainer } from "@core/misc-components/ContentContainer"
import { AppBar } from "@core/molecules/AppBar"
import { LoadingScreen } from "@core/organism/LoadingScreen"
import { match_history_query, match_infos_list_query } from "@core/queries"
import { formatDurationMin } from "@core/utils/format"
import { goBack } from "@core/utils/navigation/go-back"
import { conditional } from "@core/utils/textUtils"
import { notNil } from "@core/utils/tools"
import type { StackScreenProps } from "@react-navigation/stack"
import { capitalize } from "lodash"
import { DateTime } from "luxon"
import { Center, Divider, Heading, HStack, ScrollView, Text, VStack } from "native-base"
import React from "react"

import type { MainStackParamsList } from "../MainStack"

export function History(props: StackScreenProps<MainStackParamsList, "History">) {
    const {
        navigation,
        route: { params },
    } = props
    const { matchId } = params

    const auth = useAuthContext()

    const { matchHistoryQuery } = match_history_query.use({ matchId })
    const { matchInfosListQuery } = match_infos_list_query.use({ teacherId: auth.userId })

    if (matchHistoryQuery.isLoading || matchInfosListQuery.isLoading) return <LoadingScreen />

    const byMonthItems = matchHistoryQuery.data!
    const matchInfo = matchInfosListQuery.helpers.findById(matchId)

    if (!matchInfo) throw new Error(`Can't find match ${matchId} in matchInfosListQuery)`)

    return (
        <VStack flexGrow={1} alignItems="stretch">
            <AppBar onBack={() => goBack(navigation, "MainTabs", { screen: "MatchList" })}>
                <Heading flex={1} fontSize="lg">
                    Historique - {matchInfo.label} avec {matchInfo?.Program?.Learner?.firstName || ""} {matchInfo?.Program?.Learner?.lastName || ""}
                </Heading>
            </AppBar>

            {byMonthItems.length === 0 ? (
                <Center flexGrow={1}>
                    <Text fontSize="xl">Aucun cours renseigné</Text>
                </Center>
            ) : (
                <ScrollView style={{ width: "100%", height: 0 }} contentContainerStyle={{ flexGrow: 1, width: "100%" }}>
                    {/* <Container flexGrow={1} alignItems={"stretch"} alignSelf="center" margin="20px"> */}
                    <ContentContainer py="15px" height="full">
                        <VStack space="15px">
                            {byMonthItems.map((monthItem) => {
                                return (
                                    <Card py="10px" key={monthItem.month}>
                                        <Heading fontSize="2xl" mb="15px">
                                            {capitalize(DateTime.fromISO(monthItem.month).toFormat("MMMM yyyy"))}
                                        </Heading>
                                        {monthItem.lessons.map((lsn) => (
                                            <HStack key={lsn.id} alignItems="center" justifyContent="space-between">
                                                <Text>
                                                    {lsn.startedAtUnofficial ?? lsn.startedAtOfficial ? DateTime.fromJSDate(lsn.startedAtUnofficial! ?? lsn.startedAtOfficial!).toFormat("EEE dd MMM") : "Pas de date"}
                                                    {conditional(` (brouillon)`, lsn.status === "DRAFT")}
                                                </Text>
                                                <Text>{notNil(lsn.durationMin) ? formatDurationMin(lsn.durationMin) : "0h"}</Text>
                                            </HStack>
                                        ))}
                                        <Divider my="5px" />

                                        <HStack alignItems="center" justifyContent="space-between">
                                            <Text fontSize="xl" textAlign="center">
                                                Total
                                            </Text>
                                            <Text fontSize="xl" textAlign="center">
                                                {formatDurationMin(monthItem.monthSum)}
                                            </Text>
                                        </HStack>
                                    </Card>
                                )
                            })}
                        </VStack>
                    </ContentContainer>
                </ScrollView>
            )}
        </VStack>
    )
}
