import { useDialog } from "@core/hooks"
import { Box, Button, Overlay, useTheme } from "native-base"
import { Modal as RNModal } from "react-native"

import { ForgetPasswordScreenContent } from "../../../auth-stack/ForgetPasswordScreen"
import { LoginScreenContent } from "../../../auth-stack/LoginScreen"
import { useAuthContext } from "./auth-context"

/**
 * Avec Overlay, les children (LoginScreenContent et ForgetPasswordScreenContent) semblent ne pas pouvoir accéder au contexte.
 * Avec RNModal, ça marche
 */

export function ReloginDialog(/* { contextValue }: { contextValue: any } */) {
    const auth = useAuthContext()
    const forgetPasswordDialog = useDialog<{ email: string }>()
    const theme = useTheme()

    return (
        // <Overlay isOpen={auth.isLoginDialogOpen}>
        <RNModal visible={auth.isLoginDialogOpen} transparent>
            <Box backgroundColor={theme.colors.background.light1} _dark={{ backgroundColor: theme.colors.background.dark1 }} height="full" width="full">
                {forgetPasswordDialog.isClosed ? (
                    <LoginScreenContent type="dialog" /* authCtx={contextValue} */ goToForgetPassword={({ email }) => forgetPasswordDialog.open({ email })} />
                ) : (
                    <ForgetPasswordScreenContent goToLogin={() => forgetPasswordDialog.hide()} prefilledEmail={forgetPasswordDialog.payload?.email ?? ""} />
                )}
            </Box>
        </RNModal>
    )
}
