import type { LocalStorageAuthData } from "@core/context/auth/auth-context"
import AsyncStorage from "@react-native-async-storage/async-storage"

export async function authHeader(): Promise<{ Authorization?: string }> {
    // return authorization header
    const auth$ = await AsyncStorage.getItem("auth")
    if (auth$) {
        const auth = JSON.parse(auth$) as LocalStorageAuthData
        return { Authorization: "Bearer " + auth.token }
    } else {
        return {}
    }
}
