import { ScrollViewGrow } from "@core/atoms"
import { useAuthContext } from "@core/context/auth"
import { FmkComboBox } from "@core/formik/FmkComboBox"
import { useValidationSchema } from "@core/hooks/useValidationSchema"
import { ContentContainerColumn, ContentContainerRow } from "@core/misc-components/ContentContainer"
import { AppBar } from "@core/molecules/AppBar"
import { main_cities_query, mobilities_query, user_info_query } from "@core/queries"
import { openapi } from "@core/services/openapi"
import { rasmik, Teacher } from "@core/services/rasmik"
import { capitalizeName, formatPhoneNumber, standardizePhoneNumber, verifyPhoneNumber } from "@core/utils"
import { FmkUtils } from "@core/utils/formik"
import { FontAwesome5 } from "@expo/vector-icons"
import { CheckboxControl, InputControl, SubmitButton, TextAreaControl } from "@native-base/formik-ui"
import type { NavigationProp } from "@react-navigation/native"
import type { StackScreenProps } from "@react-navigation/stack"
import { Formik, useFormikContext } from "formik"
import { Alert, Box, Button, Center, FormControl, Heading, HStack, Icon, Stack, Text, VStack } from "native-base"
import { useErrorBoundary } from "react-error-boundary"

import type { MainStackParamsList } from "../MainStack"

export class FormValues {
    @FmkUtils.DefField firstName: string | null
    @FmkUtils.DefField lastName: string | null
    @FmkUtils.DefField email: string | null
    @FmkUtils.DefField telephone: string | null
    @FmkUtils.DefField mainCity: number | null
    @FmkUtils.DefField wishedStudentsNb: string | null
    @FmkUtils.DefField Mobilities: Record<string, boolean>
    @FmkUtils.DefField infoComment: string
    @FmkUtils.DefField freeComment: string
}

const FIELDS = FmkUtils.getFields(FormValues)

const wishedStudentsOptions = ["1", "2", "3", "4", "5 ou plus"]

export interface EditInfoFormProps {
    navigation: NavigationProp<MainStackParamsList>
    isSplashScreen?: boolean
    onSnooze?(): void
}

export function EditInfoFormScreen({ navigation }: StackScreenProps<MainStackParamsList, "EditInfoForm">) {
    return (
        <VStack space={2} h="100%">
            <AppBar onBack={() => navigation.navigate("MainTabs", { screen: "User" })}>
                <ContentContainerRow flex={1}>
                    <Heading size="md">Mes coordonnées</Heading>
                </ContentContainerRow>
            </AppBar>
            <ScrollViewGrow>
                <ContentContainerColumn my="4">
                    <EditInfoForm navigation={navigation} />
                </ContentContainerColumn>
            </ScrollViewGrow>
        </VStack>
    )
}

export function EditInfoForm(props: EditInfoFormProps) {
    const schema = useValidationSchema(
        (Yup) =>
            Yup.object().shape({
                [FIELDS.firstName]: Yup.string().nullable().required("Requis"),
                [FIELDS.lastName]: Yup.string().nullable().required("Requis"),
                [FIELDS.email]: Yup.string().nullable().email("Format incorrect").required("Requis"),
                [FIELDS.telephone]: Yup.string()
                    .nullable()
                    .required("Requis")
                    .test("isPhoneNumber", "Format incorrect", (value) => {
                        return verifyPhoneNumber(value || "")[0]
                    }),

                [FIELDS.mainCity]: Yup.number().nullable().required("Requis"),
                [FIELDS.wishedStudentsNb]: Yup.string().nullable().required("Requis"),
                [FIELDS.Mobilities]: Yup.object(),
                [FIELDS.infoComment]: Yup.string(),
                [FIELDS.freeComment]: Yup.string(),
            }),
        []
    )

    const auth = useAuthContext()
    const teacherId = auth.userId

    const { userInfoQuery } = user_info_query.use({ teacherId: auth.userId })
    const { mobilitiesQuery } = mobilities_query.use({})
    const { mainCitiesQuery } = main_cities_query.use({})

    const { showBoundary } = useErrorBoundary()

    const initialValues: FormValues = {
        [FIELDS.firstName]: userInfoQuery.data?.firstName || null,
        [FIELDS.lastName]: userInfoQuery.data?.lastName || null,
        [FIELDS.email]: userInfoQuery.data?.email || null,
        [FIELDS.telephone]: formatPhoneNumber(userInfoQuery.data?.MainPhoneNumber?.number || "") || null,
        [FIELDS.mainCity]: userInfoQuery.data?.MainCity?.id || null,
        [FIELDS.wishedStudentsNb]: userInfoQuery.data?.wishedStudentsNb || null,
        [FIELDS.Mobilities]: mobilitiesQuery.data?.reduce<FormValues["Mobilities"]>((acc, item) => ({ ...acc, [item.id]: userInfoQuery.data?.Mobilities.some((mob) => mob.id === item.id) }), {}) ?? {},
        [FIELDS.infoComment]: "",
        [FIELDS.freeComment]: "",
    }

    //On affiche aussi la ville déjà attribuée car elle est peut-être désactivée
    const visibleMainCities = mainCitiesQuery.data?.filter((city) => city.isActiveTeachers || city.id === userInfoQuery.data?.MainCity?.id) ?? []

    return (
        <>
            <Formik<FormValues>
                initialValues={initialValues}
                enableReinitialize
                validationSchema={schema}
                onSubmit={async (values, { setSubmitting }) => {
                    if (!mobilitiesQuery.data || !mainCitiesQuery.data || !userInfoQuery.data) return
                    // console.log("test ", values)
                    try {
                        await auth.ensureValidToken()

                        const pushDef = rasmik.define.pushDef(Teacher).val({
                            allow: "update",
                            children: {
                                MainPhoneNumber: { allow: "update" },
                                Mobilities: { allow: "pk", collectionMode: "set" },
                                MainCity: { allow: "pk" },
                            },
                        })

                        const pushData = rasmik.define
                            .pushData(Teacher)
                            .def(pushDef)
                            .val({
                                id: teacherId,
                                lastName: capitalizeName(values.lastName!).trim(),
                                firstName: capitalizeName(values.firstName!).trim(),
                                email: values.email!.trim().toLowerCase() ?? "",
                                MainPhoneNumber: { id: userInfoQuery.data?.MainPhoneNumber?.id, number: standardizePhoneNumber(values.telephone!) },
                                Mobilities: Object.entries(values.Mobilities)
                                    .filter(([id, checked]) => !!checked)
                                    .map(([id, checked]) => Number(id)),
                                wishedStudentsNb: values.wishedStudentsNb!,
                                MainCity: values.mainCity!,
                                miscInfosLastUpdatedAt: new Date(),
                            })

                        const teacher = await rasmik.pushOne(Teacher).pushDef(pushDef).data(pushData).run()

                        const changes = new Array<string>()
                        if (initialValues.firstName !== pushData.firstName) {
                            changes.push(`Prénom : ~${initialValues.firstName}~ -> ${pushData.firstName}`)
                        }

                        if (initialValues.lastName !== pushData.lastName) {
                            changes.push(`Nom : ~${initialValues.lastName}~ -> ${pushData.lastName}`)
                        }

                        if (initialValues.email !== pushData.email) {
                            changes.push(`Email : ~${initialValues.email}~ -> ${pushData.email}`)
                        }

                        if (pushData.MainPhoneNumber?.number !== userInfoQuery.data?.MainPhoneNumber?.number) {
                            changes.push(`Tél : ~${initialValues.telephone}~ -> ${formatPhoneNumber(pushData.MainPhoneNumber!.number!)}`)
                        }

                        const oldMobIds = userInfoQuery.data?.Mobilities.map((mob) => mob.id).sort() ?? []
                        const newMobIds = (pushData.Mobilities as number[]).sort()
                        if (newMobIds.toString() !== oldMobIds.toString()) {
                            const oldMobs = mobilitiesQuery.data?.filter((mob) => oldMobIds.includes(mob.id))!
                            const newMobs = mobilitiesQuery.data?.filter((mob) => newMobIds.includes(mob.id))!

                            const arr1 = oldMobs.map((oldMob) => (newMobIds.includes(oldMob.id) ? oldMob.name : `~${oldMob.name}~`))
                            const arr2 = newMobs.filter((newMob) => !oldMobIds.includes(newMob.id)).map((veryNewMob) => `+${veryNewMob.name}`)

                            changes.push(`Mobilité : ${[...arr1, ...arr2].join(", ")}`)
                        }

                        if (initialValues.mainCity !== values?.mainCity) {
                            const oldCityName = mainCitiesQuery.data.find((city) => city.id === initialValues.mainCity)?.name ?? "non renseigné"
                            const newCityName = mainCitiesQuery.data.find((city) => city.id === values.mainCity!)?.name ?? "non renseigné"
                            changes.push(`Secteur : ~${oldCityName}~ -> ${newCityName}`)
                        }

                        if (initialValues.wishedStudentsNb !== pushData.wishedStudentsNb) {
                            changes.push(`Élèves souhaités : ~${initialValues.wishedStudentsNb}~ -> ${pushData.wishedStudentsNb}`)
                        }

                        if (changes.length || values.infoComment || values.freeComment) {
                            const blocks = [
                                {
                                    type: "divider",
                                },
                                {
                                    type: "section",
                                    text: {
                                        type: "mrkdwn",
                                        text: `<!channel> *Changement d'infos*\n:memo:${userInfoQuery.data?.firstName} ${userInfoQuery.data?.lastName.toUpperCase()}`,
                                    },
                                },
                                changes.length && {
                                    type: "section",
                                    text: {
                                        type: "mrkdwn",
                                        text: changes.join("\n"),
                                    },
                                },
                                values.infoComment && {
                                    type: "section",
                                    text: {
                                        type: "mrkdwn",
                                        text: `:warning: ${userInfoQuery.data?.firstName} a laissé un message car certaines infos ne sont pas modifiables sur la plateforme.\nVous devez faire vous-même la modification sur Dopple :\n\n*${values.infoComment}*`,
                                    },
                                },
                                values.freeComment && {
                                    type: "section",
                                    text: {
                                        type: "mrkdwn",
                                        text: `Commentaire libre :\n*${values.freeComment}*`,
                                    },
                                },
                                values.infoComment && {
                                    type: "actions",
                                    elements: [
                                        {
                                            type: "button",
                                            style: "primary",
                                            url: `${process.env.DOPPLE_APP_URL}/tuteurs/${userInfoQuery.data?.id}/profil`,
                                            text: {
                                                type: "plain_text",
                                                text: "Corriger sur Dopple",
                                                emoji: true,
                                            },
                                        },
                                    ],
                                },
                            ].filter(Boolean)
                            openapi.teachersApp.notifySlack({ channel: "tuteurs", blocks }).catch()
                        }

                        await userInfoQuery.refetch()
                        if (!props.isSplashScreen) {
                            props.navigation.navigate("MainTabs", { screen: "User" })
                        }
                    } catch (e) {
                        showBoundary(e)
                    } finally {
                        setSubmitting(false)
                    }
                }}
            >
                {({ values }) => (
                    <Stack space={2}>
                        <InputControl inputProps={{ marginTop: "0" }} label="Prénom" name={FIELDS.firstName} />
                        <InputControl inputProps={{ marginTop: "0" }} label="Nom" name={FIELDS.lastName} />
                        <InputControl inputProps={{ marginTop: "0" }} label="Téléphone" name={FIELDS.telephone} />
                        <InputControl inputProps={{ marginTop: "0" }} label="Email" name={FIELDS.email} />
                        <FmkComboBox name={FIELDS.mainCity} label="Secteur" modalHeader="Secteur" options={visibleMainCities} configure={(opt) => ({ label: opt.name, value: opt.id })} />

                        <FmkComboBox
                            name={FIELDS.wishedStudentsNb}
                            label="Nombre d'élèves souhaités *"
                            helperText="* à l'idéal"
                            modalHeader="Élèves souhaités"
                            options={wishedStudentsOptions}
                            configure={(opt) => ({ label: opt, value: opt })}
                        />

                        <Box my="3">
                            <FormControl.Label>Moyens de transport</FormControl.Label>

                            {mobilitiesQuery.data?.map((mob, index) => (
                                <CheckboxControl key={mob.id} name={`${FIELDS.Mobilities}.${mob.id}`}>
                                    <Text mx={2}>{mob.name}</Text>
                                </CheckboxControl>
                            ))}
                        </Box>

                        <Box py="13px" px="20px" borderRadius="10px" _light={{ bgColor: "light.200" }} _dark={{ bgColor: "dark.200" }}>
                            <FormControl.Label>Adresse officielle</FormControl.Label>
                            <Text>{userInfoQuery.data?.MainAddress?.fullStreet + ", " + userInfoQuery.data?.MainAddress?.postalCode + ", " + userInfoQuery.data?.MainAddress?.cityName}</Text>

                            <FormControl.Label mt="4">{(userInfoQuery.data?.Addresses.length ?? 0) >= 3 ? "Adresses" : "Adresse"} de référence pour les cours</FormControl.Label>
                            {userInfoQuery.data?.Addresses.length === 1 ? (
                                <Text>{userInfoQuery.data?.MainAddress?.fullStreet + ", " + userInfoQuery.data?.MainAddress?.postalCode + ", " + userInfoQuery.data?.MainAddress?.cityName}</Text>
                            ) : (
                                userInfoQuery.data?.Addresses.filter((addr) => !addr.isMain).map((addr) => <Text key={addr.id}>{addr?.fullStreet + ", " + addr?.postalCode + ", " + addr?.cityName}</Text>)
                            )}

                            <FormControl.Label mt="4">Matières</FormControl.Label>
                            <Text>{userInfoQuery.data?.teachingsLevelsGroupedPopulated?.map((teaching) => teaching.teachingName).join(", ")}</Text>
                        </Box>

                        <TextAreaControl label="Si ton adresse ou tes matières ne sont pas à jour, indique le nous ici et nous ferons les changements nécessaires dans les prochains jours" name={FIELDS.infoComment} />
                        <Box mt="3">
                            <TextAreaControl label="Une suggestion, une remarque ?" name={FIELDS.freeComment} />
                        </Box>

                        {props.isSplashScreen && <InfoFooter />}

                        {/* <PreFmkValues /> */}

                        {props.isSplashScreen ? (
                            <HStack padding={2} justifyContent="right" h="60px" space={1}>
                                <Button variant="solid" colorScheme="gray" onPress={() => props.onSnooze?.()} leftIcon={<Icon as={FontAwesome5} name="clock" size="xs" />}>
                                    Plus tard
                                </Button>
                                <SubmitButton
                                    leftIcon={values.infoComment.length > 0 ? <Icon as={FontAwesome5} name="paper-plane" color="white" size="xs" /> : <Icon as={FontAwesome5} name="save" size="xs" />}
                                    colorScheme="pedagome"
                                >
                                    {values.infoComment.length > 0 ? "Envoyer" : "Enregistrer"}
                                </SubmitButton>
                            </HStack>
                        ) : (
                            <Center>
                                <SubmitButton
                                    leftIcon={values.infoComment.length > 0 ? <Icon as={FontAwesome5} name="paper-plane" color="white" size="xs" /> : <Icon as={FontAwesome5} name="save" size="xs" />}
                                    colorScheme="pedagome"
                                >
                                    {values.infoComment.length > 0 ? "Envoyer" : "Enregistrer"}
                                </SubmitButton>
                            </Center>
                        )}
                    </Stack>
                )}
            </Formik>
        </>
    )
}

function PreFmkValues() {
    const ctx = useFormikContext()
    if (process.env.NODE_ENV !== "development") return null
    return <Text>{JSON.stringify({ values: ctx.values, errors: ctx.errors }, undefined, 2)}</Text>
}

export function InfoFooter() {
    return (
        <Alert status="info" variant="subtle" colorScheme="info" my="2">
            <VStack space={2} flexShrink={1} w="100%">
                <HStack flexShrink={1} space={2} alignItems="center" justifyContent="space-between">
                    <HStack flexShrink={1} space={2} alignItems="center">
                        <Alert.Icon />
                        <Text fontSize="md" fontWeight="medium">
                            Information
                        </Text>
                    </HStack>
                </HStack>
                <Text>Retrouve ce formulaire à tout moment depuis l'onglet profil.</Text>
            </VStack>
        </Alert>
    )
}
