import { Box, HStack, VStack } from "native-base"
import type { IVStackProps } from "native-base/lib/typescript/components/primitives/Stack/VStack"

export interface ContentContainerProps extends IVStackProps {}

export function ContentContainer(props: ContentContainerProps) {
    // const {} = props;

    return (
        <Box nativeID="content-container-container" width="full" justifyContent="center" display="flex" flexDirection="row">
            <VStack nativeID="content-container-content" maxWidth="2xl" px="15px" flex={1} {...props} />
        </Box>
    )
}

/**
 * Centre le contenu sur la page (marginX: auto)
 * Prend le max de largeur (width: 100%)
 * Tout en limitant la largeur du contenu (maxWidth="2xl")
 * S'assure d'un padding minimal par rapport aux bords de l'écran (paddingX: 15px)
 */
export function ContentContainerRow(props: ContentContainerProps) {
    return (
        <HStack nativeID="content-container" maxWidth="2xl" width="100%" marginX="auto" paddingX="15px" {...props}>
            {props.children}
        </HStack>
    )
}

export function ContentContainerColumn(props: ContentContainerProps) {
    return (
        <VStack nativeID="content-container" maxWidth="2xl" width="100%" marginX="auto" paddingX="15px" {...props}>
            {props.children}
        </VStack>
    )
}
