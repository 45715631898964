import type { Teacher } from "@core/services/rasmik-client"
import { DateTime } from "luxon"

export const EuropeParis = "Europe/Paris"

export function getShowedMonthDt() {
    return DateTime.now().setZone(EuropeParis).minus({ hours: 12 }).startOf("month")
}

export function getShowedMonthStr() {
    return getShowedMonthDt().toISODate()!
}

export function isDispoNextYear(teacher: Pick<Teacher, "dispoNextYear" | "dispoNextYearAt">) {
    if (!teacher) return false
    const now = DateTime.now()
    const startOfCurrentSchoolYear = now.month >= 8 ? now.set({ month: 8, day: 1 }) : now.set({ month: 8, day: 1 }).minus({ year: 1 })
    return !!teacher.dispoNextYear && !!teacher.dispoNextYearAt && startOfCurrentSchoolYear.toJSDate() <= teacher.dispoNextYearAt
}
