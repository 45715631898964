import { uniq } from "lodash"
import { useState } from "react"

export function useAccordion(args: { multiple?: boolean; initialExpanded?: number[] } = {}) {
    const multiple = args.multiple ?? false
    const initialExpanded = args.initialExpanded ?? []

    const [expandedIndexes, setExpandedIndexes] = useState(initialExpanded)

    return {
        expandedIndexes,
        expandedIndex: expandedIndexes[0],
        expand: (index: number) => {
            setExpandedIndexes((idxs) => (multiple ? uniq([...idxs, index]) : [index]))
        },
        collapse: (index: number) => {
            setExpandedIndexes((idxs) => idxs.filter((idx) => idx !== index))
        },
        collapseAll: (index: number) => {
            setExpandedIndexes([])
        },
        expandMany: (indexes: number[]) => {
            if (!multiple) throw new Error("multiple must be true when using expandMany")
            setExpandedIndexes((idxs) => uniq([...idxs, ...indexes]))
        },
    }
}
