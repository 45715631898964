import { DarkTheme, DefaultTheme, type Theme as NavTheme } from "@react-navigation/native"
import { type ColorMode, extendTheme, theme as defaultTheme } from "native-base"
import { useMemo } from "react"

// extend the theme

export const theme = extendTheme({
    // breakpoints:{

    // },
    config: {
        // Changing initialColorMode to 'dark'
        useSystemColorMode: false,
        initialColorMode: "dark",
    },
    colors: {
        // Add new colors

        pedagome: {
            50: "#ffdbdb", // 'hsl(352 100% 93%)',
            100: "#ffc2c2", //'hsl(352 100% 88%)',// '#ffbdbf',#ffd6d8
            200: "#ffa3a3", //hsl(358deg 100% 82%)",
            300: "#ff8084", //'hsl(358 100% 75%)',
            400: "#ff4d52", //"hsl(358deg 100% 65%)",
            500: "#ff1a24", //55%
            600: "#e6000a",
            700: "#b40007",
            // 800: "#640205",
            800: "#320103",
            900: "#220102",
            // 900: '#500000',
            950: "#190101",
            // 50: '#ffe1e5',
            // 100: '#ffb1b4',
            // 200: '#ff7f84',
            // 300: '#ff4c53',
            // 400: '#ff1a24',
            // 500: '#e6000a',
            // 600: '#b40007',
            // 700: '#810004',
            // 800: '#500000',
            // 900: '#210000',
            official: "#ff2e38", //255, 46, 56
        },
        get secondary() {
            return this.pedapink
        },
        get primary() {
            return this.indigo
        },

        pedapink: {
            100: "#ffeeef",
            200: "#ffdedf",
            300: "#ffbcbf",
            400: "#ff9ba0",
            500: "#ff7980",
            600: "#ff5860",
            700: "#cc464d",
            800: "#99353a",
            900: "#662326",
            950: "#331213",
        },
        // brown: {
        //     50: "#f4ece5",
        //     100: "#e5d3c2",
        //     200: "#d7baa0",
        //     300: "#c8a27d",
        //     400: "#ba895b",
        //     500: "#a07043",
        //     600: "#7e5835",
        //     700: "#5b4026",
        //     800: "#392818",
        //     900: "#161009",
        // },

        brown: {
            50: "#f2ebe6",
            100: "#e1d1c6",
            200: "#d0b7a6",
            300: "#bf9c86",
            400: "#af8266",
            500: "#956a4e",
            600: "#75533d",
            700: "#553c2c",
            // 800: "#35251c",
            800: "#2b1e17",
            900: "#1e1510",
            950: "#150f0b",
        },
        indigo: {
            50: "#eef2ff", //bg light
            100: "#e0e7ff", //hover light
            200: "#c7d2fe", //pressed light
            300: "#a5b4fc",
            400: "#818cf8",
            500: "#6366f1", //bg both solid
            600: "#4f46e5", //hover both solid
            700: "#4338ca", //pressed both solid
            // 800: '#3730a3',
            // 900: '#312e81',
            800: "#12104c", //bg dark subtle
            900: "#0b0a2f", //hover dark subtle
            950: "#080722", //pressed dark subtle
        },

        background: {
            light1: defaultTheme.colors.light[100],
            dark1: defaultTheme.colors.dark[50],
            light2: defaultTheme.colors.light[50],
            dark2: "rgb(39, 39, 42)",
            light3: "white",
            dark3: defaultTheme.colors.dark[200],
            lightMode: {
                gray: defaultTheme.colors.trueGray[200],
            },
            darkMode: {
                gray: defaultTheme.colors.trueGray[800],
            },
        },
        card: {
            light: defaultTheme.colors.light[50],
            dark: defaultTheme.colors.dark[100],
        },
        appBar: {
            light: "white",
            dark: "#272729",
        },
        lightText: "#F0F0F0",
        darkText: "#0F0F0F",

        darkTextMild: "#ababab",
        lightTextMild: "#787878",

        //   // Redefinig only one shade, rest of the color will remain same.
        //   amber: {
        //     400: '#d97706',
        //   },
    },
    components: {
        Button: {
            // Can simply pass default props to change default behaviour of components.
            baseStyle: {
                rounded: "md",
                fontWeight: 600,
            },
            defaultProps: {},
            variants: {
                solid({ colorScheme }: any) {
                    return {
                        _text: {
                            color: "text.50",
                            fontWeight: 600,
                        },
                        _icon: {
                            color: "text.50",
                        },
                        _spinner: {
                            color: "text.50",
                        },
                        bg: `${colorScheme}.500`,
                        _hover: {
                            bg: `${colorScheme}.600`,
                        },
                        _pressed: {
                            bg: `${colorScheme}.700`,
                        },

                        _dark: {
                            bg: `${colorScheme}.500`,
                            _hover: {
                                bg: `${colorScheme}.600`,
                            },
                            _pressed: {
                                bg: `${colorScheme}.700`,
                            },
                        },
                    }
                },
                subtle2: ({ colorScheme }: any) => {
                    return {
                        bg: `${colorScheme}.50`,
                        _text: {
                            fontWeight: 600,
                            color: `${colorScheme}.500`,
                        },
                        _icon: {
                            color: `${colorScheme}.500`,
                        },
                        _spinner: {
                            color: `${colorScheme}.500`,
                        },
                        _hover: {
                            bg: `${colorScheme}.100`,
                        },
                        _pressed: {
                            bg: `${colorScheme}.200`,
                        },

                        _dark: {
                            bg: `${colorScheme}.800`,
                            _hover: {
                                bg: `${colorScheme}.900`,
                            },
                            _pressed: {
                                bg: `${colorScheme}.950`,
                            },
                        },
                    }
                },
            },
            // subtleBrown: ({ colorScheme }:any) => {
            //     return {
            //         bg: `#efd7c6`,
            //         _text: {
            //             color: `#724f38`,
            //         },
            //         _icon: {
            //             color: `#724f38`,
            //         },
            //         _spinner: {
            //             color: `#724f38`,
            //         },
            //         _hover: {
            //             bg: `${colorScheme}.200`,
            //         },
            //         _pressed: {
            //             bg: `${colorScheme}.500`,
            //         },

            //         _dark: {
            //             bg: `${colorScheme}.300`,
            //             _hover: {
            //                 bg: `${colorScheme}.200`,
            //             },
            //             _pressed: {
            //                 bg: `${colorScheme}.100`,
            //             },
            //         },
            //     };
            // }
        },

        Input: {
            baseStyle: {
                rounded: "md",
            },
        },
        Spinner: {
            baseStyle: {
                color: "primary.500",
            },
        },
        Alert: {
            defaultProps: {
                alignItems: "flex-start",
            },
            variants: {
                subtle: (props: any) => {
                    let { colorScheme, status } = props

                    colorScheme = colorScheme ?? status ?? "gray"

                    return {
                        bg: `${colorScheme}.100`,
                        _icon: { color: `${colorScheme}.500` },
                        _dark: {
                            bg: `${colorScheme}.800`,
                            _icon: { color: `${colorScheme}.100` },
                        },
                    }
                },
                subtle2: (props: any) => {
                    let { colorScheme, status } = props

                    colorScheme = colorScheme ?? status ?? "gray"

                    return {
                        bg: `${colorScheme}.200`,
                        _icon: { color: `${colorScheme}.600` },
                        _dark: {
                            bg: `${colorScheme}.600`,
                            _icon: { color: `${colorScheme}.100` },
                        },
                    }
                },
                "left-accent": (props: any) => {
                    let { colorScheme, status } = props

                    colorScheme = colorScheme ?? status ?? "gray"

                    return {
                        bg: `${colorScheme}.100`,
                        _icon: { color: `${colorScheme}.500` },
                        _dark: {
                            bg: `${colorScheme}.800`,
                            _icon: { color: `${colorScheme}.100` },
                        },
                    }
                },
            },
        },
        ModalContent: {
            defaultProps: {
                width: "94%",
            },
        },
    },
    fontConfig: {
        WorkSans: {
            300: {
                normal: "WorkSans_300Light",
                // italic: 'WorkSans-LightItalic',
            },
            400: {
                normal: "WorkSans_400Regular",
            },
            500: {
                normal: "WorkSans_500Medium",
            },
            600: {
                normal: "WorkSans_600SemiBold",
            },
        },
    },
    fonts: {
        heading: "WorkSans",
        body: "WorkSans",
        mono: "WorkSans",
    },
})

/** Module augmentation du Theme de natiive-base 🤯*/
type MyThemeType = typeof theme
declare module "native-base" {
    interface ICustomTheme extends MyThemeType {}
}

/**
 * Build a specific theme for @react-navigation from native-base theme and colorMode
 */
export function useNavigationTheme(colorMode: ColorMode): NavTheme {
    return useMemo(() => {
        if (colorMode === "light") {
            const MyDefaultTheme: NavTheme = {
                dark: false,
                colors: {
                    ...DefaultTheme.colors,
                    primary: theme.colors.secondary[500],
                    background: theme.colors.light[100],
                    card: theme.colors.light[50],
                    text: theme.colors.lightText,
                    // border: Colors.grey30,
                    // notification: Colors.primary,
                },
            }

            return MyDefaultTheme
        } else if (colorMode === "dark") {
            const MyDarkTheme: NavTheme = {
                dark: true,
                colors: {
                    ...DarkTheme.colors,
                    primary: theme.colors.secondary[500],
                    background: theme.colors.dark[50],
                    card: theme.colors.dark[100],
                    text: theme.colors.darkText,
                    // border: Colors.grey30,
                    // notification: Colors.primary,
                },
            }

            return MyDarkTheme
        } else {
            return DefaultTheme
        }
    }, [colorMode])
}
