import { rasmik, TeacherProposition, TeacherPropositionStatusEntries } from "@core/services/rasmik"
import { find } from "lodash"

import { arrayQueryHelpers, defineQuery } from "./utils"

// const teacherPropositionInfoReadOptions = rasmik.define.readOptions(TeacherProposition).val({

// })

// export type MatchInfoData = ReadData<TeacherProposition, typeof teacherPropositionInfoReadOptions>

export const propositions_list_query = defineQuery("propositionsListQuery", ({ teacherId }: { teacherId: number | null | undefined }) => ({
    key: ["propositions_list_query", { teacherId }],
    queryFn: async (qryCtx, auth) => {
        console.debug("propositionsListQuery")

        await auth!.ensureValidToken()
        const allPropositions = await rasmik
            .readMany(TeacherProposition)
            .options({
                children: {
                    Match: {
                        children: {
                            Program: {
                                children: {
                                    Learner: {
                                        children: {
                                            LessonAddresses: true,
                                        },
                                    },
                                },
                            },
                            Lessons: true,
                        },
                    },
                },
            })
            .where({
                Teacher: teacherId,
                status: {
                    $in: [TeacherPropositionStatusEntries.PENDING, TeacherPropositionStatusEntries.EXPIRED, TeacherPropositionStatusEntries.REFUSED],
                },
            })
            .run()

        //TODO: faire differents filtres : matchs pour lesquels on attend des créneaux, matchs terminés ...
        const pendingPropositions = allPropositions.filter((tprop) => tprop.status === "PENDING")
        const deadPropositions = allPropositions.filter((tprop) => tprop.status === "EXPIRED" || tprop.status === "REFUSED")
        const expiredPropositonsShown = allPropositions.filter((tprop) => tprop.status === "EXPIRED" && !tprop.dismissedAborted)

        return { allPropositions, pendingPropositions, deadPropositions, expiredPropositonsShown }
    },
    defaultOptions: {
        enabled: !!teacherId,
        useErrorBoundary: true,
        initialData: { allPropositions: [], pendingPropositions: [], deadPropositions: [], expiredPropositonsShown: [] },
    },
})).withHelpers((query) => ({
    lessonsOfProposedMatch(matchId: number) {
        return query.data?.allPropositions.find((prop) => prop.Match.id === matchId)?.Match.Lessons ?? []
    },
    pendingPropsCount() {
        // console.log('pendingPropsCount',query.data?.allPropositions.filter(prop => prop.status === "PENDING").length)
        return query.data?.pendingPropositions.length ?? 0
    },
    expiredShownPropsCount() {
        // console.log('pendingPropsCount',query.data?.allPropositions.filter(prop => prop.status === "PENDING").length)
        return query.data?.expiredPropositonsShown.length ?? 0
    },
    getPropById(propId: number) {
        return find(query.data?.allPropositions, { id: propId })
    },
    getPropByMatchId(matchId: number) {
        return find(query.data?.allPropositions, { "Match.id": matchId })
    },
}))

export type PropositionsListQueryData = (typeof propositions_list_query)["dataShape"]
