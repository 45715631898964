import { FastModal } from "@core/atoms/FastModal"
import { useAuthContext } from "@core/context/auth"
import { FmkComboBox } from "@core/formik/FmkComboBox"
import { useCatchToBoundary, usePrevious, useSpinner } from "@core/hooks"
import type { DialogAwaitIO, DialogAwaitLogic } from "@core/hooks/useDialog"
import { useValidationSchema } from "@core/hooks/useValidationSchema"
import { user_info_query, type UserInfoQueryData } from "@core/queries"
import { rasmik } from "@core/services/rasmik"
import { YearDispo } from "@core/services/rasmik-client"
import { FmkUtils } from "@core/utils/formik"
import { FontAwesome5 } from "@expo/vector-icons"
import { SubmitButton } from "@native-base/formik-ui"
import { Formik } from "formik"
import { DateTime } from "luxon"
import { Box, Button, DeleteIcon, Heading, HStack, IconButton, Modal, Spacer, Text } from "native-base"
import { useEffect } from "react"
import { useErrorBoundary } from "react-error-boundary"

const SCHOOL_YEAR_START_MONTH = 9
export class FormValues {
    @FmkUtils.DefField startOnDay: number | null
    @FmkUtils.DefField endOnDay: number | null
    @FmkUtils.DefField startOnMonth: string | null
    @FmkUtils.DefField endOnMonth: string | null
}

const FIELDS = FmkUtils.getFields(FormValues)

export interface YearDispoPickerProps {
    dialogLogic: DialogAwaitLogic<DialogAwaitIO<{ payload: UserInfoQueryData["YearDispos"][number] | null; outcome: void }>>
}
export function YearDispoPicker(props: YearDispoPickerProps) {
    const { dialogLogic } = props

    const schema = useValidationSchema(
        (Yup) =>
            Yup.object().shape({
                [FIELDS.startOnDay]: Yup.string().nullable().required("Requis"),
                [FIELDS.endOnDay]: Yup.string().nullable().required("Requis"),
                [FIELDS.startOnMonth]: Yup.string().nullable().required("Requis"),
                [FIELDS.endOnMonth]: Yup.string().nullable().required("Requis"),
            }),
        []
    )

    const auth = useAuthContext()
    const teacherId = auth.userId

    const { userInfoQuery } = user_info_query.use({ teacherId: auth.userId })

    const { showBoundary } = useErrorBoundary()

    const currentDay = DateTime.now().day.valueOf()

    const deleteSpinner = useSpinner()
    const handleDeleteYearDispo = useCatchToBoundary(async () => {
        try {
            deleteSpinner.start()

            await rasmik.deleteOne(YearDispo).where(dialogLogic.payload!.id).run()
            await userInfoQuery.refetch()
            dialogLogic.hide("success")
        } finally {
            deleteSpinner.stop()
        }
    })

    return (
        <FastModal isOpen={dialogLogic.isOpen}>
            <Formik<FormValues>
                initialValues={{
                    startOnDay: dialogLogic.payload?.startOn ? DateTime.fromISO(dialogLogic.payload.startOn).day : null,
                    endOnDay: dialogLogic.payload?.endOn ? DateTime.fromISO(dialogLogic.payload.endOn).day : null,
                    startOnMonth: dialogLogic.payload?.startOn ? DateTime.fromISO(dialogLogic.payload.startOn).startOf("month").toISODate() : null,
                    endOnMonth: dialogLogic.payload?.endOn ? DateTime.fromISO(dialogLogic.payload.endOn).startOf("month").toISODate() : null,
                }}
                enableReinitialize
                validationSchema={schema}
                onSubmit={async (values, { setSubmitting }) => {
                    try {
                        const startOn = DateTime.fromISO(values.startOnMonth!).set({ day: values.startOnDay! }).toISODate()
                        const endOn = DateTime.fromISO(values.endOnMonth!).set({ day: values.endOnDay! }).toISODate()
                        await auth.ensureValidToken()

                        await rasmik
                            .pushOne(YearDispo)
                            .pushDef({
                                allow: "upsert",
                                children: {
                                    Teacher: {
                                        allow: "pk",
                                    },
                                },
                            })
                            .data({ startOn, endOn, isActive: true, Teacher: teacherId!, id: dialogLogic.payload?.id })
                            .run()

                        await userInfoQuery.refetch()
                        dialogLogic.hide("success")
                    } catch (err) {
                        showBoundary(err)
                    } finally {
                        setSubmitting(false)
                    }
                }}
            >
                {function XXX({ values, setFieldValue }) {
                    const daysListStart = generateDaysList(values.startOnMonth)
                    const daysListEnd = generateDaysList(values.endOnMonth, values.startOnDay && values.startOnMonth === values.endOnMonth ? values.startOnDay : 1)
                    const monthsListStart = generateMonthsList()
                    const monthListEnd = generateMonthsList(values.startOnMonth)

                    const previousValues = usePrevious(values)
                    useEffect(() => {
                        if (values.startOnMonth !== previousValues?.startOnMonth) {
                            //diminuer de 31 à 28 si le mois de début change
                            if (
                                values.startOnMonth &&
                                values.startOnDay &&
                                previousValues?.startOnMonth &&
                                DateTime.fromISO(values.startOnMonth).daysInMonth! < DateTime.fromISO(previousValues.startOnMonth).daysInMonth! &&
                                values.startOnDay > DateTime.fromISO(values.startOnMonth).daysInMonth!
                            ) {
                                setFieldValue(FIELDS.startOnDay, DateTime.fromISO(values.startOnMonth!).daysInMonth)
                            }
                        }

                        if (values.endOnMonth !== previousValues?.endOnMonth) {
                            //diminuer de 31 à 28 si le mois de fin change
                            if (
                                values.endOnMonth &&
                                values.endOnDay &&
                                previousValues?.endOnMonth &&
                                DateTime.fromISO(values.endOnMonth).daysInMonth! < DateTime.fromISO(previousValues.endOnMonth).daysInMonth! &&
                                values.endOnDay > DateTime.fromISO(values.endOnMonth!).daysInMonth!
                            ) {
                                setFieldValue(FIELDS.endOnDay, DateTime.fromISO(values.endOnMonth!).daysInMonth)
                            }
                        }

                        //si le mois de début passe après le mois de fin
                        if (values.startOnMonth !== previousValues?.startOnMonth) {
                            if (values.startOnMonth && values.endOnMonth && values.startOnMonth > values.endOnMonth) {
                                setFieldValue(FIELDS.endOnMonth, null)
                                setFieldValue(FIELDS.endOnDay, null)
                            }
                        }

                        //si le jour de début passe après le jour de fin (et que les mois sont les mêmes)
                        if (values.startOnDay !== previousValues?.startOnDay) {
                            if (values.endOnDay && values.startOnDay && values.startOnMonth && values.endOnMonth && values.startOnMonth === values.endOnMonth && values.endOnDay < values.startOnDay) {
                                setFieldValue(FIELDS.endOnDay, null)
                            }
                        }
                    })

                    return (
                        <Modal.Content width="350px">
                            <Modal.Header>
                                <Heading size="md">{dialogLogic.payload ? "Édition" : "Ajout"} d'une période</Heading>
                            </Modal.Header>
                            <Modal.Body>
                                <Text>Du</Text>
                                <HStack space={2} marginBottom="30px">
                                    {/* sélécteur du jour */}
                                    <Box width="100px">
                                        <FmkComboBox name={FIELDS.startOnDay} label="Jour" modalHeader="Jour" clearable options={daysListStart} configure={(day) => ({ label: day, value: day })} />
                                    </Box>

                                    {/* sélécteur de mois */}
                                    <Box flex={1}>
                                        <FmkComboBox
                                            name={FIELDS.startOnMonth}
                                            label="Mois"
                                            modalHeader="Mois"
                                            clearable
                                            options={monthsListStart}
                                            configure={(month) => ({ label: DateTime.fromISO(month!).toFormat("LLLL yyyy"), value: month })}
                                        />
                                    </Box>
                                </HStack>
                                <Text>au</Text>
                                <HStack space={2}>
                                    {/* sélécteur du jour */}
                                    <Box width="100px">
                                        <FmkComboBox name={FIELDS.endOnDay} label="Jour" modalHeader="Jour" clearable options={daysListEnd} configure={(day) => ({ label: day, value: day })} />
                                    </Box>

                                    {/* sélécteur de mois */}
                                    <Box flex={1}>
                                        <FmkComboBox
                                            name={FIELDS.endOnMonth}
                                            label="Mois"
                                            modalHeader="Mois"
                                            clearable
                                            options={monthListEnd}
                                            configure={(month) => ({ label: DateTime.fromISO(month).toFormat("LLLL yyyy"), value: month })}
                                        />
                                    </Box>
                                </HStack>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button.Group space={2} w="full">
                                    <>
                                        {dialogLogic.payload && (
                                            <IconButton
                                                variant="solid"
                                                colorScheme="warmGray"
                                                onPress={handleDeleteYearDispo}
                                                width="10"
                                                // isLoading={deleteSpinner.loading}
                                                icon={<DeleteIcon as={FontAwesome5} name="delete" size="sm" />}
                                            />
                                        )}
                                    </>
                                    <Spacer />
                                    <Button variant="subtle2" colorScheme="gray" onPress={() => dialogLogic.hide("cancel")}>
                                        Annuler
                                    </Button>

                                    <SubmitButton isLoading={deleteSpinner.loading} colorScheme="pedagome">
                                        Enregistrer
                                    </SubmitButton>
                                </Button.Group>
                            </Modal.Footer>
                        </Modal.Content>
                    )
                }}
            </Formik>
        </FastModal>
    )
}

function generateDaysList(month: string | null, startDay: number = 1) {
    let daysInMonth: number
    if (month) {
        daysInMonth = DateTime.fromISO(month).daysInMonth!
    } else {
        daysInMonth = 31
    }

    return Array.from({ length: daysInMonth - startDay + 1 }, (_, i) => i + startDay)
}

function generateMonthsList(startMonth?: string | null) {
    const now = DateTime.now()

    const startMonthNb = startMonth ? DateTime.fromISO(startMonth).month : now.month
    const startYearNb = startMonth ? DateTime.fromISO(startMonth).year : now.year

    const offset = startMonthNb >= SCHOOL_YEAR_START_MONTH ? startMonthNb - SCHOOL_YEAR_START_MONTH : startMonthNb + (12 - SCHOOL_YEAR_START_MONTH)

    // Créez une liste de mois pour l'année scolaire (septembre à septembre).
    const schoolYearMonths = Array.from(
        { length: 13 - offset },
        (_, i) =>
            now
                .set({
                    year: startYearNb,
                    month: i + startMonthNb,
                    day: 1,
                })
                .toISODate()!
    )

    return schoolYearMonths
}

// const unifiedDate = selectedMonthIndex !== undefined && selectedDay !== undefined ? DateTime.fromISO(schoolYearMonths[selectedMonthIndex]).set({ day: selectedDay }).toISODate() : ""
// {selectedMonthIndex !== undefined && selectedDay !== undefined && <Text>Date sélectionnée: {unifiedDate}</Text>}

// const handleMonthChange = (newMonthIndex: number) => {
//     setSelectedMonthIndex(newMonthIndex)
//     setSelectedDay(undefined) // Réinitialisez la sélection du jour
//     const newDate = schoolYearMonths[newMonthIndex]
//     onDateChange(newDate)
// }

// const handleDayChange = (newDay: number) => {
//     setSelectedDay(newDay)
//     const selectedMonth = DateTime.fromISO(schoolYearMonths[selectedMonthIndex!])
//     const newDate = selectedMonth.set({ day: newDay }).toISODate()
//     onDateChange(newDate)
// }
