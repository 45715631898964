import { ComboBox, type ComboBoxProps } from "@core/controls/ComboBox"
import { useField, useFormikContext } from "formik"

export type FmkComboBoxProps<OptionData> = Omit<ComboBoxProps<OptionData>, "value" | "onChange" | "error" | "onBlur"> & {
    name: string
}

export function FmkComboBox<OptionData>(props: FmkComboBoxProps<OptionData>) {
    const { name, ...otherProps } = props

    const [field, meta, { setValue, setTouched }] = useField<string>(name)
    const { isSubmitting } = useFormikContext()

    const handleChange = (newValue: any | any[], optionData: any) => {
        setTouched(true, false)
        setValue(newValue, true)
    }

    const defaultProps: Partial<ComboBoxProps<OptionData>> = {
        onChange: handleChange as any,
    }

    const config = {
        ...field, //props from formik to field (value, onChange, onBlur, name, multiple, checked )
        ...defaultProps,
        ...otherProps,
    }
    if (meta && meta.touched && meta.error) {
        config.error = meta.error
    }

    config.isDisabled = config.isDisabled ?? isSubmitting

    return <ComboBox {...config} />
}
