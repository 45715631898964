import { ScrollViewGrow } from "@core/atoms"
import { WarningAlert } from "@core/atoms/WarningAlert"
import { useAuthContext } from "@core/context/auth"
import { ContentContainerColumn, ContentContainerRow } from "@core/misc-components/ContentContainer"
import { AppBar } from "@core/molecules/AppBar"
import { user_info_query } from "@core/queries"
import { toFrenchRelativeCalendar } from "@core/utils"
import { FontAwesome5 } from "@expo/vector-icons"
import type { NavigationProp } from "@react-navigation/native"
import { DateTime } from "luxon"
import { Button, Heading, HStack, Icon } from "native-base"

import type { MainStackParamsList } from "../MainStack"
import { WeekDispoTable } from "../user-screen/EditWeekDispoForm"
import { useEditWeekDispoForm } from "../user-screen/WeekDispoScreenComponentAndUtils/useEditWeekDispoForm"
import { useSnoozeLocalStorage } from "./useSplashScreenNotifications"

export function WeekDisposStaleSplashContent(props: { navigation: NavigationProp<MainStackParamsList> }) {
    const logic = useEditWeekDispoForm({ navigation: props.navigation, displayAppBar: false })
    const auth = useAuthContext()
    const { userInfoQuery } = user_info_query.use({ teacherId: auth.userId })
    const expirationDate = userInfoQuery.data?.weekDispoLastUpdatedAt
    const durationSinceStale = toFrenchRelativeCalendar(DateTime.fromJSDate(expirationDate!))
    const isInputValid = logic.draftDispos.length < 3
    const { snooze } = useSnoozeLocalStorage()

    return (
        <>
            <AppBar noBackButton>
                <ContentContainerRow flex={1}>
                    <Heading size="md">Vérification des dispos hebdo</Heading>
                </ContentContainerRow>
            </AppBar>
            <ScrollViewGrow>
                <ContentContainerColumn my="4" space="4">
                    <WarningAlert
                        text={`Tes disponibilités hebdomadaires ont été confirmées pour la dernière fois ${durationSinceStale}.\nTu peux les conserver ou bien les corriger.\nClique sur les créneaux pour les éditer.`}
                    />
                    <WeekDispoTable logic={logic} />
                </ContentContainerColumn>
            </ScrollViewGrow>
            <HStack backgroundColor={logic.barBg} shadow="1" h="60px">
                <ContentContainerRow padding={2} justifyContent="right" space={1}>
                    <Button variant="solid" colorScheme="gray" onPress={() => snooze("WEEK_DISPOS_STALE", 15)} leftIcon={<Icon as={FontAwesome5} name="clock" size="xs" />}>
                        Plus tard
                    </Button>
                    <Button isLoading={logic.isSubmitting} isDisabled={isInputValid} colorScheme="pedagome" onPress={() => logic.defaultHandleSubmit()} leftIcon={<Icon as={FontAwesome5} name="save" size="xs" />}>
                        J'approuve
                    </Button>
                </ContentContainerRow>
            </HStack>
        </>
    )
}
