import { Lesson, rasmik } from "@core/services/rasmik"
import { DryLessonStatusEntries } from "@core/services/swaggerClient"
import { getShowedMonthStr } from "@core/utils"
import { DayDate } from "@core/utils/DayDate"
import { find, groupBy, map, sortBy } from "lodash"

import { arrayQueryHelpers, defineQuery } from "./utils"

/**
 * Lessons du mois déjà déposées
 */
export const current_real_lessons_query = defineQuery("currentRealLessonsQuery", ({ teacherId }: { teacherId: number | null | undefined }) => ({
    key: ["current_real_lessons", { teacherId }],
    queryFn: async (qryCtx, auth) => {
        // console.debug('current_lessons start-' + (cpt++, cpt))
        await auth!.ensureValidToken()
        // console.debug('current_lessons token-checked-' + cpt)

        const currentMonth = getShowedMonthStr()
        let currentLessons = await rasmik
            .readMany(Lesson)
            .where({
                Match: { Teacher: teacherId },
                monthOfficialFormula: currentMonth,
                status: [DryLessonStatusEntries.SUBMITTED, DryLessonStatusEntries.PAYSLIPPED],
                startedAtOfficial: { $ne: null },
            })
            .run()
        currentLessons = sortBy(currentLessons, (lsn) => lsn.startedAtOfficial)
        const grouped = map(
            groupBy(currentLessons, (lsn) => lsn.Match),
            (matchLessons, matchId$) => ({ matchId: +matchId$, lessons: matchLessons })
        )
        return grouped
    },
    defaultOptions: { enabled: !!teacherId, useErrorBoundary: true, initialData: [] as any },
})).withHelpers((query) => ({
    findLessonsByMatchId(matchId: number) {
        return find(query.data, { matchId })?.lessons || []
    },
    findLessonById(lessonId: number) {
        return find(query.data?.flatMap((item) => item.lessons), { id: lessonId })
    },
    getAllLessons() {
        const currentLessons = query.data?.flatMap((item) => item.lessons)
        return sortBy(currentLessons, (lsn) => lsn.startedAtOfficial)
    },
    ...arrayQueryHelpers(query),
}))

export type CurrentRealLessonsQueryData = (typeof current_real_lessons_query)["dataShape"]
