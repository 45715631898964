import { persist } from "easy-peasy"
import { Action, Model, Property } from "easy-peasy-classes"

import { EzModel } from "./base"
import { persistedModel } from "./persisted"
import { ezpzAsyncStorage } from "./storage"
// import { userModel } from "./user"
//type tabDef = [string,string]
//type tabs = Array<tabDef>

@Model
export class topModel extends EzModel {
    // @Child
    // user: userModel = userModel.obj

    @Property
    persisted: persistedModel = persist(persistedModel.obj, { storage: ezpzAsyncStorage })

    // @Child
    // msgBox:msgBoxModel = msgBoxModel.obj

    @Property
    private initialState: any = null

    @Action
    setInitialState(initialState: any) {
        this.initialState = initialState
    }

    @Property
    globalLoading: boolean = false

    @Action
    startGlobalLoading() {
        this.globalLoading = true
    }

    @Action
    stopGlobaLoading() {
        this.globalLoading = false
    }

    @Property
    networkErrorScreenVisible: boolean = false

    @Action
    showNetworkErrorScreen() {
        this.networkErrorScreenVisible = true
    }

    @Action
    hideNetworkErrorScreen() {
        this.networkErrorScreenVisible = false
    }
}
