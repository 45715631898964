import { MainCity, Mobility, rasmik } from "@core/services/rasmik"

import { defineQuery } from "./utils"

export const main_cities_query = defineQuery("mainCitiesQuery", () => ({
    key: ["main_cities_query"],
    queryFn: async (qryCtx, auth) => {
        await auth!.ensureValidToken()

        return await rasmik.readMany(MainCity).run()
    },
}))

export type MainCitiesQueryData = (typeof main_cities_query)["dataShape"]
