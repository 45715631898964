import { useAuthContext } from "@core/context/auth"
import useLocalStorageSync from "@core/hooks/useLocalStorageSync"
import { user_info_query } from "@core/queries"
import type { NavigationProp } from "@react-navigation/native"
import { DateTime } from "luxon"

import type { MainStackParamsList } from "../MainStack"
import { MiscInfosStaleSplashContent } from "./MiscInfosStaleSplashContent"
import { ScolarityStaleSplashContent } from "./ScolarityStaleSplashContent"
import { WeekDisposStaleSplashContent } from "./WeekDisposStaleSplashContent"
import { YearDisposStaleSplashContent } from "./YearDisposStaleSplashContent"

export const SplashNotifTypeEnum = {
    WEEK_DISPOS_STALE: "WEEK_DISPOS_STALE",
    YEAR_DISPOS_STALE: "YEAR_DISPOS_STALE",
    MISC_INFOS_STALE: "MISC_INFOS_STALE",
    SCOLARITY_STALE: "SCOLARITY_STALE",
}
export type SplashNotifType = keyof typeof SplashNotifTypeEnum

export function useSplashScreenNotifications(navigation: NavigationProp<MainStackParamsList>) {
    //cheker si c'est expiré

    const auth = useAuthContext()
    const { userInfoQuery } = user_info_query.use({ teacherId: auth.userId })
    const { getIsSnoozed } = useSnoozeLocalStorage()

    const isWeekDisposStale = userInfoQuery.data && userInfoQuery.data.weekDispoExpiredAt && userInfoQuery.data.weekDispoExpiredAt > (userInfoQuery.data.weekDispoLastUpdatedAt ?? new Date(0))
    const isYearDisposStale = userInfoQuery.data && userInfoQuery.data.yearDispoExpiredAt && userInfoQuery.data.yearDispoExpiredAt > (userInfoQuery.data.yearDispoLastUpdatedAt ?? new Date(0))
    const isScolarityDisposStale = userInfoQuery.data && userInfoQuery.data.scolarityExpiredAt && userInfoQuery.data.scolarityExpiredAt > (userInfoQuery.data.scolarityLastUpdatedAt ?? new Date(0))
    const isMiscInfosStale = userInfoQuery.data && userInfoQuery.data.miscInfosExpiredAt && userInfoQuery.data.miscInfosExpiredAt > (userInfoQuery.data.miscInfosLastUpdatedAt ?? new Date(0))

    let currentType: SplashNotifType | null, element: JSX.Element | null
    if (isMiscInfosStale && !getIsSnoozed("MISC_INFOS_STALE")) {
        currentType = "MISC_INFOS_STALE"
        element = <MiscInfosStaleSplashContent navigation={navigation} />
    } else if (isScolarityDisposStale && !getIsSnoozed("SCOLARITY_STALE")) {
        currentType = "SCOLARITY_STALE"
        element = <ScolarityStaleSplashContent navigation={navigation} />
    } else if (isYearDisposStale && !getIsSnoozed("YEAR_DISPOS_STALE")) {
        currentType = "YEAR_DISPOS_STALE"
        element = <YearDisposStaleSplashContent navigation={navigation} />
    } else if (isWeekDisposStale && !getIsSnoozed("WEEK_DISPOS_STALE")) {
        currentType = "WEEK_DISPOS_STALE"
        element = <WeekDisposStaleSplashContent navigation={navigation} />
    } else {
        currentType = null
        element = null
    }

    return {
        element,
        currentType,
        isWeekDisposStale,
        isScolarityDisposStale,
    }
}

type ParsedData = Partial<Record<SplashNotifType, string>>

export function useSnoozeLocalStorage() {
    const [snoozeTimeISOMap, setSnoozeTimeISOMap] = useLocalStorageSync<ParsedData>("splashScreenSnoozeTime", {})

    const snooze = (key: SplashNotifType, durationMinutes: number) => {
        const dateTime = DateTime.now().plus({ minutes: durationMinutes })

        setSnoozeTimeISOMap((snoozeMap) => {
            const newMap = { ...snoozeMap }
            newMap[key] = dateTime.toISO()!
            return newMap
        })
    }

    const getSnoozeTime = (key: SplashNotifType) => {
        const res = (snoozeTimeISOMap ?? {})[key]
        if (res) return new Date(res)
        return null
    }

    const getIsSnoozed = (key: SplashNotifType) => {
        const snoozeTime = getSnoozeTime(key)
        const isSnoozed = !!snoozeTime && snoozeTime > new Date()
        return isSnoozed
    }

    return {
        snooze,
        getIsSnoozed,
        getSnoozeTime,
    }
}
