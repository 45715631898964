import { rasmik, StudyLevel } from "@core/services/rasmik"

import { defineQuery } from "./utils"

export const studies_level_query = defineQuery("studiesLevelQuery", () => ({
    key: ["studies_level_query"],
    queryFn: async (qryCtx, auth) => {
        await auth!.ensureValidToken()

        const studiesLevel = await rasmik.readMany(StudyLevel).run()

        return studiesLevel
    },
}))

export type StudiesLevelQueryData = (typeof studies_level_query)["dataShape"]
