import React, { useCallback, useMemo, useRef } from "react"

import { type InitMsgBoxPayload, MsgBoxContext, type MsgBoxState } from "./context"
import { MsgBox } from "./MsgBox"

export function MsgBoxProvider(props: React.PropsWithChildren<object>) {
    const [msgBoxState, setMsgBoxState] = React.useState<MsgBoxState>({
        isOpen: false,
        title: "Boite de message",
        msg: "Voici un message",
        buttons: {
            cancel: "Annuler",
            accept: "Accepter",
        },
    })

    const resolveRef = useRef<(value: any) => void>()

    const init = useCallback(
        async (payload: Partial<InitMsgBoxPayload>) => {
            setMsgBoxState((state) => ({ ...state, ...payload, isOpen: true }))
            return new Promise<string>((resolve) => {
                resolveRef.current = resolve
            })
        },
        [resolveRef, setMsgBoxState]
    ) as any

    const handleClick = useCallback(
        (result: string) => {
            setMsgBoxState((state) => ({ ...state, isOpen: false }))
            resolveRef.current && resolveRef.current(result)
        },
        [resolveRef, setMsgBoxState]
    )

    const contextValue = useMemo(() => {
        return {
            state: msgBoxState,
            actions: {
                init,
                handleClick,
            },
        }
    }, [msgBoxState, init, handleClick])

    return (
        <MsgBoxContext.Provider value={contextValue}>
            <MsgBox />
            {props.children}
        </MsgBoxContext.Provider>
    )
}
