import { useAuthContext } from "@core/context/auth"
import { type LinkingOptions, NavigationContainer } from "@react-navigation/native"
import { createStackNavigator } from "@react-navigation/stack"
import { useColorMode } from "native-base"
import React from "react"
import { ActivityIndicator } from "react-native"

import { AuthStack } from "../../auth-stack/AuthStack"
import { LoginResetScreen } from "../../auth-stack/LoginResetScreen"
import { MainStack } from "../../new-stack/MainStack"
import { useEzState } from "../store"
import { linking } from "./linking"
import { useNavigationTheme } from "./theme"

// import { MsgBox } from '../components/MsgBox';

/**
 * Base de @react-navigation
 * C'est ici qu'on set le theme propre à @react-navigation. Ce theme est utilisé pour le background de l'app et les tabs
 *  */

const GlobNav = createStackNavigator<GlobStackParamsList>()
export type GlobStackParamsList = {
    AuthStack: { prev: string | undefined }
    MainStack: undefined
    LoginReset: { login?: string; password?: string }
}

export function Navigator() {
    const { colorMode } = useColorMode()
    const navigationTheme = useNavigationTheme(colorMode)
    const auth = useAuthContext()
    const networkErrorScreenVisible = useEzState((s) => s.networkErrorScreenVisible)

    return (
        <NavigationContainer
            linking={linking}
            fallback={<ActivityIndicator color="blue" size="large" />}
            theme={navigationTheme}
            documentTitle={{
                formatter: (options, route) => (options?.title ? `Pedagome | ${options?.title}` : "Pedagome"),
            }}
        >
            <GlobNav.Navigator screenOptions={{ headerShown: false }} initialRouteName="MainStack" detachInactiveScreens>
                {/* {networkErrorScreenVisible && <BadConnexionFallback />} */}
                {/* {!networkErrorScreenVisible && auth.isLoggedIn === false && <AuthStack />} */}
                {/* {!networkErrorScreenVisible && <MainStack />} */}

                <GlobNav.Screen name="MainStack" component={MainStack} />
                <GlobNav.Screen name="AuthStack" component={AuthStack} />
                <GlobNav.Screen name="LoginReset" component={LoginResetScreen} />
            </GlobNav.Navigator>

            {/* <MsgBox/> */}
        </NavigationContainer>
    )
}
