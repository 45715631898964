import { ScrollViewGrow } from "@core/atoms"
import { WarningAlert } from "@core/atoms/WarningAlert"
import { useAuthContext } from "@core/context/auth"
import { ContentContainerColumn, ContentContainerRow } from "@core/misc-components/ContentContainer"
import { AppBar } from "@core/molecules/AppBar"
import { user_info_query } from "@core/queries"
import { toFrenchRelativeCalendar } from "@core/utils"
import type { NavigationProp } from "@react-navigation/native"
import { DateTime } from "luxon"
import { Heading } from "native-base"

import type { MainStackParamsList } from "../MainStack"
import { EditScolarityForm } from "../user-screen/EditScolarityScreen"
import { useSnoozeLocalStorage } from "./useSplashScreenNotifications"

export function ScolarityStaleSplashContent(props: { navigation: NavigationProp<MainStackParamsList> }) {
    const auth = useAuthContext()
    const { userInfoQuery } = user_info_query.use({ teacherId: auth.userId })
    const expirationDate = userInfoQuery.data?.scolarityLastUpdatedAt
    const durationSinceStale = toFrenchRelativeCalendar(DateTime.fromJSDate(expirationDate!))
    const { snooze } = useSnoozeLocalStorage()

    return (
        <>
            <AppBar noBackButton>
                <ContentContainerRow flex={1}>
                    <Heading size="md">Vérification de tes études</Heading>
                </ContentContainerRow>
            </AppBar>
            <ScrollViewGrow>
                <ContentContainerColumn my="4">
                    <WarningAlert text={`Tes études ont été confirmées pour la dernière fois ${durationSinceStale}.\nTu peux les conserver ou bien les corriger.`} />
                    <EditScolarityForm navigation={props.navigation} isSplashScreen onSnooze={() => snooze("SCOLARITY_STALE", 15)} />
                </ContentContainerColumn>
            </ScrollViewGrow>
        </>
    )
}
