import { Alert, HStack, Text } from "native-base"

export function WarningAlert(props: { text: string }) {
    return (
        <Alert marginTop="5px" status="warning" colorScheme="warning">
            <HStack space={1} alignItems="center" justifyContent="space-between">
                <Alert.Icon />
                <Text>{props.text}</Text>
            </HStack>
        </Alert>
    )
}
