import { FastModal } from "@core/atoms/FastModal"
import {
    Box,
    CheckIcon,
    ChevronDownIcon,
    CloseIcon,
    FormControl,
    HStack,
    IconButton,
    type IFormControlProps,
    type IInputProps,
    type IModalProps,
    Input,
    Modal,
    Pressable,
    Text,
    useColorModeValue,
    useTheme,
    ZStack,
} from "native-base"
import { useCallback } from "react"

import { useDialog } from "../hooks/useDialog"

export interface ComboBoxProps<OptionData> {
    error?: string
    helperText?: string
    label?: string
    isDisabled?: boolean

    value: any
    options: OptionData[]
    onChange: (selectedValue: any, selectedData: OptionData | null) => void
    configure: (option: OptionData) => { value: any; label: any; disabled?: boolean }
    modalHeader?: string
    clearable?: boolean
    _formControl?: IFormControlProps
    _input?: IInputProps
    _modal?: IModalProps
}

type Option<OptionData = any> = { value: any; label: string; disabled: boolean; data: OptionData }

export function ComboBox<OptionData>(props: ComboBoxProps<OptionData>) {
    const { configure, options, onChange, value, modalHeader, clearable, error, helperText, isDisabled, label, _formControl, _input, _modal } = props

    const optionsX = options.map((option) => {
        const optX = configure(option)
        return {
            ...optX,
            data: option,
        }
    }) as Option<OptionData>[]

    const selectedOptx = optionsX.find((optX) => optX.value === value)
    const isEmpty = !selectedOptx

    const modalLogic = useDialog()

    const handleChange = useCallback(
        (selectedOption: Option<OptionData>) => {
            let selectedData, selectedValue

            if (selectedOption !== undefined && selectedOption !== null) {
                const selectedOptionX = configure(selectedOption.data)

                if (selectedOptionX.value === value && clearable) {
                    selectedData = null
                    selectedValue = null
                } else {
                    selectedData = selectedOption.data
                    selectedValue = selectedOptionX.value
                }
            } else {
                selectedData = null
                selectedValue = null
            }

            onChange && onChange(selectedValue, selectedData)
            modalLogic.hide()
        },
        [onChange, modalLogic, configure, value, clearable]
    )

    const theme = useTheme()
    const iconColor = useColorModeValue(theme.colors.darkText, theme.colors.lightText)

    return (
        <FormControl isInvalid={!!error} isDisabled={isDisabled || !options?.length} {..._formControl}>
            {label && <FormControl.Label>{label}</FormControl.Label>}

            <ZStack>
                <Input
                    value={isEmpty ? "" : selectedOptx!.label}
                    InputRightElement={
                        <HStack flexGrow={1} justifyContent="flex-end">
                            <ChevronDownIcon size={6} style={{ color: "rgb(163, 163, 163)", padding: 4, marginRight: 4 }} />
                        </HStack>
                    }
                    {..._input}
                />
                <Pressable isDisabled={isDisabled || !options?.length} onPress={() => modalLogic.open()} w="full" h="full" style={{ backgroundColor: "yellow", opacity: 0 }} />
            </ZStack>
            {helperText && <FormControl.HelperText>{helperText}</FormControl.HelperText>}
            {error && <FormControl.ErrorMessage>{error}</FormControl.ErrorMessage>}

            {modalLogic.isOpen && (
                <FastModal isOpen backdropOpacity={0.6}>
                    <Modal.Content width="350px" position="relative">
                        <IconButton icon={<CloseIcon size="xs" color={iconColor} />} onPress={modalLogic.hide} position="absolute" top="10px" right="5px" zIndex={1} />
                        {modalHeader && <Modal.Header>{modalHeader}</Modal.Header>}

                        <Modal.Body>
                            {optionsX.map((optX) => (
                                <ItemOption key={optX.value} isSelected={optX.value === value} onPress={() => handleChange(optX)} isDisabled={optX.disabled}>
                                    {optX.label}
                                </ItemOption>
                            ))}
                        </Modal.Body>
                    </Modal.Content>
                </FastModal>
            )}
        </FormControl>
    )
}

export function ItemOption(props: { children: string; onPress: () => any; isDisabled?: boolean; isSelected?: boolean }) {
    return (
        <Pressable
            onPress={props.onPress}
            isDisabled={props.isDisabled}
            _light={{
                // backgroundColor: "white",
                _hover: { backgroundColor: "gray.200" },
            }}
            _dark={{
                borderColor: "gray.700",
                // backgroundColor: "dark.100",
                _hover: { backgroundColor: "dark.200" },
            }}
        >
            <HStack alignItems="center">
                <Box width="25px">{props.isSelected && <CheckIcon size="xs" />}</Box>
                <Text paddingY="7px">{props.children}</Text>
            </HStack>
        </Pressable>
    )
}

//     {/* <Menu.OptionGroup title={null} type="radio" value={value} _title={{height:0}}> */}
//     {optionsX.map(optX => (
//         // <Pressable  disabled={optX.disabled}>
//         <Menu.ItemOption key={optX.value} value={optX.value} onPress={() => handleChange(optX)} isDisabled={optX.disabled}>{optX.label}</Menu.ItemOption>
//         // </Pressable>
//     ))}
// {/* </Menu.OptionGroup> */}
