import AsyncStorage from "@react-native-async-storage/async-storage"

/**
 * Adapts cross platform storage to easy-peasy (same as localeStorage on web)
 * Used primarily to store auth data
 */

export const ezpzAsyncStorage = {
    async getItem(key: string) {
        const item = await AsyncStorage.getItem(key)
        if (!item) return
        return JSON.parse(item)
    },
    async setItem(key: string, data: any) {
        AsyncStorage.setItem(key, JSON.stringify(data))
    },
    async removeItem(key: string) {
        const item = await AsyncStorage.getItem(key)
        if (!item) return
        AsyncStorage.removeItem(key)
    },
}
