import { isNil } from "lodash"
import { useCallback, useRef, useState } from "react"

import { useStateRef } from "./useStateRef"

//---------- DIALOG CLASSIC -------------

export function useDialog<Payload = void>() {
    const [isOpen, setOpened] = useState(false)
    const [payload, setPayload] = useState<Payload | null>(null)
    const open = useCallback((payload: Payload) => {
        setOpened(true)
        payload && setPayload(payload)
    }, [])
    const hide = useCallback(() => {
        setOpened(false)
        setPayload(null)
    }, [])
    return {
        isOpen,
        isClosed: !isOpen,
        open,
        hide,
        payload,
    }
}

export type DialogLogic<Payload = void> = {
    isOpen: boolean
    isClosed: boolean
    open: (payload: Payload) => void
    hide: () => void
    payload: Payload | null
}

//---------- DIALOG AWAIT -------------

const dialogAwaitIOSymbol = Symbol("dialogAwaitIO")

export type DialogAwaitIO<IO extends { payload: any; outcome: any; status?: string }> = {
    payload: IO["payload"]
    outcome: IO["outcome"]
    status: undefined extends IO["status"] ? string : IO["status"]
    // status: undefined extends IO["status"] ? "success" | "cancel" : IO["status"]
    "Must use useDialogAwait<DialogAwaitIO<...>> syntax": typeof dialogAwaitIOSymbol
}
export type DialogAwaitLogic<IO extends DialogAwaitIO<{ payload: any; outcome: any; status?: string }> = DialogAwaitIO<{ outcome: void; payload: void }>> = {
    isOpen: boolean
    isClosed: boolean
    open: (payload: IO["payload"]) => Promise<{
        status: IO["status"]
        outcome?: IO["outcome"] | undefined
    }>
    hide: (status: string extends IO["status"] ? "success" | "cancel" : IO["status"], outcome?: IO["outcome"] | undefined) => void
    payload: IO["payload"] | null
    // status: IO["status"]
}

export function useDialogAwait<IO extends DialogAwaitIO<any> = DialogAwaitIO<{ outcome: void; payload: void }>>() {
    const resolveRef = useRef<(...args: any[]) => void>()
    const promiseRef = useRef<Promise<{ status: IO["status"]; outcome?: IO["outcome"] }>>()
    const [isOpen, setOpened, isOpenRef] = useStateRef(false)
    const [payload, setPayload] = useState<IO["payload"] | null>(null)

    const open = useCallback(
        (payload: IO["payload"]) => {
            if (isOpenRef.current) {
                //if already open, return current promise.
                setPayload(payload)
                return promiseRef.current as Promise<{ status: IO["status"]; outcome?: IO["outcome"] }>
            } else {
                //open and create a promise that will resolve when dialog closes
                setOpened(true)
                setPayload(payload)
                const promise = new Promise<{ status: IO["status"]; outcome?: IO["outcome"] }>((_resolve, _reject) => {
                    resolveRef.current = _resolve
                })
                promiseRef.current = promise
                return promise
            }
        },
        [isOpenRef, setOpened]
    )
    const hide = useCallback(
        (status: IO["status"], outcome?: IO["outcome"]) => {
            const resolve = resolveRef.current
            resolve!({ outcome, status })
            setOpened(false)
            setPayload(null)
        },
        [setOpened]
    )

    return {
        isOpen,
        isClosed: !isOpen,
        open,
        hide,
        payload,
    }
}

export function useDialogIndex<N extends string = "Dialog">(
    dialogName?: N
): { [K in `open${N}`]: (idx: number) => void } & { [K in `close${N}`]: () => void } & {
    [K in `is${N}Open`]: boolean
} & { [K in `indexOf${N}`]: number | null } {
    dialogName = dialogName ?? ("Dialog" as any)
    const [index, setIndex] = useState<number | null>(null)
    const open = useCallback((idx: number) => {
        setIndex(idx)
    }, [])
    const close = useCallback(() => {
        setIndex(null)
    }, [])
    return {
        [`is${dialogName}Open`]: !isNil(index),
        [`indexOf${dialogName}`]: index,
        [`open${dialogName}`]: open,
        [`close${dialogName}`]: close,
    } as any
}

export function useDialogIndexCU<N extends string = "Dialog">(
    dialogName?: N
): { [K in `openEdit${N}`]: (idx: number) => void } & { [K in `openCreate${N}`]: () => void } & {
    [K in `close${N}`]: () => void
} & { [K in `is${N}Open`]: boolean } & { [K in `indexOf${N}`]: number | null } & {
    [K in `modeOf${N}`]: "create" | "edit"
} {
    dialogName = dialogName ?? ("Dialog" as any)
    const [index, setIndex] = useState<number | null>(null)
    const openEdit = useCallback((idx: number) => {
        setIndex(idx)
    }, [])
    const openCreate = useCallback(() => {
        setIndex(-1)
    }, [])
    const close = useCallback(() => {
        setIndex(null)
    }, [])
    return {
        [`is${dialogName}Open`]: !isNil(index),
        [`indexOf${dialogName}`]: index,
        [`openEdit${dialogName}`]: openEdit,
        [`openCreate${dialogName}`]: openCreate,
        [`close${dialogName}`]: close,
        [`modeOf${dialogName}`]: index === -1 ? "create" : "edit",
    } as any
}
