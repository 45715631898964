import { FastModal } from "@core/atoms/FastModal"
import { isString } from "lodash"
import { Button, Modal } from "native-base"
import React from "react"

import { MsgBoxContext } from "./context"

export function MsgBox() {
    const ctxValue = React.useContext(MsgBoxContext)

    return (
        <FastModal isOpen={ctxValue.state.isOpen}>
            <Modal.Content>
                {/*   <Modal.CloseButton /> */}
                <Modal.Header>{ctxValue.state.title}</Modal.Header>
                <Modal.Body>{ctxValue.state.msg}</Modal.Body>
                <Modal.Footer>
                    <Button.Group space={2}>
                        <GetButtons />
                    </Button.Group>
                </Modal.Footer>
            </Modal.Content>
        </FastModal>
    )

    function GetButtons() {
        const ctxValue = React.useContext(MsgBoxContext)

        return (
            <>
                {ctxValue.state.buttons &&
                    Object.keys(ctxValue.state.buttons).map((key, index) => {
                        const itemConf = ctxValue.state.buttons[key]

                        if (isString(itemConf)) {
                            const buttonLabel = itemConf
                            return (
                                <Button key={key} onPress={() => ctxValue.actions.handleClick(key)} variant="subtle2" colorScheme="trueGray">
                                    {buttonLabel}
                                </Button>
                            )
                        } else {
                            return (
                                <Button key={key} onPress={() => ctxValue.actions.handleClick(key)} variant="subtle2" colorScheme="trueGray" {...itemConf?.buttonProps}>
                                    {itemConf?.label || `bouton ${index + 1}`}
                                </Button>
                            )
                        }
                    })}
            </>
        )
    }

    // <Modal /* leastDestructiveRef={cancelRef} */ isOpen={$s.isOpen} /* onClose={onClose} */>      <Modal.Content>
    //   {/*   <Modal.CloseButton /> */}
    //   <Modal.Header>{$s.title}</Modal.Header>
    //   <Modal.Body>{$s.msg}  </Modal.Body>
    //   <Modal.Footer>
    //     <Button.Group space={2}>
    //       <GetButtons $s={$s} $a={$a} /* cancelRef={cancelRef}  */ />
    //     </Button.Group>
    //   </Modal.Footer>
    // </Modal.Content>
    // </Modal>
}

//cancelRef sert à mettre le focus sur le bouton le moins dangereux
