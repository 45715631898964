import { Mobility, rasmik } from "@core/services/rasmik"

import { defineQuery } from "./utils"

export const mobilities_query = defineQuery("mobilitiesQuery", () => ({
    key: ["mobilities_query"],
    queryFn: async (qryCtx, auth) => {
        await auth!.ensureValidToken()

        return await rasmik.readMany(Mobility).run()
    },
}))

export type MobilitiesQueryData = (typeof mobilities_query)["dataShape"]
