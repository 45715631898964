import { useCallback, useEffect, useState } from "react"

export function useBoolean(initialValue = false) {
    const [state, setState] = useState(initialValue)

    useEffect(() => {
        setState(initialValue)
    }, [initialValue])

    const setTrue = useCallback(() => {
        setState(true)
    }, [])

    const setFalse = useCallback(() => {
        setState(false)
    }, [])

    const toggle = useCallback(() => {
        setState((st) => !st)
    }, [])

    return [state, { setTrue, setFalse, toggle, setValue: setState }] as const
}
