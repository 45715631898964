import { DOPPLE_API_URL } from "@core/config/api"
import { getAxiosRetryInstance } from "@core/config/axios-retry"
import { AxiosWithInterceptors } from "@core/utils/axiosWithInterceptors"

import { authHeader } from "./authHeader"
import { RasmikClient } from "./rasmik-client"

export * from "./rasmik-client/entities"
export type { PushData, ReadData } from "./rasmik-client/lib"

export const rasmikAxios = new AxiosWithInterceptors({ unpackData: false, unpackErrors: false })

    //merge default headers and token header
    .setHeaderFn(async (configHeaders) => {
        return { ...configHeaders, ...(await authHeader()) }
    })
    .configureInstance(getAxiosRetryInstance())

export const rasmik = new RasmikClient({
    axios: rasmikAxios,
    baseUrl: DOPPLE_API_URL + "/rasmik",
    onError: (err) => {
        throw err
    },
})
