import { Box, type IPressableProps, Pressable } from "native-base"

export interface CardProps extends IPressableProps {} // (IPressableProps & {pressable:true })
export function Card(props: CardProps) {
    let Component: typeof Pressable
    //@ts-ignore
    if (props.onPress) Component = Pressable
    //@ts-ignore
    else Component = Box

    return (
        <Component
            width="full"
            rounded="lg"
            overflow="hidden"
            borderColor="coolGray.200"
            borderWidth="1"
            padding="15px"
            position="relative"
            style={{ position: "relative" }}
            _light={{
                backgroundColor: "white",
                ...(props.onPress && { _hover: { backgroundColor: "gray.200" } }),
            }}
            _dark={{
                borderColor: "gray.700",
                backgroundColor: "dark.100",
                ...(props.onPress && { _hover: { backgroundColor: "dark.200" } }),
            }}
            _web={{
                shadow: 2,
                borderWidth: 0,
            }}
            {...props}
        >
            {props.children}
        </Component>
    )
}
