import { FastModal } from "@core/atoms/FastModal"
import { useAuthContext } from "@core/context/auth"
import { FmkComboBox } from "@core/formik/FmkComboBox"
import { type DialogLogic, useCatchToBoundary, useDialog, useSpinner } from "@core/hooks"
import type { DialogAwaitLogic } from "@core/hooks/useDialog"
import { useValidationSchema } from "@core/hooks/useValidationSchema"
import { current_proposition_lessons_query, current_real_lessons_query, match_history_query, match_infos_list_query, user_info_query } from "@core/queries"
import { openapi } from "@core/services/openapi"
import { EuropeParis, getShowedMonthDt } from "@core/utils"
import { FmkUtils } from "@core/utils/formik"
import { FontAwesome5 } from "@expo/vector-icons"
import { SubmitButton } from "@native-base/formik-ui"
import { Formik } from "formik"
import { capitalize, max, min } from "lodash"
import { DateTime, Duration } from "luxon"
import { Box, Button, Heading, HStack, Icon, Modal, Spacer, Text } from "native-base"
import React, { useMemo } from "react"
import { useErrorBoundary } from "react-error-boundary"

export function BookedActions({ lessonId, matchId }: { matchId: number; lessonId: number }) {
    const auth = useAuthContext()
    const { currentPropositionLessonsQuery } = current_proposition_lessons_query.use({ teacherId: auth.userId }, { staleTime: 5000 })
    const lesson = currentPropositionLessonsQuery.helpers.findLessonById(lessonId)
    const { userInfoQuery } = user_info_query.use({ teacherId: auth.userId })

    const submitBookedDialog = useDialog()
    const cancelDialog = useDialog()

    // const msgBox = useMsgBox()

    const newProcessAfterDateStr = min([
        DateTime.fromJSDate(userInfoQuery.data?.newProcessEnabledAt ?? new Date(0))
            .plus({ days: 7 })
            .toISODate(),
        "2023-01-01",
    ])!
    const newProcessAfterDateDate = DateTime.fromISO(newProcessAfterDateStr).setZone(EuropeParis).plus({ hours: 12 }).toJSDate()

    const remainingTimeStr =
        lesson?.submittedAt &&
        DateTime.fromJSDate(max([lesson.submittedAt, newProcessAfterDateDate])!)
            .plus({ hours: 12 })
            .diffNow()
            .toFormat("h'h et' mm'min'")
            .replace(/^0h et /, "")

    return (
        <HStack alignItems="center">
            {cancelDialog.isOpen && <CancelModal logic={cancelDialog} lessonId={lessonId} matchId={matchId} />}
            {submitBookedDialog.isOpen && <SubmitBookedModal logic={submitBookedDialog} lessonId={lessonId} matchId={matchId} />}
            <Button onPress={() => cancelDialog.open()} variant="subtle2" size="sm" colorScheme="brown" startIcon={<Icon as={FontAwesome5} name="times" />}>
                N'a pas eu lieu
            </Button>
            <Spacer />
            <Button onPress={() => submitBookedDialog.open()} variant="solid" size="sm" colorScheme="pedagome" startIcon={<Icon as={FontAwesome5} name="check" />}>
                A eu lieu
            </Button>
        </HStack>
    )
}

const durationOptions = [0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4]
    .map((hour) => hour * 60)
    .map((minute) => ({
        label: Duration.fromObject({ minutes: minute }).toFormat("h'h'mm").replace("0h30", "30min").replace("h00", "h"),
        value: minute,
    }))

export class FormValues {
    @FmkUtils.DefField day: number | null
    @FmkUtils.DefField durationMin: number | null
}

const FIELDS = FmkUtils.getFields(FormValues)

export interface AddLessonModalProps {
    dialogLogic: DialogAwaitLogic
    matchId: number
}

function SubmitBookedModal({ logic, lessonId, matchId }: { logic: DialogLogic; matchId: number; lessonId: number }) {
    const schema = useValidationSchema(
        (Yup) =>
            Yup.object().shape({
                [FIELDS.day]: Yup.number().nullable().required("Requis").min(1, "Requis"),
                [FIELDS.durationMin]: Yup.number().nullable().required("Requis").min(0.5, "Requis"),
            }),
        []
    )

    const auth = useAuthContext()
    const { currentPropositionLessonsQuery } = current_proposition_lessons_query.use({ teacherId: auth.userId }, { staleTime: 5000 })
    const lesson = currentPropositionLessonsQuery.helpers?.findLessonById?.(lessonId)

    const teacherId = auth.userId

    const { matchInfosListQuery } = match_infos_list_query.use({ teacherId })
    const { currentRealLessonsQuery } = current_real_lessons_query.use({ teacherId })
    const { userInfoQuery } = user_info_query.use({ teacherId: auth.userId })
    const monthDt = getShowedMonthDt()
    const monthStr = monthDt.toISODate()
    const matchInfo = matchInfosListQuery.helpers.findById(matchId)
    // const matchLessons = currentRealLessonsQuery.data?.findByMatchId(matchId)

    //soit 7j après acceptation, soit jusqu'au 31dec
    const newProcessAfterDateStr = min([
        DateTime.fromJSDate(userInfoQuery.data?.newProcessEnabledAt ?? new Date(0))
            .plus({ days: 7 })
            .toISODate(),
        "2023-01-01",
    ])!
    const isNewProcess = DateTime.now().setZone(EuropeParis).toISODate()! >= newProcessAfterDateStr
    const isOldProcess = !isNewProcess

    const spinner = useSpinner()

    const { showBoundary } = useErrorBoundary()

    const currentDay = DateTime.now().day.valueOf()

    const dayOptions = useMemo(() => {
        if (!isOldProcess) return []

        return Array.from("x".repeat(currentDay))
            .map((x, index) => index + 1)
            .map((day) => ({
                label: `${monthDt.set({ day }).toFormat("EEE d")}`,
                value: day,
            }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOldProcess, currentDay, monthStr])

    return (
        <FastModal isOpen={logic.isOpen}>
            <Formik<FormValues>
                initialValues={{
                    durationMin: lesson?.durationMinPlanned ?? null,
                    day: DateTime.now().day,
                }}
                validationSchema={schema}
                onSubmit={async (values, { setSubmitting }) => {
                    try {
                        await auth.ensureValidToken()

                        await openapi.teachersApp.submitBookedLesson(lessonId, {
                            startedAtOfficial: isOldProcess ? monthDt.set({ day: values.day! }).toJSDate() : new Date(),
                            durationMin: values.durationMin!,
                            lessonId,
                            // startedAtUnofficial: xxx,
                            // teacherComment: xxx,
                        })

                        //refetch base query
                        await Promise.allSettled([currentPropositionLessonsQuery.refetch(), currentRealLessonsQuery.refetch()])

                        //invalidate other impacted queries
                        currentRealLessonsQuery.queryClient.invalidateQueries(match_history_query.getKey({ matchId }))
                        currentRealLessonsQuery.queryClient.invalidateQueries(match_infos_list_query.getKey({ teacherId }))

                        logic.hide()
                    } catch (err) {
                        showBoundary(err)
                    } finally {
                        setSubmitting(false)
                    }
                }}
            >
                <Modal.Content width="350px">
                    {/* <Modal.CloseButton /> */}
                    <Modal.Header>
                        <Heading size="md">Déclaration cours</Heading>
                    </Modal.Header>
                    <Modal.Body>
                        <Text fontSize="md">
                            {matchInfo?.Program?.Learner?.firstName} {matchInfo?.Program?.Learner.lastName}
                        </Text>
                        <Text color="primary.500">{matchInfo?.label}</Text>

                        {isOldProcess ? (
                            <FmkComboBox _formControl={{ mt: 3 }} name={FIELDS.day} label="Date" modalHeader={monthDt.toFormat("MMMM yyyy")} clearable options={dayOptions} configure={(opt) => opt} />
                        ) : (
                            <Text>{capitalize(DateTime.now().toFormat("EEEE dd MMMM"))}</Text>
                        )}

                        <FmkComboBox _formControl={{ mt: "3" }} name={FIELDS.durationMin} label="Durée" modalHeader="Durée" clearable options={durationOptions} configure={(opt) => opt} />

                        {isOldProcess && (
                            <Box mt="15px">
                                <Text color="danger.500">
                                    <Icon as={FontAwesome5} name="exclamation-circle" color="danger.500" mr={1} />A partir du{" "}
                                    {DateTime.fromISO(newProcessAfterDateStr).setZone(EuropeParis).minus({ day: 1 }).toFormat("d MMMM").toLowerCase()}, il ne sera plus possible de saisir des heures rétroactivement. Elles
                                    devront être saisie le jour même.
                                </Text>
                            </Box>
                        )}

                        <Box mt={2}>
                            <Text>
                                <Icon as={FontAwesome5} name="exclamation-triangle" color="warning.500" mr={1} />
                                Saisie définitive après un délai de 12h.
                            </Text>
                        </Box>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button.Group space={2}>
                            <Button variant="subtle2" colorScheme="trueGray" onPress={() => logic.hide()}>
                                Annuler
                            </Button>

                            <SubmitButton colorScheme="pedagome">Enregistrer</SubmitButton>
                        </Button.Group>
                    </Modal.Footer>
                </Modal.Content>
            </Formik>
        </FastModal>
    )
}

function CancelModal({ logic, lessonId, matchId }: { logic: DialogLogic; matchId: number; lessonId: number }) {
    const auth = useAuthContext()
    const { currentPropositionLessonsQuery } = current_proposition_lessons_query.use({ teacherId: auth.userId }, { staleTime: 5000 })
    const lesson = currentPropositionLessonsQuery.helpers?.findLessonById?.(lessonId)

    const isFastLesson = !(lesson?.proposedAt || lesson?.bookedAt)
    const spinner = useSpinner()

    const handleUnsubmit = useCatchToBoundary(async () => {
        spinner.start()

        try {
            await auth.ensureValidToken()
            await openapi.teachersApp.cancelBookedLesson(lessonId, { cancelComment: "" })

            //refetch base query
            await currentPropositionLessonsQuery.refetch()

            //invalidate other impacted queries
            currentPropositionLessonsQuery.queryClient.invalidateQueries(match_history_query.getKey({ matchId }))
            currentPropositionLessonsQuery.queryClient.invalidateQueries(match_infos_list_query.getKey({ teacherId: auth.userId }))

            logic.hide()
        } finally {
            spinner.stop()
        }
    })

    return (
        <Modal isOpen>
            <Modal.Content>
                {/*   <Modal.CloseButton /> */}
                <Modal.Header>Annulation cours prévu</Modal.Header>
                <Modal.Body>{`Le cours du ${DateTime.fromJSDate(lesson?.startedAtPlanned!).toFormat("cccc dd MMMM")} sera annulé`}</Modal.Body>
                <Modal.Footer>
                    <Button.Group space={2}>
                        <Button onPress={() => logic.hide()} variant="subtle2" colorScheme="trueGray">
                            Retour
                        </Button>
                        <Button onPress={handleUnsubmit} isLoading={spinner.loading} variant="solid" colorScheme="brown">
                            Annuler cours
                        </Button>
                    </Button.Group>
                </Modal.Footer>
            </Modal.Content>
        </Modal>
    )
}
