import { Card } from "@core/atoms"
import { useAuthContext } from "@core/context/auth"
import { useCatchToBoundary, useSpinner } from "@core/hooks"
import { current_proposition_lessons_query, current_real_lessons_query, match_infos_list_query, match_offers_query, type MatchOffersQueryItem, MAX_KM_DISTANCE, user_info_query } from "@core/queries"
import { openapi } from "@core/services/openapi"
import type { LessonAbortReason } from "@core/services/rasmik-client"
import { conditional, EuropeParis, toFrenchRelativeCalendar } from "@core/utils"
import { customRound, formatDurationMin, joinOu } from "@core/utils/format"
import { AntDesign, FontAwesome5 } from "@expo/vector-icons"
import { type NavigationProp, useNavigation } from "@react-navigation/native"
import { max, min } from "lodash"
import { DateTime, Duration } from "luxon"
import { Badge, Box, Center, ChevronRightIcon, Divider, Heading, HStack, type IBadgeProps, Icon, Text, VStack } from "native-base"
import React from "react"

import type { MainStackParamsList, MainTabsParamList } from "../MainStack"

export function OfferCard({ offer }: { offer: MatchOffersQueryItem }) {
    const auth = useAuthContext()
    const navigation = useNavigation<NavigationProp<MainTabsParamList>>()
    const parentNavigation = navigation.getParent<NavigationProp<MainStackParamsList>>()
    const showDistance = offer.shortestDistanceKm !== null && offer.shortestDistanceKm <= MAX_KM_DISTANCE && offer.wishVisio !== "Visio"

    return (
        <Card onPress={() => parentNavigation.navigate("OfferDetail", { matchId: offer.id })}>
            <HStack justifyContent="space-between" alignItems="center" space={4}>
                <VStack space={2} flex={1}>
                    <HStack justifyContent="space-between">
                        <Heading size="sm">{offer.label}</Heading>
                    </HStack>
                    <Text fontSize="xs" _light={{ color: "primary.500" }} _dark={{ color: "primary.400" }} fontWeight="500" mt="-1">
                        {offer.placeText}
                        {showDistance && ` - ${customRound(offer.shortestDistanceKm, "0.1")}km`}
                    </Text>

                    <Text fontSize="xs" mt="5px" color="coolGray.600" _dark={{ color: "warmGray.200" }} fontWeight="400">
                        Publié {toFrenchRelativeCalendar(DateTime.fromJSDate(offer.broadcastOpenedAt!))}
                    </Text>
                </VStack>

                {offer.interestAnswer === "INTERESTED" && (
                    <Center>
                        <Icon as={AntDesign} name="star" size="20px" color="yellow.500" _dark={{ color: "yellow.500" }} />
                    </Center>
                )}

                <Center opacity={offer.urgent ? 1 : 0}>
                    <Badge variant="subtle" colorScheme="yellow" rounded="full" startIcon={<FontAwesome5 name="gift" />}>
                        Parrainage 10€
                    </Badge>
                </Center>

                <ChevronRightIcon />
            </HStack>

            {/* {ActionsEl && <Divider my="10px" />}
            {ActionsEl} */}
        </Card>
    )
}
