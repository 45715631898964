import type { ApiErrors } from "@core/services/swaggerClient"

export type ApiError<N extends keyof ApiErrors> = {
    name: N
    message: string
    error: true
    status: number
    stack: string
    cause?: any
} & (ApiErrors[N] extends null ? { data?: undefined } : { data: ApiErrors[N] })

export function isApiError<N extends keyof ApiErrors>(type: N, err: any): err is ApiError<N> {
    return err.name && err.name === type
}
