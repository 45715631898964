import { useAuthContext } from "@core/context/auth"
import { useBoolean, useCatchToBoundary, useSpinner } from "@core/hooks"
import { ContentContainer } from "@core/misc-components/ContentContainer"
import { user_info_query } from "@core/queries"
import { rasmik, Teacher } from "@core/services/rasmik"
import { useEzActions } from "@core/store"
import { EuropeParis, plural } from "@core/utils"
import { FontAwesome5, MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons"
import type { StackScreenProps } from "@react-navigation/stack"
import { min } from "lodash"
import { DateTime } from "luxon"
import { Box, Button, Center, Checkbox, FormControl, Heading, HStack, Icon, Text, theme, useColorModeValue, useTheme, useToken, VStack } from "native-base"

import type { MainStackParamsList } from "./MainStack"

export function NewProcessScreen(props: StackScreenProps<MainStackParamsList, "NewProcess">) {
    const {
        navigation,
        route: { params },
    } = props

    const auth = useAuthContext()
    const { userInfoQuery } = user_info_query.use({ teacherId: auth.userId })

    const [lightText, darkText] = useToken("colors", ["lightText", "darkText"])
    const iconColor = useColorModeValue(darkText, lightText)

    const spinner = useSpinner()
    const handleSubmit = useCatchToBoundary(async () => {
        spinner.start()
        try {
            await rasmik
                .pushOne(Teacher)
                .pushDef({ allow: "update" })
                .data({
                    id: auth.userId,
                    newProcessEnabledAt: new Date(),
                })
                .run()
            await userInfoQuery.refetch()
        } finally {
            spinner.stop()
        }
    })

    const theme = useTheme()
    const [checked, { setValue }] = useBoolean()

    const newProcessAfterDateStr = min([DateTime.now().plus({ days: 7 }).toISODate(), "2023-01-01"])!

    const days = Math.ceil(DateTime.fromISO(newProcessAfterDateStr).minus({ day: 1 }).setZone(EuropeParis).diffNow("days").as("days"))

    return (
        <Center flexGrow={1}>
            <ContentContainer>
                <Center>
                    <Heading fontSize="xl" mb="15px">
                        Le mode de saisie des heures évolue.
                    </Heading>
                </Center>

                <VStack space="20px" p="20px" _dark={{ bgColor: "dark.200" }} _light={{ bgColor: "light.300" }} borderRadius="15px">
                    <HStack alignItems="center">
                        <HStack width="40px" justifyContent="center">
                            <FontAwesome5 name="exclamation-triangle" style={{ color: theme.colors.error[500], fontSize: 20 }} />
                        </HStack>
                        <Text flex={1} lineHeight="sm" color="error.500">
                            Il n'est plus possible de choisir la date lors de saisie d'un cours. Cela veut dire que les heures doivent être renseignées{" "}
                            <Text color="error.500" underline>
                                le jour même, après chaque cours
                            </Text>
                            .
                        </Text>
                    </HStack>

                    <HStack alignItems="center">
                        <HStack width="40px" justifyContent="center">
                            <FontAwesome5 name="hand-point-right" style={{ color: iconColor, fontSize: 20 }} />
                        </HStack>
                        <Text flex={1} lineHeight="sm">
                            Une fois une heure saisie, il est possible en cas d'erreur d'annuler la saisie dans un délai de 12h. Au dela la saisie est transmise définitivement.
                        </Text>
                    </HStack>

                    <HStack alignItems="center">
                        <HStack width="40px" justifyContent="center">
                            <FontAwesome5 name="hand-point-right" style={{ color: iconColor, fontSize: 20 }} />
                        </HStack>
                        <Text flex={1} lineHeight="sm">
                            Pour assurer la transition vers ce nouveau mode de saisie, tu peux execptionellement saisir les heures effectuées début décembre en choisissant la date. Cette période de transition prendra fin le{" "}
                            <Text color="error.500" underline>
                                {DateTime.fromISO(newProcessAfterDateStr).minus({ day: 1 }).setZone(EuropeParis).toFormat("d MMMM").toLowerCase()}
                            </Text>
                        </Text>
                    </HStack>

                    <HStack alignItems="center">
                        <HStack width="40px" justifyContent="center">
                            <FontAwesome5 name="hand-point-right" style={{ color: iconColor, fontSize: 20 }} />
                        </HStack>
                        <Text flex={1} lineHeight="sm">
                            Il te reste donc{" "}
                            <Text color="error.500" underline>
                                {days} {plural("jour", "jours", days)} pour saisir les heures déjà effectuées en décembre.
                            </Text>
                        </Text>
                    </HStack>

                    <HStack alignItems="center">
                        <HStack width="40px" justifyContent="center">
                            <FontAwesome5 name="hand-point-right" style={{ color: iconColor, fontSize: 20 }} />
                        </HStack>
                        <Text flex={1} lineHeight="sm">
                            Ce n'est plus la peine de cliquer sur "Envoyer mes réponses" en fin de mois. Les heures sont transmises automatiquement dès leur saisie.
                        </Text>
                    </HStack>
                </VStack>

                <Checkbox
                    mt="20px"
                    value={String(checked)}
                    isChecked={checked}
                    onChange={(isSelected) => {
                        setValue(isSelected)
                    }}
                >
                    J'ai compris
                </Checkbox>

                <Button mt="20px" isDisabled={!checked} isLoading={spinner.loading} colorScheme="primary" variant="solid" onPress={() => handleSubmit()}>
                    Valider
                </Button>
            </ContentContainer>
        </Center>
    )
}
