import { FontAwesome5 } from "@expo/vector-icons"
import { Box, Center, IconButton, useColorModeValue, useTheme, View, VStack } from "native-base"
import type { IVStackProps } from "native-base/lib/typescript/components/primitives/Stack/VStack"
import React, { type ReactNode } from "react"

export interface AppBarProps {
    noBackButton?: boolean
    onBack?: () => any
    children?: ReactNode
    _vStackProps?: IVStackProps
}
export function AppBar(props: AppBarProps) {
    const { noBackButton, onBack, children, _vStackProps } = props

    const theme = useTheme()
    const iconColor = useColorModeValue(theme.colors.light[800], theme.colors.dark[800])
    return (
        <VStack safeAreaTop _light={{ backgroundColor: "appBar.light" }} _dark={{ backgroundColor: "appBar.dark" }} shadow="1" /*  height="110px" */ {..._vStackProps}>
            <Box display="flex" flexDirection="row" alignItems="center" top={0} left={0} right={0} minHeight="56px" height={70}>
                {!noBackButton && (
                    <Center m="8px">
                        <IconButton
                            width="40px"
                            /* onPress={onBack}  */ onPress={() => {
                                onBack?.()
                            }}
                            mt="2px"
                            variant="ghost"
                            // icon={<ArrowBackIcon _light={{ color: "darkText" }} _dark={{ color: "lightText" }} />}
                            icon={<FontAwesome5 name="arrow-left" color={iconColor} size={18} />}
                        />
                    </Center>
                )}

                {noBackButton && <View width="42px" />}

                {children}

                {<View width="42px" /* Pour égaliser */ />}
            </Box>
        </VStack>
    )
}
