import { Match, rasmik, type ReadData } from "@core/services/rasmik"
import { getShowedMonthStr } from "@core/utils"
import { find } from "lodash"

import { defineQuery } from "./utils"

const matchInfoReadOptions = rasmik.define.readOptions(Match).val({
    include: ["*", "lessonsDuration", "lastLessonAt" /* , 'submittedMonths', 'unsubmittedMonths', 'selectedTeacherProposition' */],
    children: {
        Lessons: {
            include: ["proposedBy", "submittedBy", "durationMin", "id", "startedAtOfficial", "startedAtPlanned", "status"],
        },
        // PlanningAnswers: { children: { PlanningSubmission: true } },
        Program: {
            children: {
                Learner: {
                    include: ["*", "familyPhoneNumbers"],
                    children: {
                        WeekDispos: true,
                        LessonAddresses: true,
                        Family: true,
                    },
                },
            },
        },
    },
})

export type MatchInfoData = ReadData<Match, typeof matchInfoReadOptions>

export const match_infos_list_query = defineQuery("matchInfosListQuery", ({ teacherId }: { teacherId: number | null | undefined }) => ({
    key: ["match_infos_list", { teacherId }],
    queryFn: async (qryCtx, auth) => {
        console.debug("matchInfosListQuery")
        await auth!.ensureValidToken()

        const allMatches = await rasmik.readMany(Match).where({ Teacher: teacherId }).options(matchInfoReadOptions).run()

        const currentMonthStr = getShowedMonthStr()

        //TODO: faire differents filtres : matchs pour lesquels on attend des créneaux, matchs terminés ...
        const draftAcceptedMatches = allMatches.filter((match) => match.teacherActivatedAt && match.status === "DRAFT")
        const realMatches = allMatches.filter((match) => match.status === "ACTIVE" || match.status === "PAUSED" || match.status === "FINISHED")

        //On n'affiche pas les matchs avant que le client n'accepte aussi.
        const currentMonthMatches = realMatches.filter(
            (match) => match.teacherActivatedAt && match.customerActivatedAt && match.teacherActivatedAt < new Date() && (!match.planningLastMonth || match.planningLastMonth >= currentMonthStr)
        )

        return {
            allMatches,
            realMatches,
            draftAcceptedMatches,
            currentMonthMatches,
            // findById(matchId: number | undefined) { return find(allMatches, { id: matchId }) }
        }
    },
    defaultOptions: { enabled: !!teacherId, useErrorBoundary: true },
})).withHelpers((query) => ({
    findById(matchId: number | undefined) {
        return find(query.data?.allMatches, { id: matchId })
    },
}))

export type MatchInfoListQueryData = (typeof match_infos_list_query)["dataShape"]
