import AsyncStorage from "@react-native-async-storage/async-storage"
import { type Dispatch, type SetStateAction, useCallback, useLayoutEffect, useRef, useState } from "react"

// function useLocalStorage<T>(key: string, initialValue: T) {
//     // State to store our value
//     // Pass initial state function to useState so logic is only executed once
//     const [storedValue, setStoredValue] = useState<T>(() => {
//       if (typeof window === "undefined") {
//         return initialValue;
//       }
//       try {
//         // Get from local storage by key
//         const item = window.localStorage.getItem(key);
//         // Parse stored json or if none return initialValue
//         return item ? JSON.parse(item) : initialValue;
//       } catch (error) {
//         // If error also return initialValue
//         console.debug(error);
//         return initialValue;
//       }
//     });

//     // Return a wrapped version of useState's setter function that ...
//     // ... persists the new value to localStorage.

//     const setValueAsync = async (value: T | ((val: T) => T)) => {
//         try {
//             // Allow value to be a function so we have same API as useState
//             const valueToStore = value instanceof Function ? value(storedValue) : value;
//             // Save to local storage
//             await AsyncStorage.setItem(key, JSON.stringify(valueToStore))
//             // Save state
//             setStoredValue(valueToStore);
//         } catch (error) {
//             // A more advanced implementation would handle the error case
//             console.debug(error);
//             throw error
//         }
//     };
//     return [storedValue, setValueAsync] as const;
//   }

type parserOptions<T> =
    | {
          raw: true
      }
    | {
          raw: false
          serializer: (value: T) => string
          deserializer: (value: string) => T
      }

const useLocalStorageNative = <T>(key: string, initialValue?: T, options?: parserOptions<T>): [T | undefined, Dispatch<SetStateAction<T | undefined>>, () => void] => {
    //   if (!isBrowser) {
    //     return [initialValue as T, noop, noop];
    //   }
    if (!key) {
        throw new Error("useLocalStorage key may not be falsy")
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const deserializer = options ? (options.raw ? (value: any) => value : options.deserializer) : JSON.parse

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const initializer = useRef(async (key: string) => {
        try {
            const serializer = options ? (options.raw ? String : options.serializer) : JSON.stringify

            const localStorageValue = await AsyncStorage.getItem(key)
            if (localStorageValue !== null) {
                return deserializer(localStorageValue)
            } else {
                initialValue && (await AsyncStorage.setItem(key, serializer(initialValue)))
                return initialValue
            }
        } catch {
            // If user is in private mode or has storage restriction
            // localStorage can throw. JSON.parse and JSON.stringify
            // can throw, too.
            return initialValue
        }
    })

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [state, setState] = useState<T | undefined>(initialValue /* () => initializer.current(key) */)

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useLayoutEffect(() => {
        initializer.current(key).then((val) => setState(val))
    }, [key])

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const set: Dispatch<SetStateAction<T | undefined>> = useCallback(
        async (valOrFunc) => {
            try {
                const newState = typeof valOrFunc === "function" ? (valOrFunc as Function)(state) : valOrFunc
                if (typeof newState === "undefined") return
                let value: string

                if (options)
                    if (options.raw)
                        if (typeof newState === "string") value = newState
                        else value = JSON.stringify(newState)
                    else if (options.serializer) value = options.serializer(newState)
                    else value = JSON.stringify(newState)
                else value = JSON.stringify(newState)

                await AsyncStorage.setItem(key, value)
                setState(deserializer(value))
            } catch {
                // If user is in private mode or has storage restriction
                // localStorage can throw. Also JSON.stringify can throw.
            }
        },
        [deserializer, key, options, state]
    )

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const remove = useCallback(async () => {
        try {
            await AsyncStorage.removeItem(key)
            setState(undefined)
        } catch {
            // If user is in private mode or has storage restriction
            // localStorage can throw.
        }
    }, [key, setState])

    return [state, set, remove]
}

export default useLocalStorageNative
export { useLocalStorageNative }

//   async getItem(key:string) {
//     const item = await AsyncStorage.getItem(key)
//     if(!item) return
//     return JSON.parse(item)
//   },
//   async setItem(key:string, data:any) {
//     AsyncStorage.setItem(key, JSON.stringify(data))
//   },
//   async removeItem(key:string) {
//     const item = await AsyncStorage.getItem(key)
//     if(!item) return
//     AsyncStorage.removeItem(key)
//   }
