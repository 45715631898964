import type { GlobStackParamsList } from "@core/config/Navigator"
import { type NavigationProp, useNavigation } from "@react-navigation/native"
import { Button, useColorMode } from "native-base"
import type { ColorType } from "native-base/lib/typescript/components/types"

import type { MainStackParamsList } from "../../MainStack"
import type { Day, DispoByDay, DraftWeekDispo } from "../EditWeekDispoForm"
import { getThemedColor, Time } from "./TimePicker"
import { formatISOTime } from "./utils"

export function WeekDispoTag(props: { key: number; data: DraftWeekDispo; day: Day; handleDelete: (id: number) => void; handleOnPress: (time: Time, day: Day, id: number | undefined) => void; dispoByDay: DispoByDay }) {
    const { colorMode } = useColorMode()
    const navigation = useNavigation<NavigationProp<GlobStackParamsList>>()

    let color: ColorType
    if (props.data) {
        color = getThemedColor("inRange", colorMode)
    } else {
        color = getThemedColor("normal", colorMode)
    }

    const hoverColor: ColorType = getThemedColor("hover", colorMode)
    const textColor: ColorType = getThemedColor("text", colorMode)

    return (
        <Button
            onPress={() =>
                navigation.getParent<NavigationProp<MainStackParamsList, "History">>().navigate("TimePickerScreen", {
                    day: props.day,
                    handleOnPress: props.handleOnPress,
                    handleDelete: props.handleDelete,
                    // label: "début",
                    id: props.data ? props.data.id : undefined,
                    fistPress: null,
                    originalDispo: props.data ? { start: props.data.startTime, end: props.data.endTime } : null,
                    sameDayDispos: props.dispoByDay[props.day],
                })
            }
            flexGrow={1}
            maxWidth="32%"
            bgColor={color}
            _text={{ color: textColor }}
            _hover={{ bgColor: hoverColor }}
            height="35px"
            variant="subtle"
        >
            {props.data ? `${formatISOTime(props.data.startTime)} - ${formatISOTime(props.data.endTime)}` : "Vide"}
        </Button>
    )
}
