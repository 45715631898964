import { useAuthContext } from "@core/context/auth"
import { AppBar, type AppBarProps } from "@core/molecules/AppBar"
import { propositions_list_query } from "@core/queries"
import { sleep } from "@core/utils/tools"
import { MaterialCommunityIcons } from "@expo/vector-icons"
import { type NavigationProp, type RouteProp, useNavigation, useNavigationState, useRoute } from "@react-navigation/native"
import { Badge, Box, Button, Fab, Heading, HStack, Icon, PresenceTransition, Spacer, useBreakpointValue, useColorModeValue } from "native-base"
import React, { type ReactNode, useCallback, useRef, useState } from "react"

import type { MainStackParamsList, MainTabsParamList } from "../MainStack"
import { useNotificationsCount } from "../messages-screen/MessagesScreen"
import { currentScreenStore } from "./currentScreenStore"
import { FakeTabs } from "./FakeTabs"

type AppBarPosition = "top" | "bottom"

const mainTabsConfig: Record<keyof MainTabsParamList, { getTitle: () => string; getAppBarProps?: (params: { appBarLineColor: string; tabBarPosition: AppBarPosition }) => AppBarProps }> = {
    SaisieHeures: {
        getTitle: () => `Heures du mois`,
    },
    Offres: {
        getTitle: () => `Offres`,
    },
    MatchList: {
        getTitle: () => `Liste des matchs`,
    },
    SurveysList: {
        getTitle: () => `Bilans mensuels`,
    },
    User: {
        getTitle: () => ``,
        getAppBarProps: (params) => (params.tabBarPosition === "top" ? { _vStackProps: { flexGrow: 1, borderBottomColor: params.appBarLineColor, borderBottomWidth: 3 } } : {}),
    },
}

export interface AmbiantContainerProps {
    children: ReactNode | ReactNode[]
}
export function AmbiantContainer(props: AmbiantContainerProps) {
    const currentScreen = currentScreenStore.useStoreState((x) => x)

    const appBarLineColor = useColorModeValue("coolGray.200", "gray.400")
    const tabBarPosition: AppBarPosition = useBreakpointValue({ base: "bottom", md: "top" })

    // const [fadeAnim] = useState(new Animated.Value(0));
    // React.useEffect(() => {
    //     Animated.timing(fadeAnim, {
    //         toValue: 1,
    //         duration: 1000,
    //         useNativeDriver: false
    //     }).start();
    // }, [currentScreen]);

    const [isChanging, setIsChanging] = useState(false)
    const isChangingStartedRef = useRef(0)
    currentScreenStore.useWatch(
        (prev, next) => !prev.isChanging && next.isChanging,
        () => {
            setIsChanging(true)
            isChangingStartedRef.current = Date.now()
        }
    )
    currentScreenStore.useWatch(
        (prev, next) => prev.isChanging && !next.isChanging,
        async () => {
            while (Date.now() - isChangingStartedRef.current < 1500) {
                await sleep(1500 - (Date.now() - isChangingStartedRef.current))
            }
            setIsChanging(false)
            isChangingStartedRef.current = 0
        }
    )
    currentScreenStore.useWatch(
        (prev, next) => prev.name !== next.name,
        () => {
            setIsChanging(false)
            isChangingStartedRef.current = 0
        }
    )

    const navigation = useNavigation<NavigationProp<MainStackParamsList>>()

    const navigateToMessagesScreen = useCallback(() => {
        navigation.navigate("Messages")
    }, [navigation])

    const auth = useAuthContext()
    const { propositionsListQuery } = propositions_list_query.use({ teacherId: auth.userId })

    const { name } = useRoute<RouteProp<MainStackParamsList>>()
    const currentRouteName = useNavigationState((s) => s.routes[s.routes.length - 1]?.name)
    const showFAB = currentRouteName === "MainTabs"

    const _vStackProps = tabBarPosition === "top" ? { flexGrow: 1, borderBottomColor: appBarLineColor, borderBottomWidth: 3 } : {}
    const tabConfig = mainTabsConfig[currentScreen.name]

    const badgeCount = useNotificationsCount()

    return (
        <Box height="full" width="full">
            <HStack justifyContent="flex-end">
                <AppBar noBackButton _vStackProps={{ flexGrow: 1, ..._vStackProps }} /* {...tabConfig.getAppBarProps?.({ appBarLineColor, tabBarPosition })} */>
                    <PresenceTransition visible={!isChanging} initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: 250 } }}>
                        <Heading size="md">{tabConfig?.getTitle()}</Heading>
                    </PresenceTransition>

                    <Spacer />
                    {tabBarPosition === "top" && (
                        <Box paddingRight="20px">
                            <Button
                                colorScheme="pedagome"
                                onPress={navigateToMessagesScreen}
                                startIcon={
                                    <Icon name={badgeCount ? "bell-alert" : "bell-check"} as={MaterialCommunityIcons}>
                                        <Badge position="absolute" rounded="full" colorScheme="pedagome" top={-20} right={-20}>
                                            {badgeCount}
                                        </Badge>
                                    </Icon>
                                }
                            >
                                Notifications
                            </Button>
                        </Box>
                    )}
                </AppBar>

                {tabBarPosition === "top" && <FakeTabs />}
            </HStack>
            {props.children}

            {tabBarPosition === "bottom" && showFAB && (
                <Fab
                    onPress={navigateToMessagesScreen}
                    placement="bottom-right"
                    colorScheme="pedagome"
                    size="lg"
                    bottom={tabBarPosition === "bottom" ? "72px" : null}
                    icon={
                        <Icon name={badgeCount ? "bell-alert" : "bell-check"} as={MaterialCommunityIcons}>
                            <Badge position="absolute" rounded="full" colorScheme="pedagome" top={-20} right={-20}>
                                {badgeCount}
                            </Badge>
                        </Icon>
                    }
                    // label={<Badge position="absolute" rounded="full" colorScheme="pedagome" top={-25}>{3}</Badge>}
                    // width={"52px"}
                />
            )}

            {tabBarPosition === "bottom" && (
                <HStack height={70} safeAreaBottom justifyContent="flex-end">
                    <FakeTabs />
                </HStack>
            )}
        </Box>
    )
}
