import AsyncStorage from "@react-native-async-storage/async-storage"
import { type Dispatch, type SetStateAction, useCallback, useMemo, useSyncExternalStore } from "react"

const stores = new Map<string, AsyncStorageKeyStore>()

class AsyncStorageKeyStore {
    constructor(public key: string) {}

    private hydrated?: boolean
    private initialSet?: boolean

    private value: string | null = null
    private subscribers = new Set<() => void>()

    public subscribe(notify: () => void) {
        this.subscribers.add(notify)

        return () => {
            this.unsubscribe(notify)
        }
    }
    public unsubscribe(notify: () => void) {
        this.subscribers.delete(notify)
    }

    /** init value from storage */
    public async hydrateIfNot(makeInitialValue?: () => string) {
        if (this.hydrated) return
        try {
            //use initial value in the interval between init and hydration
            let initialValue
            if (!this.initialSet && typeof makeInitialValue !== "undefined") {
                initialValue = makeInitialValue()
                this.value = initialValue
                this.initialSet = true
            }

            this.hydrated = true
            const fromStorageVal = await AsyncStorage.getItem(this.key)

            if (typeof fromStorageVal !== "undefined") {
                this.value = fromStorageVal
            }

            this.emitChange()
        } catch (err) {
            console.log(err)
        }
    }

    // /** init value from code value */
    // public async setInitial(makeInitialValue?: () => string) {
    //     if (this.initialSet || typeof makeInitialValue === "undefined") return
    //     const initialValue = makeInitialValue()

    //     this.initialSet = true
    //     this.set(initialValue)
    // }

    public set(value: string) {
        AsyncStorage.setItem(this.key, value)

        if (this.value !== value) {
            this.value = value
            this.emitChange()
        }
    }

    public remove() {
        AsyncStorage.removeItem(this.key)

        if (this.value !== null) {
            this.value = null
            this.emitChange()
        }
    }

    public getSnapshot() {
        return this.value
    }

    private emitChange() {
        this.subscribers.forEach((notify) => notify())
    }
}

//1. hydrater
//2. notifier tous les suscribers
//3. notifier les suscribers a chaque fois que les données changent

/**
 * Share a value between components and tabs
 * Components will be subscribed to changes thanks to useSyncExternalStore
 */
const useLocalStorageSync = <T>(key: string, initialValue?: T, options?: parserOptions<T>): [T | undefined, Dispatch<SetStateAction<T | undefined>>, () => void] => {
    if (!key) {
        throw new Error("useLocalStorageSync key may not be falsy")
    }

    if (!stores.has(key)) {
        stores.set(key, new AsyncStorageKeyStore(key))
    }
    const store = stores.get(key)!

    const subscribe = useCallback((notify: () => void) => store.subscribe(notify), [store])
    const getSnapshot = useCallback(() => store.getSnapshot(), [store])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const deserializer = options ? (options.raw ? (value: any) => value : options.deserializer) : JSON.parse

    const serializer = (plain: any) => {
        if (typeof plain === "undefined") return "null"
        let raw: string

        if (options)
            if (options.raw)
                if (typeof plain === "string") raw = plain
                else raw = JSON.stringify(plain)
            else if (options.serializer) raw = options.serializer(plain)
            else raw = JSON.stringify(plain)
        else raw = JSON.stringify(plain)

        return raw
    }

    const rawValue = useSyncExternalStore(subscribe, getSnapshot)
    store.hydrateIfNot(() => serializer(initialValue))

    const state = useMemo(() => {
        return deserializer(rawValue ?? "null")
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rawValue])

    const set: Dispatch<SetStateAction<T | undefined>> = (valOrFunc) => {
        try {
            const plain = typeof valOrFunc === "function" ? (valOrFunc as Function)(state) : valOrFunc
            const raw = serializer(plain)
            store.set(raw ?? "null")
        } catch {
            // If user is in private mode or has storage restriction
            // localStorage can throw. Also JSON.stringify can throw.
        }
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const remove = useCallback(() => {
        try {
            store.remove()
        } catch {
            // If user is in private mode or has storage restriction
            // localStorage can throw.
        }
    }, [store])

    return [state, set, remove]
}
export default useLocalStorageSync
export { useLocalStorageSync }

type parserOptions<T> =
    | {
          raw: true
      }
    | {
          raw: false
          serializer: (value: T) => string
          deserializer: (value: string) => T
      }
