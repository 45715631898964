import { useAuthContext } from "@core/context/auth"
import { type DialogLogic, useCatchToBoundary, useDialog, useSpinner } from "@core/hooks"
import { current_proposition_lessons_query, current_real_lessons_query, match_history_query, match_infos_list_query, user_info_query } from "@core/queries"
import { openapi } from "@core/services/openapi"
import { EuropeParis } from "@core/utils"
import { FontAwesome5 } from "@expo/vector-icons"
import { find, max, min } from "lodash"
import { DateTime } from "luxon"
import { Button, HStack, Icon, Modal, Spacer, Text } from "native-base"
import React, { useCallback } from "react"

export function SubmittingActions({ lessonId, matchId }: { matchId: number; lessonId: number }) {
    const auth = useAuthContext()
    const { currentRealLessonsQuery } = current_real_lessons_query.use({ teacherId: auth.userId }, { staleTime: 5000 })

    const lesson = currentRealLessonsQuery.helpers.findLessonById(lessonId)
    const { userInfoQuery } = user_info_query.use({ teacherId: auth.userId })

    const unsubmitDialog = useDialog()
    // const msgBox = useMsgBox()
    const askDeleteLesson = useCallback(
        async (lessonId: number) => {
            unsubmitDialog.open()
        },
        [unsubmitDialog]
    )

    const newProcessAfterDateStr = min([
        DateTime.fromJSDate(userInfoQuery.data?.newProcessEnabledAt ?? new Date(0))
            .plus({ days: 7 })
            .toISODate(),
        "2023-01-01",
    ])!
    const newProcessAfterDateDate = DateTime.fromISO(newProcessAfterDateStr).setZone(EuropeParis).plus({ hours: 12 }).toJSDate()

    const remainingTimeStr =
        lesson?.submittedAt &&
        DateTime.fromJSDate(max([lesson.submittedAt, newProcessAfterDateDate])!)
            .plus({ hours: 12 })
            .diffNow()
            .toFormat("h'h et' mm'min'")
            .replace(/^0h et /, "")

    return (
        <HStack alignItems="center" space={2}>
            {unsubmitDialog.isOpen && <UnsubmitModal logic={unsubmitDialog} lessonId={lessonId} matchId={matchId} />}

            <Button onPress={() => askDeleteLesson(lessonId)} variant="solid" size="sm" colorScheme="gray" startIcon={<Icon as={FontAwesome5} name="eraser" />}>
                Annuler saisie
            </Button>
            <Spacer />
            <Text pt={1} style={{ fontStyle: "italic" }}>
                Verrouillage dans {remainingTimeStr}
            </Text>
        </HStack>
    )
}
export function UnsubmitModal({ logic, lessonId, matchId }: { logic: DialogLogic; matchId: number; lessonId: number }) {
    const auth = useAuthContext()
    const { currentRealLessonsQuery } = current_real_lessons_query.use({ teacherId: auth.userId }, { staleTime: 5000 })
    const { currentPropositionLessonsQuery } = current_proposition_lessons_query.use({ teacherId: auth.userId }, { staleTime: 5000 })

    const lesson = currentRealLessonsQuery.helpers?.findLessonById?.(lessonId)

    const isFastLesson = !(lesson?.proposedAt || lesson?.bookedAt)
    const spinner = useSpinner()

    const handleUnsubmit = useCatchToBoundary(async () => {
        spinner.start()

        try {
            await auth.ensureValidToken()
            await openapi.teachersApp.unsubmitLesson(lessonId)

            //refetch base query
            await Promise.allSettled([currentPropositionLessonsQuery.refetch(), currentRealLessonsQuery.refetch()])

            //invalidate other impacted queries
            currentRealLessonsQuery.queryClient.invalidateQueries(match_history_query.getKey({ matchId }))
            currentRealLessonsQuery.queryClient.invalidateQueries(match_infos_list_query.getKey({ teacherId: auth.userId }))

            logic.hide()
        } finally {
            spinner.stop()
        }
    })

    return (
        <Modal isOpen>
            <Modal.Content>
                {/*   <Modal.CloseButton /> */}
                <Modal.Header>{isFastLesson ? "Suppression cours" : "Annulation soumission"}</Modal.Header>
                <Modal.Body>{`Le cours du ${DateTime.fromJSDate(lesson?.startedAtOfficial!).toFormat("cccc dd MMMM")} ${isFastLesson ? "sera supprimé du planning." : "retournera a l'état 'Cours prévu'"}`}</Modal.Body>
                <Modal.Footer>
                    <Button.Group space={2}>
                        <Button onPress={() => logic.hide()} variant="subtle2" colorScheme="trueGray">
                            Retour
                        </Button>
                        <Button onPress={handleUnsubmit} isLoading={spinner.loading} variant="solid" colorScheme="brown">
                            {isFastLesson ? "Supprimer" : "Annuler soumission"}
                        </Button>
                    </Button.Group>
                </Modal.Footer>
            </Modal.Content>
        </Modal>
    )
}
