import React, { useCallback, useEffect, useMemo, useState } from "react"

export function useCollapse(initialExpanded = false) {
    const [expanded, setState] = useState(initialExpanded)

    useEffect(() => {
        if (expanded !== initialExpanded) setState(initialExpanded)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialExpanded])

    const expand = useCallback(() => {
        setState(true)
    }, [])

    const collapse = useCallback(() => {
        setState(false)
    }, [])

    const toggle = useCallback(() => {
        setState((st) => !st)
    }, [])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useMemo(() => ({ expanded, collapsed: !expanded, expand, collapse, toggle, set: setState }), [expanded])
}
