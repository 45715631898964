export class RasmikError extends Error {
    constructor(error) {
        super(error.message);
        Object.assign(this, error);
    }
}
const services = {
    async getTeacherWithConciseLevels() { }
};
export class RasmikClient {
    config;
    constructor(config) {
        this.config = config;
    }
    services = services;
    async request(url, payload) {
        try {
            const res = await this.config.axios.post(url, payload, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const data = res.data;
            return data;
        }
        catch (err) {
            const serverError = err.response?.data;
            if (serverError?.error === true) {
                const rethrown = new RasmikError({
                    url,
                    ...serverError,
                    ...payload
                });
                this.config.onError(rethrown);
            }
            else {
                this.config.onError(err);
            }
        }
    }
    pushOne = ((entity) => ({
        pushDef: (pushDef) => ({
            data: (data) => ({
                run: async () => await this.request(this.config.baseUrl + '/crud/' + entity.__path + '/pushOne', { data, pushDef }),
                readOptions: (readOptions) => ({
                    run: async () => await this.request(this.config.baseUrl + '/crud/' + entity.__path + '/pushOne', { data, readOptions, pushDef })
                })
            }),
        })
    }));
    pushMany = ((entity) => ({
        pushDef: (pushDef) => ({
            data: (data) => ({
                run: async () => await this.request(this.config.baseUrl + '/crud/' + entity.__path + '/pushMany', { data, pushDef }),
                readOptions: (readOptions) => ({
                    run: async () => await this.request(this.config.baseUrl + '/crud/' + entity.__path + '/pushMany', { data, readOptions, pushDef })
                })
            }),
        })
    }));
    readOne = ((entity) => ({
        where: (where) => ({
            run: async () => await this.request(this.config.baseUrl + '/crud/' + entity.__path + '/readOne', { where }),
            options: (options) => ({
                run: async () => await this.request(this.config.baseUrl + '/crud/' + entity.__path + '/readOne', { where, options })
            })
        }),
        options: (options) => ({
            where: (where) => ({
                run: async () => await this.request(this.config.baseUrl + '/crud/' + entity.__path + '/readOne', { where, options })
            })
        })
    }));
    readMany = ((entity) => ({
        run: async () => await this.request(this.config.baseUrl + '/crud/' + entity.__path + '/readMany', {}),
        where: (where) => ({
            run: async () => await this.request(this.config.baseUrl + '/crud/' + entity.__path + '/readMany', { where }),
            options: (options) => ({
                run: async () => await this.request(this.config.baseUrl + '/crud/' + entity.__path + '/readMany', { where, options })
            })
        }),
        options: (options) => ({
            run: async () => await this.request(this.config.baseUrl + '/crud/' + entity.__path + '/readMany', { options }),
            where: (where) => ({
                run: async () => await this.request(this.config.baseUrl + '/crud/' + entity.__path + '/readMany', { where, options })
            })
        })
    }));
    deleteOne = ((entity) => ({
        where: (where) => ({
            run: async () => await this.request(this.config.baseUrl + '/crud/' + entity.__path + '/deleteOne', { where }),
            options: (options) => ({
                run: async () => await this.request(this.config.baseUrl + '/crud/' + entity.__path + '/deleteOne', { where, options })
            })
        }),
        options: (options) => ({
            where: (where) => ({
                run: async () => await this.request(this.config.baseUrl + '/crud/' + entity.__path + '/deleteOne', { where, options })
            })
        })
    }));
    deleteMany = ((entity) => ({
        where: (where) => ({
            run: async () => await this.request(this.config.baseUrl + '/crud/' + entity.__path + '/deleteMany', { where }),
            options: (options) => ({
                run: async () => await this.request(this.config.baseUrl + '/crud/' + entity.__path + '/deleteMany', { where, options })
            })
        }),
        options: (options) => ({
            run: async () => await this.request(this.config.baseUrl + '/crud/' + entity.__path + '/deleteMany', { options }),
            where: (where) => ({
                run: async () => await this.request(this.config.baseUrl + '/crud/' + entity.__path + '/deleteMany', { where, options })
            })
        })
    }));
    define = ({
        pushDef: (entity) => ({
            val: (def) => def
        }),
        pushData: (entity) => ({
            def: () => ({
                val: (data) => data,
                shape: () => undefined
            })
        }),
        readOptions: (entity) => ({
            val: (def) => def
        }),
        readData: (entity) => ({
            def: () => ({
                val: (data) => data,
                shape: () => undefined
            })
        }),
        draftDef: (entity) => ({
            val: (def) => def
        }),
        draftData: (entity) => ({
            def: () => ({
                val: (data) => data,
                shape: () => undefined
            })
        }),
        pickDef: (entity) => ({
            val: (def) => def
        }),
        pickData: (entity) => ({
            def: () => ({
                val: (data) => data,
                shape: () => undefined
            })
        }),
    });
}
