import type { LinkingOptions } from "@react-navigation/native"
import * as Linking from "expo-linking"

import type { GlobStackParamsList } from "./Navigator"

export const linking: LinkingOptions<any> = {
    prefixes: [Linking.createURL("/"), "https://tuteurs.pedagome.fr"],
    config: {
        screens: {
            AuthStack: {
                // path: '',
                // exact: true,
                screens: {
                    Login: { exact: true, path: "login" },
                    ForgetPassword: { exact: true, path: "oubli-mdp" },
                },
            },
            MainStack: {
                screens: {
                    Messages: { exact: true, path: "notifications" },
                    EditSurvey: { exact: true, path: "edition-bilan" },
                    Payslips: { exact: true, path: "bulletins-de-paie" },
                    Tuto: { exact: true, path: "tuto" },
                    Tuto_Auto: { exact: true, path: "tuto-auto" },

                    OfferDetail: { exact: true, path: "offres/:matchId", parse: { matchId: (matchId$: string) => parseInt(matchId$, 10) } },

                    AddLessonSelectMatch: { exact: true, path: "choix-match" },
                    NewProcess: { exact: true, path: "nouveau-mode-de-saisie" },
                    MainTabs: {
                        // path: '',
                        // exact: true,
                        screens: {
                            SaisieHeures: { exact: true, path: "heures" },
                            Offres: { exact: true, path: "offres" },
                            SurveysList: { exact: true, path: "bilans" },
                            MatchList: { exact: true, path: "matchs" },
                            User: { exact: true, path: "profil" },
                        },
                    },
                    EditInfoForm: { exact: true, path: "mes-infos" },
                    SplashNotifications: { exact: true, path: "splash" },
                    EditScolarityForm: { exact: true, path: "mes-études" },
                    EditYearDispoForm: { exact: true, path: "mes-dispos-annuelles" },
                    EditWeekDispoForm: { exact: true, path: "mes-dispos-hebdo" },
                    TimePickerScreen: { exact: true, path: "sélection-plage" },
                },
            },
            LoginReset: { exact: true, path: "login-reset" },
        },
    },
}
