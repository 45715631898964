import { ScrollViewGrow } from "@core/atoms"
import { WarningAlert } from "@core/atoms/WarningAlert"
import { ContentContainerColumn, ContentContainerRow } from "@core/misc-components/ContentContainer"
import { AppBar } from "@core/molecules/AppBar"
import type { WeekDispo } from "@core/services/rasmik-client"
import { FontAwesome5 } from "@expo/vector-icons"
import { type NavigationProp, useNavigation } from "@react-navigation/native"
import type { StackScreenProps } from "@react-navigation/stack"
import { Button, Center, Heading, HStack, Icon, Switch, Text, VStack } from "native-base"
import React from "react"

import type { MainStackParamsList } from "../MainStack"
import { InfoFooter, ModifiedDataWarning, NotEnoughDispoWarning } from "./WeekDispoScreenComponentAndUtils/Alerts"
import { OnCloseDialog } from "./WeekDispoScreenComponentAndUtils/OnCloseDialog"
import { type EditWeekDispoFormLogic, useEditWeekDispoForm } from "./WeekDispoScreenComponentAndUtils/useEditWeekDispoForm"
import { WeekDispoTag } from "./WeekDispoScreenComponentAndUtils/WeekDispoTag"

export type DispoByDay = {
    Lundi: DraftWeekDispo[]
    Mardi: DraftWeekDispo[]
    Mercredi: DraftWeekDispo[]
    Jeudi: DraftWeekDispo[]
    Vendredi: DraftWeekDispo[]
    Samedi: DraftWeekDispo[]
    Dimanche: DraftWeekDispo[]
}

export type DraftWeekDispo = Required<Pick<WeekDispo, "startTime" | "endTime" | "dayId">> & Partial<Pick<WeekDispo, "id">>
export type DraftWeekDispos = DraftWeekDispo[]

export type Day = "Lundi" | "Mardi" | "Mercredi" | "Jeudi" | "Vendredi" | "Samedi" | "Dimanche"
const days: Day[] = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"]

export function EditWeekDispoFormScreen({ navigation }: StackScreenProps<MainStackParamsList, "EditWeekDispoForm">) {
    return <WeekDispoDisplay displayAppBar />
}

function WeekDispoDisplay(props: { displayAppBar: boolean }) {
    const navigation = useNavigation<NavigationProp<MainStackParamsList, "EditWeekDispoForm">>()
    const logic = useEditWeekDispoForm({ navigation, displayAppBar: props.displayAppBar })

    return (
        <>
            <VStack space={2} h="100%">
                {props.displayAppBar && (
                    <AppBar onBack={() => navigation.navigate("MainTabs", { screen: "User" })}>
                        <ContentContainerRow flex={1}>
                            <Heading size="md">Mes disponibilités hebdo</Heading>
                        </ContentContainerRow>
                    </AppBar>
                )}

                <ContentContainerColumn>
                    {logic.isInit && <NotEnoughDispoWarning nbDispo={logic.draftDispos.length} />}
                    {logic.isInit && logic.isDraft && <ModifiedDataWarning />}
                    {logic.warning && <WarningAlert text={logic.warning} />}
                </ContentContainerColumn>

                <ScrollViewGrow>
                    <ContentContainerColumn mb="4">
                        <WeekDispoTable logic={logic} />
                    </ContentContainerColumn>
                </ScrollViewGrow>

                <Center backgroundColor={logic.barBg} shadow="1" h="60px">
                    <Button
                        isLoading={logic.isSubmitting}
                        // isDisabled={logic.draftDispos.length < 3 || !logic.isDraft}
                        colorScheme="pedagome"
                        onPress={() => logic.defaultHandleSubmit()}
                        leftIcon={<Icon as={FontAwesome5} name="save" size="xs" />}
                    >
                        Enregistrer
                    </Button>
                </Center>
            </VStack>
            <OnCloseDialog isOpen={logic.closeWarning} onCancel={logic.onCloseCancel} onProceed={logic.onCloseProceed} />
        </>
    )
}

export function WeekDispoTable(props: { logic: EditWeekDispoFormLogic }) {
    const { logic } = props
    return (
        <>
            <HStack my="4">
                <Switch isChecked={props.logic.hasUnstableWeekDispos} onToggle={props.logic.handleHasUnstableWeekDisposToggle} marginRight="10px" />
                <Text>Mon emploi du temps change régulièrement</Text>
            </HStack>
            <VStack space={2}>
                {days.map((day) => (
                    <React.Fragment key={day}>
                        <Text>{day}</Text>
                        <HStack justifyContent="space-between">
                            <WeekDispoTag dispoByDay={logic.dispoByDay} handleDelete={logic.handleDelete} key={0} handleOnPress={logic.handleFirstPress} day={day} data={logic.dispoByDay[day][0]} />
                            <WeekDispoTag dispoByDay={logic.dispoByDay} handleDelete={logic.handleDelete} key={1} handleOnPress={logic.handleFirstPress} day={day} data={logic.dispoByDay[day][1]} />
                            <WeekDispoTag dispoByDay={logic.dispoByDay} handleDelete={logic.handleDelete} key={2} handleOnPress={logic.handleFirstPress} day={day} data={logic.dispoByDay[day][2]} />
                        </HStack>
                    </React.Fragment>
                ))}
                <InfoFooter />
            </VStack>
        </>
    )
}
