import { MonthlyMatchSurvey, rasmik } from "@core/services/rasmik"
import type { FilterQuery } from "@core/services/rasmik-client"
import { groupBy, min } from "lodash"
import { DateTime } from "luxon"

import { arrayQueryHelpers, defineQuery } from "./utils"

export const monthly_match_surveys_query = defineQuery("monthlyMatchSurveysQuery", ({ teacherId, status }: { teacherId: number | null | undefined; status: "DRAFT" | "SUBMITTED" | "all" }) => ({
    key: ["monthly_match_surveys", { teacherId, status }],
    queryFn: async (qryCtx, auth) => {
        console.debug("monthlyMatchSurveysQuery")

        const currentMonthStr = DateTime.now().startOf("month").toISODate()

        //tous ceux du mois en cours et les précédents en fonction du filtre status

        const whereClause: FilterQuery<MonthlyMatchSurvey> = {
            Match: { Teacher: teacherId },
            $or: [{ month: { $lte: currentMonthStr } }, { month: currentMonthStr }],
        }
        if (status !== "all") {
            //@ts-ignore
            whereClause.$or![0].status = status
        }
        await auth!.ensureValidToken()
        const mmss = await rasmik
            .readMany(MonthlyMatchSurvey)
            .where(whereClause)
            .options({ include: ["*", "lessonsDurationSum"], orderBy: { month: "asc", Match: "asc" } })
            .run()

        //on affiche un seul brouillon par match a la fois.
        const mmss2: typeof mmss = []
        const groups = groupBy(mmss, (mms) => mms.Match)
        for (const matchId$ in groups) {
            const matchMMSS = groups[matchId$]
            const firstDraftMonth = min(matchMMSS.filter((mms) => mms.status === "DRAFT").map((mms) => mms.month))
            const filteredMatchMMS = matchMMSS.filter((mms) => mms.status !== "DRAFT" || (mms.status === "DRAFT" && mms.month === firstDraftMonth))
            mmss2.push(...filteredMatchMMS)
        }

        return mmss2
    },
    defaultOptions: { enabled: !!teacherId, useErrorBoundary: true, initialData: [] },
})).withHelpers((query) => ({
    ...arrayQueryHelpers(query),
    getPendingSurveysCount() {
        const maxUnlockedSurveyMonth = DateTime.now()
            .minus({ days: 25 - 1 })
            .startOf("month")
            .toISODate()!
        const pendingSurveysCount = query.data?.filter((mms) => mms.status === "DRAFT" && mms.month <= maxUnlockedSurveyMonth).length as number
        return pendingSurveysCount
    },
    isMonthLocked(month: string) {
        const maxUnlockedSurveyMonth = DateTime.now()
            .minus({ days: 25 - 1 })
            .startOf("month")
            .toISODate()!
        return month > maxUnlockedSurveyMonth
    },
}))

export type MonthlyMatchSureysQueryData = (typeof monthly_match_surveys_query)["dataShape"]
