import { type Dispatch, type MutableRefObject, type SetStateAction, useCallback, useRef, useState } from "react"

/**
 * React state hook that returns the latest state as described in the React hooks FAQ.
 * This is mostly useful to get access to the latest value of some props or state inside an asynchronous callback, instead of that value at the time the callback was created from.
 *
 * Same as useLatest from react-use package but ref is set when calling setState
 */
function useStateRef<S>(initialState: S | (() => S)): [S, Dispatch<SetStateAction<S>>, MutableRefObject<S>]
function useStateRef<S = undefined>(): [S | undefined, Dispatch<SetStateAction<S | undefined>>, MutableRefObject<S>]
function useStateRef(v?: any) {
    const initFn = typeof v === "function" ? v : () => v

    const [state, setState] = useState(initFn)
    const stateRef = useRef(state)

    const setState2 = useCallback<Dispatch<SetStateAction<any>>>(
        (arg) => {
            setState((prevState: any) => {
                const nextState = typeof arg === "function" ? arg(prevState) : arg
                stateRef.current = nextState
                return nextState
            })
        },
        [setState, stateRef]
    )

    return [state, setState2, stateRef]
}

export { useStateRef }
