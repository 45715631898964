import { DateTime } from "luxon"

export function toFrenchRelativeCalendar(dt: DateTime) {
    const other = dt.set({ millisecond: 0, second: 0, minute: 0, hour: 0 })
    const today = DateTime.now().set({ millisecond: 0, second: 0, minute: 0, hour: 0 })
    const daysDiff = other.diff(today, "days").days
    const weeksDiff = other.diff(today, "weeks").weeks
    const monthsDiff = other.diff(today, "months").months

    if (monthsDiff <= -1) {
        return `il y a ${Math.floor(-monthsDiff)} mois`
    } else if (daysDiff <= -14) {
        return `il y a ${Math.floor(-daysDiff / 7)} semaines`
    } else if (daysDiff <= -3) {
        return `il y a ${-daysDiff} jours`
    } else if (daysDiff === -2) {
        return `avant-hier`
    } else if (daysDiff === -1) {
        return `hier`
    } else if (daysDiff === 0) {
        return `aujourd'hui`
    } else if (daysDiff === 1) {
        return `demain`
    } else if (daysDiff === 2) {
        return `après-demain`
    } else if (daysDiff >= 3) {
        return `dans ${daysDiff} jours`
    } else if (daysDiff >= 14 && monthsDiff < 1) {
        return `dans ${Math.floor(daysDiff / 7)} semaines`
    } else if (monthsDiff >= 1) {
        return `dans ${Math.floor(monthsDiff)} mois`
    }
}
