import { isNil } from "lodash"

export function sleep(milliseconds: number) {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(true)
        }, milliseconds)
    })
}

export function notNil<T>(v: T | null | undefined): v is T {
    return !isNil(v)
}

export function truthy<T>(v: T | null | undefined | 0 | ""): v is T {
    return !!v
}
