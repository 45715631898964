import { rasmik, TeacherScolarityMain } from "@core/services/rasmik"

import { defineQuery } from "./utils"

export const scolarity_main_query = defineQuery("scolarityMainQuery", () => ({
    key: ["scolarity_main_query"],
    queryFn: async (qryCtx, auth) => {
        await auth!.ensureValidToken()

        return await rasmik
            .readMany(TeacherScolarityMain)
            .options({
                children: {
                    Complements: true,
                },
            })
            .run()
    },
}))

export type ScolarityQueryData = (typeof scolarity_main_query)["dataShape"]
