import { openapi } from "@core/services/openapi"
import { getTokenPayoad } from "@core/utils/jwt"
import axios from "axios"
import { Action, Computed, Model, Property, Thunk } from "easy-peasy-classes"

import { EzModel } from "./base"
import { LoadableModel } from "./loadable"

@Model
export class persistedModel extends EzModel {
    @Property
    finishedTuto?: boolean = false

    @Action
    setFinishedTuto(hasFinished: boolean) {
        this.finishedTuto = hasFinished
    }
}
