import { type DialogAwaitIO, useDialogAwait, useLocalStorageNative } from "@core/hooks"
import jwtDecode, { InvalidTokenError } from "jwt-decode"
import React, { type ReactNode, useEffect, useMemo, useRef } from "react"

import { AuthContext, type LocalStorageAuthData, type TeacherTokenPayload } from "./auth-context"
// import { ReloginDialog } from './ReloginDialog'

const SECURITY_DELAY_SEC = 60 //570//60

const tokenExists = (val: LocalStorageAuthData | undefined) => {
    return Boolean(val)
}
const tokenExpiresInSec = (val: LocalStorageAuthData | undefined) => {
    return val?.tokenPayload?.exp && Math.round(val?.tokenPayload?.exp - Date.now() / 1000 - SECURITY_DELAY_SEC)
}
const hasTokenExpired = (val: LocalStorageAuthData | undefined) => {
    return Boolean((tokenExpiresInSec(val) ?? 0) <= 0)
}
const isTokenValid = (val: LocalStorageAuthData | undefined) => {
    return tokenExists(val) && !hasTokenExpired(val)
}

export function AuthContextProvider({ children }: { children: ReactNode }) {
    const initialValue = undefined
    const [value, setValue, remove] = useLocalStorageNative<LocalStorageAuthData | undefined>("auth", initialValue)
    const valueRef = useRef(value)
    const autofillRef = useRef<{ email: string; password: string } | null>(null)
    valueRef.current = value

    const loginDialog = useDialogAwait<DialogAwaitIO<{ outcome: void; payload: void }>>()

    //check for expired token every 10s
    useEffect(() => {
        const timer = setInterval(() => {
            //if there's no token, login page is displayed by react router (PrivateRoute)
            if (!tokenExists(value) || loginDialog.isOpen) return

            //If there's a token but it is not valid, login page is displayed by LoginPageDialog
            if (hasTokenExpired(value)) {
                loginDialog.open()
            }
            // console.debug(`token expires in ${tokenExpiresInSec(value)}s`)
        }, 10 * 1000)

        return () => {
            clearInterval(timer)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, loginDialog.isOpen])

    const contextValue = useMemo(
        () => ({
            autofillRef,
            ...value,
            isLoggedIn: !!value?.token,
            get isTokenExpired() {
                return hasTokenExpired(value)
            },
            isLoginDialogOpen: loginDialog.isOpen,
            hideLoginDialog: loginDialog.hide as () => void,
            /** decode token and store in localstorage + state */
            setToken(token: string) {
                if (!token) throw new Error("Can't store empty token")

                try {
                    const decodedToken = jwtDecode<TeacherTokenPayload>(token)

                    const newValue = {
                        token,
                        tokenPayload: decodedToken,
                        userId: decodedToken.user.id,
                        // username: decodedToken.user.username,
                    }
                    setValue(newValue)
                    // console.log("setting token")
                    valueRef.current = newValue
                } catch (err) {
                    if (err instanceof InvalidTokenError) {
                        console.debug("Invalid jwt token")
                        throw err
                    } else {
                        throw err
                    }
                }
            },
            /** remove token from state and localStorage */
            eraseToken() {
                remove()
            },
            async ensureValidToken() {
                const hasValidToken = isTokenValid(valueRef.current)
                console.debug("ensureValidToken: " + (hasValidToken ? "valid" : "invalid"))
                if (hasValidToken) {
                } else {
                    // console.debug('opening: ' + loginDialog)
                    return loginDialog.open(undefined) as any as Promise<void>
                }
            },
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [value, loginDialog.isOpen]
    )

    // console.debug("AuthContextProvider -> auth is " + Boolean(contextValue) + " at " + Math.round((new Date().valueOf() - new Date("2022-11-29T15:27:00").valueOf()) / 100))
    return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
}
