import { Action, Model, ModelBaseTyped, Thunk } from "easy-peasy-classes"
import { get } from "lodash"

import type { topModel } from "./top"

//This works because the only cyclic imported value is 'initialState' and it is used in a function 'resetState' that is never called before the loop is complete.

/* do not reference in store/index ! */

@Model
export class EzModel extends ModelBaseTyped<topModel> {
    @Action
    setStateFn(stateModifierFn: (modelState: this) => void) {
        stateModifierFn(this)
    }

    //Must be a thunk : can't access resetState.type in an action's proxy
    @Thunk
    resetState() {
        const resetStateFnObject: any = this.resetState
        const thunkType = resetStateFnObject.type
        const path$ = thunkType.split(".").slice(1, -1).join(".") // .replace('@thunk.','').replace('.resetState','')
        const initaialLocalState = get((this.getStoreState() as any).initialState, path$)
        this.resetStateAction(initaialLocalState)
    }

    @Action
    private resetStateAction(initaialLocalState: any) {
        Object.assign(this, initaialLocalState)
    }
}
