import { useAuthContext } from "@core/context/auth"
import { useCatchToBoundary, useSpinner } from "@core/hooks"
import { ContentContainerColumn } from "@core/misc-components/ContentContainer"
import { LoadingScreen } from "@core/organism/LoadingScreen"
import { match_offers_query, user_info_query } from "@core/queries"
import type { StackScreenProps } from "@react-navigation/stack"
import { Box, Center, Text, useTheme, VStack } from "native-base"
import React, { useRef } from "react"
import { Animated, RefreshControl, ScrollView } from "react-native"

import type { MainTabsParamList } from "../MainStack"
import { OfferCard } from "./OfferCard"
/**
 * Liste des offres de matchs 'broadcast'
 */
export function OffersScreen({ navigation }: StackScreenProps<MainTabsParamList, "Offres">) {
    const auth = useAuthContext()
    const { userInfoQuery } = user_info_query.use({ teacherId: auth.userId })
    const { matchOffersQuery } = match_offers_query.use({ teacher: userInfoQuery.data })

    const scrollRefreshSpinner = useSpinner()
    const onRefresh = useCatchToBoundary(async () => {
        try {
            await auth.ensureValidToken()
            scrollRefreshSpinner.start()
            await matchOffersQuery.refetch()
        } finally {
            scrollRefreshSpinner.stop()
        }
    }, [auth, scrollRefreshSpinner, matchOffersQuery])

    const theme = useTheme()

    if (!matchOffersQuery.data) return <LoadingScreen />
    const offers = matchOffersQuery.data

    const showNoOffersMessage = !offers.length
    return (
        <VStack safeAreaBottom width="100%" h="100%">
            {showNoOffersMessage ? (
                <NoLessonsMessage />
            ) : (
                <ScrollView
                    style={{ width: "100%", height: 0 }}
                    contentContainerStyle={{ flexGrow: 1, width: "100%", alignItems: "stretch" }}
                    refreshControl={<RefreshControl refreshing={scrollRefreshSpinner.loading} onRefresh={onRefresh} />}
                    scrollEventThrottle={16}
                >
                    <ContentContainerColumn space="15px" paddingY="15px">
                        {offers.map((offer) => (
                            <OfferCard key={offer.id} offer={offer} />
                        ))}
                    </ContentContainerColumn>
                </ScrollView>
            )}
        </VStack>
    )
}

function NoLessonsMessage() {
    return (
        <ContentContainerColumn paddingY="15px" alignItems="center" flex={1}>
            <Center flexGrow={1}>
                <Box p="20px" borderRadius="10px" _light={{ bgColor: "light.200" }} _dark={{ bgColor: "dark.200" }}>
                    <Text textAlign="center" _light={{ color: "light.700" }} _dark={{ color: "dark.700" }}>
                        Aucune offre n'est disponible pour le moment
                    </Text>
                </Box>
            </Center>
        </ContentContainerColumn>
    )
}
