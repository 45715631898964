import { TimePickerControl, type TimePickerControlProps } from "@core/controls/TimePickerControl"
import { useField, useFormikContext } from "formik"

export type FmkTimePickerProps = Omit<TimePickerControlProps, "value" | "minutes" | "onChange" | "error" | "onBlur"> & {
    name: string
}

export function FmkTimePicker(props: FmkTimePickerProps) {
    const { name, ...otherProps } = props

    const [field, meta, { setValue, setTouched }] = useField<number | null>(name)
    const { isSubmitting } = useFormikContext()

    const handleChange = (newValue: number | null) => {
        setTouched(true, false)
        setValue(newValue, true)
    }

    const defaultProps: Partial<TimePickerControlProps> = {
        onChange: handleChange as any,
    }

    const config = {
        ...field, //props from formik to field (value, onChange, onBlur, name, multiple, checked )
        ...defaultProps,
        ...otherProps,
        minutes: field.value,
    }
    if (meta && meta.touched && meta.error) {
        config.error = meta.error
    }

    config.isDisabled = config.isDisabled ?? isSubmitting

    return <TimePickerControl {...config} />
}
