import type { WeekDispo } from "@core/services/rasmik-client"
import { groupBy, sortBy } from "lodash"
import { Duration } from "luxon"

import type { Day, DispoByDay, DraftWeekDispos } from "../EditWeekDispoForm"

const days: Day[] = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"]

export type DraftWeekDispo = Required<Pick<WeekDispo, "startTime" | "endTime" | "dayId">>

/**
 * Supprime les id négatifs dans les données pour les rendre compatibles avec rasmik
 * @param draftDispos données à traiter
 * @returns les données traitées
 */
export function removeNegativeIds(draftDispos: DraftWeekDispos): DraftWeekDispos {
    const res: DraftWeekDispos = []
    for (const dispo of draftDispos) {
        res.push({
            id: dispo.id! < 0 ? undefined : dispo.id,
            dayId: dispo.dayId,
            startTime: dispo.startTime,
            endTime: dispo.endTime,
        })
    }
    return res
}

/**
 * renvoie le nom du jour selon l'id
 * @param number id du jour de 1 à 7
 */
export const getDayFromNumber = (number: number): Day => {
    return days[number - 1]
}

/**
 * prepare les données dans un format compatible avec le composant
 * @param data données crues
 * @returns les données préparées
 */
export function prepareDispoData(data: DraftWeekDispos | undefined): DispoByDay {
    const dispoByDay: DispoByDay = {
        Lundi: [],
        Mardi: [],
        Mercredi: [],
        Jeudi: [],
        Vendredi: [],
        Samedi: [],
        Dimanche: [],
    }

    if (!data) return dispoByDay

    const dispos = data

    for (const dispo of dispos) {
        dispoByDay[getDayFromNumber(dispo.dayId)].push({ id: dispo.id ?? -1, dayId: dispo.dayId, startTime: dispo.startTime, endTime: dispo.endTime })
    }
    //fillWithEmpty(dispoByDay)
    return dispoByDay
}

export function groupWeekDisposByDay(data: DraftWeekDispos | undefined) {
    if (!data) return []

    const grouped = groupBy(data, (item) => item.dayId)

    return Object.entries(grouped)
        .map(([dayId$, dayDispos]) => ({ dayId: Number(dayId$), dayDispos: sortBy(dayDispos, (d) => d.startTime) }))
        .sort((a, b) => a.dayId - b.dayId)
}

export function formatISOTime(isoTimeString: string | null | undefined) {
    if (!isoTimeString) return ""
    else return Duration.fromISOTime(isoTimeString).toFormat("h'h'mm").replace("h00", "h")
}

export function getIdFromDay(day: Day): number {
    switch (day) {
        case "Lundi":
            return 1
        case "Mardi":
            return 2
        case "Mercredi":
            return 3
        case "Jeudi":
            return 4
        case "Vendredi":
            return 5
        case "Samedi":
            return 6
        case "Dimanche":
            return 7
    }
}

/**
 * checks for overlaps when inputing a new dispo
 * @param input time frame to check
 * @param completeDraft all current dispos
 * @param ignoreId dispo to ingore in checks, used to ignore the one we are about to modified, if -1 is passed, nothing will be ignored
 */
export function validateDispoInput(dayId: number, input: { start: string; end: string }, completeDraft: DraftWeekDispos, ignoreId: number | undefined): boolean {
    for (const dispo of completeDraft) {
        if ((dispo.id === ignoreId && ignoreId !== undefined) || dispo.dayId !== dayId) continue
        if ((input.start >= dispo.startTime && input.start <= dispo.endTime) || (input.end >= dispo.startTime && input.end <= dispo.endTime) || (input.start < dispo.startTime && input.end > dispo.endTime)) return false
    }
    return true
}

/**
 * determine si il y eu des changement dans les dispos
 * @param oldDraft état orignal
 * @param newDraft état actuel
 * @returns true si l'état atuel est différent de l'original
 */
export function isDispoArrayChanged(oldDraft: DraftWeekDispos, newDraft: DraftWeekDispos): boolean {
    // console.log(oldDraft)
    // console.log(newDraft)
    if (oldDraft.length !== newDraft.length) return true

    let found: boolean
    for (const newDispo of newDraft) {
        found = false
        for (const oldDispo of oldDraft) {
            //on teste pas l'id car on test la sémantique et non l'identité
            if (newDispo.dayId === oldDispo.dayId && newDispo.startTime === oldDispo.startTime && newDispo.endTime === oldDispo.endTime) {
                found = true
                break
            }
        }
        if (!found) return true
    }

    return false
}
