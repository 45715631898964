import { Lesson, rasmik } from "@core/services/rasmik"
import { DryLessonStatusEntries } from "@core/services/swaggerClient"
import { getShowedMonthStr } from "@core/utils"
import { DayDate } from "@core/utils/DayDate"
import { find, groupBy, map, sortBy } from "lodash"

import { arrayQueryHelpers, defineQuery } from "./utils"

/**
 * Ceux a venir et ceux aborted/cancelled avec un délai de 3 mois ?
 * Requète différente pour le 1er créneau ? : oui => ils sont récupérés avec la tprop.
 */

//TODO: filtrer pour ne pas tout récupérer.

export const lesson_props_query = defineQuery("lessonPropsQuery", ({ teacherId }: { teacherId: number | null | undefined }) => ({
    key: ["lesson_props", { teacherId }],
    queryFn: async (qryCtx, auth) => {
        await auth!.ensureValidToken()

        const currentMonth = getShowedMonthStr()
        let currentLessons = await rasmik
            .readMany(Lesson)
            .where({
                Match: { Teacher: teacherId },
                monthOfficialFormula: currentMonth,
                status: [DryLessonStatusEntries.DRAFT, DryLessonStatusEntries.BOOKED, DryLessonStatusEntries.ABORTED, DryLessonStatusEntries.CANCELLED],
            })
            .run()
        currentLessons = sortBy(currentLessons, (lsn) => lsn.startedAtOfficial)
        const grouped = map(
            groupBy(currentLessons, (lsn) => lsn.Match),
            (matchLessons, matchId$) => ({ matchId: +matchId$, lessons: matchLessons })
        )

        return grouped
    },
    defaultOptions: { enabled: !!teacherId, useErrorBoundary: true, initialData: [] as any },
})).withHelpers((query) => ({
    findByMatchId(matchId: number) {
        return find(query.data, { matchId })?.lessons || []
    },
    ...arrayQueryHelpers(query),
}))

export type CurrentLessonsQueryData = (typeof lesson_props_query)["dataShape"]
