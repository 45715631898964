import type { GlobStackParamsList } from "@core/config/Navigator"
import { useAuthContext } from "@core/context/auth"
import { type NavigationProp, type RouteProp, useLinkTo, useNavigation, useRoute } from "@react-navigation/native"
import { createStackNavigator, type StackScreenProps } from "@react-navigation/stack"
import React, { useEffect } from "react"

import { AuthStackParamsContext } from "./auth-stack.ctx"
import { ForgetPasswordScreen } from "./ForgetPasswordScreen"
import { LoginScreen } from "./LoginScreen"

export type AuthStackParamList = {
    Login: { login?: string; password?: string; prev?: string }
    ForgetPassword: { email: string }
    // LoginReset: { login?: string; password?: string }
}

const AuthStackNav = createStackNavigator<AuthStackParamList>()
export function AuthStack({ navigation, route }: StackScreenProps<GlobStackParamsList, "AuthStack">) {
    // return <Text>AuthStack</Text>

    const auth = useAuthContext()
    const linkTo = useLinkTo()
    useEffect(() => {
        if (auth.isLoggedIn) {
            if (route.params?.prev) {
                linkTo(route.params?.prev)
            } else {
                navigation.replace("MainStack")
            }
        }
    })

    return (
        <AuthStackParamsContext.Provider value={route.params}>
            <AuthStackNav.Navigator
                screenOptions={{
                    headerShown: false,
                }}
            >
                {/* <AuthStack.Screen name="Login" component={Login} /> */}

                <AuthStackNav.Screen name="Login" component={LoginScreen} initialParams={{ prev: route.params?.prev }} />
                <AuthStackNav.Screen name="ForgetPassword" component={ForgetPasswordScreen} />

                {/* <AuthStackNav.Screen name="LoginReset" component={LoginResetScreen} /> */}
            </AuthStackNav.Navigator>
        </AuthStackParamsContext.Provider>
    )
}
