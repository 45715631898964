import React, { useContext } from "react"

export type TeacherTokenPayload = {
    user: {
        id: number
        firstName: string
        lastName: string
        email: string
        gender: string
    }
    userType: "teacher"
    scopes: string[]
    iat: number
    exp: number
}

type GenericAuthState<P> = Partial<{
    token: string
    tokenPayload: P
    userId: number
    // username: string
}>

type GenericAuthContextValue<P> = GenericAuthState<P> & {
    isLoggedIn: boolean
    isTokenExpired: boolean
    isLoginDialogOpen: boolean
    hideLoginDialog(): void
    setToken(token: string): void
    eraseToken(): void
    ensureValidToken(): Promise<void>
    autofillRef: React.MutableRefObject<{
        email: string
        password: string
    } | null>
}

export type AuthContextValue = GenericAuthContextValue<TeacherTokenPayload>

export type LocalStorageAuthData = GenericAuthState<TeacherTokenPayload>

export const AuthContext = React.createContext<GenericAuthContextValue<TeacherTokenPayload>>(null as any)

export function useAuthContext() {
    return useContext(AuthContext)
}
