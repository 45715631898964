import { VStack } from "native-base"
import type { IVStackProps } from "native-base/lib/typescript/components/primitives/Stack/VStack"
import type { ReactNode } from "react"
import { ScrollView, type ScrollViewProps, type StyleProp, type ViewStyle } from "react-native"

export type ScrollViewGrowProps = {
    children: ReactNode | ReactNode[]
    noRender?: boolean
    flexGrow?: number
    contentContainerStyle?: StyleProp<ViewStyle>
    style?: StyleProp<ViewStyle>
    refreshControl?: ScrollViewProps["refreshControl"]
} & Partial<Pick<IVStackProps, "space" | "padding" | "p" | "pb" | "pr" | "pt" | "pl" | "px" | "py" | "padding" | "paddingBottom" | "paddingRight" | "paddingTop" | "paddingLeft" | "paddingX" | "paddingY">>

export function ScrollViewGrow(props: ScrollViewGrowProps) {
    const { children, noRender, flexGrow = 1, style, contentContainerStyle, refreshControl, ...stackProps } = props

    if (noRender) return null
    return (
        <ScrollView style={{ width: "100%", height: 0, ...(style as object) }} contentContainerStyle={{ flexGrow, width: "100%", ...(contentContainerStyle as object), alignItems: "stretch" }} refreshControl={refreshControl}>
            <VStack {...stackProps}>{children}</VStack>
        </ScrollView>
    )
}
