import { Center, Heading, HStack, Spinner } from "native-base"
import React from "react"

export interface LoadingScreenProps {
    message?: string
}
export function LoadingScreen(props: LoadingScreenProps) {
    const { message = "Chargement" } = props

    return (
        <Center
            // _dark={{ bg: "blueGray.900" }}
            // _light={{ bg: "blueGray.50" }}
            px={4}
            flex={1}
        >
            <HStack space={2} justifyContent="center">
                <Spinner accessibilityLabel="Loading posts" />
                <Heading color="primary.500" fontSize="md">
                    {message}
                </Heading>
            </HStack>
        </Center>
    )
}
