import { Lesson, LessonStatusEntries, rasmik } from "@core/services/rasmik"
import { groupBy, isNil, sortBy, sumBy } from "lodash"

import { defineQuery } from "./utils"

export const match_history_query = defineQuery("matchHistoryQuery", ({ matchId }: { matchId: number | null | undefined }) => ({
    key: ["match_history", { matchId }],
    queryFn: async (qryCtx, auth) => {
        console.debug("matchHistoryQuery")
        await auth!.ensureValidToken()

        const lessons = await rasmik
            .readMany(Lesson)
            .where({ Match: matchId, status: [LessonStatusEntries.SUBMITTED, LessonStatusEntries.PAYSLIPPED] })
            .options({ include: ["*", "monthOfficialFormula"] })
            .run()

        const byMonthGroups = groupBy(lessons, (lsn) => lsn.monthOfficialFormula)
        const byMonthItems = Object.keys(byMonthGroups)
            .sort()
            .reverse()
            .map((month) => {
                let monthLessons = byMonthGroups[month]
                monthLessons = sortBy(monthLessons, (lsn) => lsn.startedAtOfficial)
                const monthSum = sumBy(monthLessons, (lsn) => lsn.durationMin ?? 0)
                return {
                    month,
                    lessons: monthLessons,
                    monthSum,
                }
            })

        return byMonthItems
    },
    defaultOptions: { enabled: !isNil(matchId), useErrorBoundary: true, initialData: [] },
}))

export type MatchHistoryQueryData = (typeof match_history_query)["dataShape"]
