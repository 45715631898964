import { useAuthContext } from "@core/context/auth"
import { useCatchToBoundary, useSpinner } from "@core/hooks"
import { ContentContainer } from "@core/misc-components/ContentContainer"
import { user_info_query } from "@core/queries"
import { rasmik, Teacher } from "@core/services/rasmik"
import { useEzActions } from "@core/store"
import { goBack } from "@core/utils/navigation/go-back"
import { FontAwesome5, MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons"
import type { StackScreenProps } from "@react-navigation/stack"
import { Box, Button, Center, Heading, HStack, Icon, Text, useColorModeValue, useToken } from "native-base"

import type { MainStackParamsList } from "./MainStack"

export function TutoScreen(props: StackScreenProps<MainStackParamsList, "Tuto" | "Tuto_Auto">) {
    const {
        navigation,
        route: { params },
    } = props

    const setFinishedTuto = useEzActions((s) => s.persisted.setFinishedTuto)

    const [lightText, darkText] = useToken("colors", ["lightText", "darkText"])
    const iconColor = useColorModeValue(darkText, lightText)

    const auth = useAuthContext()

    const { userInfoQuery } = user_info_query.use({ teacherId: auth.userId })

    const spinner = useSpinner()
    const handleSubmitViewedTuto = useCatchToBoundary(async () => {
        try {
            spinner.start()
            await rasmik.pushOne(Teacher).pushDef({ allow: "update" }).data({ id: auth.userId, tutoViewedAt: new Date() }).run()
            await userInfoQuery.refetch()
            goBack(navigation, "MainTabs")
        } finally {
            spinner.stop()
        }
    })

    return (
        <Center flexGrow={1}>
            <ContentContainer>
                <Center>
                    <Heading fontSize="xl" mb="15px">
                        Mode d'emploi
                    </Heading>
                </Center>

                <Box p="20px" _dark={{ bgColor: "dark.200" }} _light={{ bgColor: "light.300" }} borderRadius="15px">
                    <HStack alignItems="center" my="10px">
                        <HStack width="40px" justifyContent="center">
                            <FontAwesome5 name="handshake" style={{ color: iconColor, fontSize: 20 }} />
                        </HStack>
                        <Text flex={1} lineHeight="sm">
                            Consulte les infos de tes éléves et l'historique des cours dans la liste des matchs
                        </Text>
                    </HStack>

                    <HStack alignItems="center" my="10px">
                        <HStack width="40px" justifyContent="center">
                            <MaterialCommunityIcons name="bell-alert" style={{ color: iconColor, fontSize: 20 }} />
                        </HStack>
                        <Text flex={1} lineHeight="sm">
                            Accède aux nouvelles propositions à valider depuis les notifications
                        </Text>
                    </HStack>

                    <HStack alignItems="center" my="10px">
                        <HStack width="40px" justifyContent="center">
                            <FontAwesome5 name="calendar-alt" style={{ color: iconColor, fontSize: 20 }} />
                        </HStack>
                        <Text flex={1} lineHeight="sm">
                            Après chaque cours, transmets tes heures depuis l'onglet saisie
                        </Text>
                    </HStack>

                    <HStack alignItems="center" my="10px">
                        <HStack width="40px" justifyContent="center">
                            <FontAwesome5 name="file-signature" style={{ color: iconColor, fontSize: 20 }} />
                        </HStack>
                        <Text flex={1} lineHeight="sm">
                            A la fin de chaque mois, complète pour chaque élève un bilan
                        </Text>
                    </HStack>

                    <HStack alignItems="center" my="10px">
                        <HStack width="40px" justifyContent="center">
                            <FontAwesome5 name="download" style={{ color: iconColor, fontSize: 20 }} />
                        </HStack>
                        <Text flex={1} lineHeight="sm">
                            Accède a tes infos et télécharge tes bulletins de paie depuis l'onglet profil
                        </Text>
                    </HStack>

                    <HStack alignItems="center" mt="5px">
                        <HStack width="40px" justifyContent="center">
                            <FontAwesome5 name="hand-point-right" style={{ color: iconColor, fontSize: 20 }} />
                        </HStack>
                        <Text flex={1} lineHeight="sm">
                            Il te faudra compléter le questionnaire du bilan mensuel, que tu aies donné des cours dans le mois ou non
                        </Text>
                    </HStack>

                    <HStack alignItems="center" mt="20px">
                        <HStack width="40px" justifyContent="center">
                            <FontAwesome5 name="lightbulb" style={{ color: iconColor, fontSize: 20 }} />
                        </HStack>
                        <Text flex={1} lineHeight="sm">
                            Si tu as cessé les cours avec un élève, tu pourras nous en informer dans le bilan mensuel
                        </Text>
                    </HStack>

                    <HStack alignItems="center" my="10px">
                        <HStack width="40px" justifyContent="center">
                            <FontAwesome5 name="exclamation-triangle" style={{ color: iconColor, fontSize: 20 }} />
                        </HStack>
                        <Text flex={1} lineHeight="sm">
                            Il n'est pas possible de saisir des heures de cours rétroactivement, c'est pourquoi elles doivent être renseignées le jour même
                        </Text>
                    </HStack>
                </Box>

                <Button isLoading={spinner.loading} colorScheme="primary" mt="15px" onPress={handleSubmitViewedTuto}>
                    J'ai compris
                </Button>
            </ContentContainer>
        </Center>
    )
}
