import { Card, ScrollViewGrow } from "@core/atoms"
import { useAuthContext } from "@core/context/auth"
import { type DialogAwaitIO, useDialogAwait } from "@core/hooks/useDialog"
import { ContentContainerColumn, ContentContainerRow } from "@core/misc-components/ContentContainer"
import { AppBar } from "@core/molecules/AppBar"
import { LoadingScreen } from "@core/organism/LoadingScreen"
import { match_infos_list_query } from "@core/queries"
import { getShowedMonthDt } from "@core/utils"
import { goBack } from "@core/utils/navigation/go-back"
import { toFrenchRelativeCalendar } from "@core/utils/toFrenchRelativeCalendar"
import { type NavigationProp, useNavigation } from "@react-navigation/native"
import type { StackScreenProps } from "@react-navigation/stack"
import { DateTime } from "luxon"
import { Box, Center, Heading, HStack, Stack, Text, useToken, VStack } from "native-base"
import React, { useCallback } from "react"

import type { MainStackParamsList } from "../MainStack"
import { AddLessonModal } from "./AddLessonModal"

export function AddLessonSelectMatchScreen(props: StackScreenProps<MainStackParamsList, "AddLessonSelectMatch">) {
    const {
        navigation,
        route: { params },
    } = props

    const [lightText, darkText] = useToken("colors", ["lightText", "darkText"])
    const auth = useAuthContext()
    const { matchInfosListQuery } = match_infos_list_query.use({ teacherId: auth.userId })
    const hasNoMatches = !matchInfosListQuery.data?.currentMonthMatches?.length

    return (
        <VStack safeAreaBottom width="100%" h="100%" display="flex">
            <AppBar onBack={() => goBack(navigation, "MainTabs", { screen: "SaisieHeures" })}>
                <ContentContainerRow flex={1}>
                    <Heading size="md">Déclarer un cours pour</Heading>
                </ContentContainerRow>
            </AppBar>

            {hasNoMatches && (
                <Center flexGrow={1}>
                    <NoMatchesMessage />
                </Center>
            )}

            {!hasNoMatches && (
                <ScrollViewGrow>
                    <ContentContainerColumn space="15px" paddingY="15px">
                        {matchInfosListQuery.data?.currentMonthMatches?.map((match) => <MatchCard key={match.id} matchId={match.id} />)}
                    </ContentContainerColumn>
                </ScrollViewGrow>
            )}
        </VStack>
    )
}

export function MatchCard({ matchId }: { matchId: number }) {
    const navigation = useNavigation<NavigationProp<MainStackParamsList, "AddLessonSelectMatch">>()
    const addLessonDialog = useDialogAwait<DialogAwaitIO<{ outcome: void; payload: void; status: "success" | "cancel" }>>()

    const startAddLesson = useCallback(async () => {
        const res = await addLessonDialog.open(undefined)
        if (res.status === "success") {
            goBack(navigation, "MainTabs", { screen: "SaisieHeures" })
        }
    }, [addLessonDialog, navigation])

    const auth = useAuthContext()
    const teacherId = auth.userId

    const { matchInfosListQuery } = match_infos_list_query.use({ teacherId })
    const matchInfo = matchInfosListQuery.helpers?.findById?.(matchId)

    if (!matchInfo) return <LoadingScreen />

    // const hasPropositionLessons = !!propositionLessons.length
    return (
        <Card onPress={startAddLesson} style={{ position: "relative" }}>
            <AddLessonModal dialogLogic={addLessonDialog} matchId={matchId} />

            <Stack>
                <Stack /* p="4" */ space={3}>
                    <HStack alignItems="center" justifyContent="space-between">
                        <Stack space={2}>
                            <Heading size="md">
                                {matchInfo.Program.Learner.firstName} {matchInfo.Program.Learner.lastName}
                            </Heading>
                            <Text fontSize="xs" _light={{ color: "primary.500" }} _dark={{ color: "primary.400" }} fontWeight="500" mt="-1">
                                {matchInfo.label}
                            </Text>
                        </Stack>
                    </HStack>

                    <Text fontSize="xs" color="coolGray.600" _dark={{ color: "warmGray.200" }} fontWeight="400">
                        {matchInfo.lastLessonAt ? `Dernier cours ${toFrenchRelativeCalendar(DateTime.fromJSDate(matchInfo.lastLessonAt))}` : "Nouveau"}
                    </Text>
                </Stack>
            </Stack>
        </Card>
    )
}

function NoMatchesMessage() {
    return (
        <ContentContainerColumn paddingY="15px" alignItems="center" flex={1}>
            <Center flexGrow={1}>
                <Box p="20px" borderRadius="10px" _light={{ bgColor: "light.200" }} _dark={{ bgColor: "dark.200" }}>
                    <Text textAlign="center" _light={{ color: "light.700" }} _dark={{ color: "dark.700" }}>
                        Aucun match actif pour lequel déclarer des heures en {getShowedMonthDt().toFormat("MMMM yyyy")}.
                    </Text>
                </Box>
            </Center>
        </ContentContainerColumn>
    )
}
