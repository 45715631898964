import type { StackScreenProps } from "@react-navigation/stack"
import { useEffect } from "react"

import type { MainStackParamsList } from "../MainStack"
import { useSplashScreenNotifications } from "./useSplashScreenNotifications"

export function SplashNotificationsScreen(props: StackScreenProps<MainStackParamsList, "SplashNotifications">) {
    const {
        navigation,
        route: { params },
    } = props

    const { currentType, element } = useSplashScreenNotifications(navigation)

    useEffect(() => {
        if (!currentType) {
            navigation.navigate("MainTabs")
        }
    })

    return element
}
