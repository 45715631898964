// import { useSnackbar } from 'notistack';
import { type DependencyList, useCallback } from "react"
import { useErrorBoundary } from "react-error-boundary"

type Callback = (...args: any[]) => any

/**
 * Creates a hook that will forward any errors to the given error handler
 */
export function creatUseCatchHook(handler: (err: any) => void) {
    return function useCatch<C extends Callback>(callback: C, deps?: DependencyList): C {
        const wrappedCallback = (...args: any[]) => {
            try {
                const res = callback(...args)
                if (res instanceof Promise) {
                    res.catch((err) => {
                        handler(err)
                    })
                }
            } catch (err) {
                handler(err)
            }
        }

        if (deps) {
            //eslint-disable-next-line
            return useCallback(wrappedCallback, deps) as C
        } else {
            return wrappedCallback as C
        }
    }
}

/**
 * Spécifique au projet
 */
export function useCatchToBoundary<C extends Callback>(callback: C, deps?: DependencyList): C {
    const { showBoundary: forwardErrorToClosestErrorBoundary } = useErrorBoundary()
    const catchHook = creatUseCatchHook(forwardErrorToClosestErrorBoundary)
    return catchHook(callback, deps)
}

// /**
//  * Sprécifique au projet
//  */
// export function useCatchToSnackbar(callback: Callback, deps?: DependencyList){
//     const { enqueueSnackbar } = useSnackbar()

//     const handler = (err:Error)=>{
//         console.error(err)
//         enqueueSnackbar("Une erreur imprévue s'est produite", { variant: 'error' })
//     }

//     const catchHook = creatUseCatchHook(handler)
//     return catchHook(callback, deps)
// }
