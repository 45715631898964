import axios, { type AxiosInstance, type AxiosRequestConfig } from "axios"

export class AxiosWithInterceptors {
    unpackData: boolean
    unpackErrors: boolean
    reviveDates: boolean
    ensureToken: boolean
    setHeaders: boolean
    constructor(config: Partial<Pick<AxiosWithInterceptors, "unpackData" | "unpackErrors" | "reviveDates" | "ensureToken" | "setHeaders">> = {}) {
        this.unpackData = config.unpackData ?? true
        this.unpackErrors = config.unpackErrors ?? true
        this.reviveDates = config.reviveDates ?? true
        this.ensureToken = config.ensureToken ?? true
        this.setHeaders = config.setHeaders ?? true
    }

    private headerFn: (configHeaders: AxiosRequestConfig["headers"]) => AxiosRequestConfig["headers"] | Promise<AxiosRequestConfig["headers"]> = (configHeaders) => configHeaders
    private ensureTokenFn?: (reqConfig: AxiosRequestConfig) => any

    setHeaderFn(fn: { (configHeaders: AxiosRequestConfig["headers"]): AxiosRequestConfig["headers"] | Promise<AxiosRequestConfig["headers"]> }) {
        this.headerFn = fn
        return this
    }

    setEnsureTokenFn(fn: (reqConfig: AxiosRequestConfig) => any) {
        this.ensureTokenFn = fn
        return this
    }

    createAxiosInstance(config?: AxiosRequestConfig) {
        const instance = axios.create(config)
        return this.configureInstance(instance)
    }

    configureInstance(axiosInstance: AxiosInstance) {
        const instance = axiosInstance

        //throw err.response.data insteat of err
        if (this.unpackErrors) {
            instance.interceptors.response.use(
                (res) => res,
                (err) => {
                    if (axios.isAxiosError(err) && err.response && err.response.data) {
                        throw err.response.data
                    } else {
                        throw err
                    }
                }
            )
        }

        //return res.data instead of res
        if (this.unpackData) {
            instance.interceptors.response.use((res) => {
                if (res.data) return res.data
                else return res
            })
        }

        //set headers before each request
        if (this.setHeaders) {
            instance.interceptors.request.use(async (config) => {
                //@ts-ignore
                config.headers = await this.headerFn(config.headers!)
                return config
            })
        }

        if (this.ensureToken) {
            //ensure a token is in the storage (must be last registered interceptor to be executed first)
            instance.interceptors.request.use(async (config) => {
                if (this.ensureTokenFn) await this.ensureTokenFn(config)
                return config
            })
        }

        if (this.reviveDates) {
            instance.interceptors.response.use((res) => {
                if (res.request && res.request instanceof XMLHttpRequest) {
                    if (!res.data) return res
                    const revivedData = handleDates(res.data)
                    res.data = revivedData
                    return res
                } else {
                    const data = res
                    if (!data) return data
                    const revivedData = handleDates(data)
                    return revivedData
                }
            })
        }

        return instance
    }
}

//date reviver
const isoDateFormat =
    /^(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))$/
const isIsoDateString = (val: any) => isoDateFormat.test(val)

function handleDates(body: any) {
    if (body === null || body === undefined || typeof body !== "object") return body

    const obj = body
    for (const key in obj) {
        const value = obj[key]
        if (Array.isArray(value)) obj[key] = value.map((item) => handleDates(item))
        else if (typeof value === "object") obj[key] = handleDates(value)
        else if (typeof value === "string" && isIsoDateString(value)) obj[key] = new Date(value)
    }
    return obj
}
