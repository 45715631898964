import { QueryClient, type QueryKey, type Updater, useQuery, useQueryClient, type UseQueryOptions, type UseQueryResult } from "@tanstack/react-query"
import { useCallback } from "react"

type UseQueryHook<T> = (queryKey: QueryKey, queryFn: () => any, options?: UseQueryOptions<any>) => UseQueryResult<T>

export type UseQueryWithClientHook<T> = (queryKey: QueryKey, queryFn: () => any, options?: UseQueryOptions<any>) => UseQueryWithClientHookResult<T>

export type UseQueryWithClientHookResult<T> = UseQueryResult<T> & {
    queryKey: QueryKey
    queryClient: QueryClient
    helpers: {
        invalidate: () => void
        setData: (newData: Updater<T | undefined, T>) => T
    }
}

export function useQueryWithClient<T>(queryKey: QueryKey, queryFn: () => T | Promise<T>, options?: UseQueryOptions<T>, useQueryHook: UseQueryHook<T> = useQuery) {
    const queryClient = useQueryClient()
    const useQueryOutput = useQueryHook(queryKey, queryFn, options)

    const setData = useCallback(
        (newData: Updater<T | undefined, T>) => {
            return queryClient.setQueryData<T>(queryKey, newData)
        },
        [queryClient, queryKey]
    )

    const invalidate = useCallback(() => {
        queryClient.invalidateQueries(queryKey)
    }, [queryClient, queryKey])

    return {
        queryKey,
        queryClient,
        ...useQueryOutput,
        helpers: {
            ...((useQueryOutput as any).helpers as object),
            invalidate,
            setData,
        },
    }
}
