import { AlertDialog, Button, VStack } from "native-base"
import { useRef } from "react"

type OnCloseDialogProps = {
    isOpen: boolean
    onCancel: () => void
    onProceed: () => void
}

export function OnCloseDialog(props: OnCloseDialogProps) {
    const cancelRef = useRef(null)

    return (
        <AlertDialog leastDestructiveRef={cancelRef} isOpen={props.isOpen} onClose={props.onCancel}>
            <AlertDialog.Content>
                <AlertDialog.CloseButton />
                <AlertDialog.Header>Quitter sans sauvegarder</AlertDialog.Header>
                <AlertDialog.Body>Vous avez des données non sauvegardées, êtes vous sûr de vouloir quitter ?</AlertDialog.Body>
                <AlertDialog.Footer justifyContent="center">
                    <VStack space={2}>
                        <Button variant="unstyled" colorScheme="coolGray" onPress={props.onProceed}>
                            Quitter sans sauvegarder
                        </Button>
                        <Button variant="solid" colorScheme="pedagome" onPress={props.onCancel} ref={cancelRef}>
                            Rester sur la page
                        </Button>
                    </VStack>
                </AlertDialog.Footer>
            </AlertDialog.Content>
        </AlertDialog>
    )
}
