import { useAuthContext } from "@core/context/auth"
import type { QueryFunction, QueryFunctionContext, QueryKey, UseQueryOptions } from "@tanstack/react-query"
import { find, type ListIterateeCustom } from "lodash"

import { useQueryWithClient, type UseQueryWithClientHookResult } from "../hooks/useQueryWithClient"

type QueryFunctionAuth<T = unknown, TQueryKey extends QueryKey = QueryKey> = (queryContext: QueryFunctionContext<TQueryKey>, auth?: ReturnType<typeof useAuthContext>) => T | Promise<T>

export type QueryFunctionResult<FN extends QueryFunction> = ReturnType<FN> extends Promise<infer U> ? U : ReturnType<FN>

type QueryDef<QFN extends QueryFunctionAuth> = {
    queryFn: QFN
    key: QueryKey
    defaultOptions?: UseQueryOptions<QueryFunctionResult<QFN>>
}

// type QueryDefH<QFN extends QueryFunction, H> = QueryDef<QFN> & {
//     getHelpers?: (query: UseQueryWithClientHookResult<QueryFunctionResult<QFN>>) => H
// }

const GLOBAL_DEFAULT_OPTIONS: UseQueryOptions<any> = {
    //pas de nouvelle requète si déjà fait dans la seconde précédente
    staleTime: 1000,

    //Sinon, initialData est considéré comme valide et les données ne sont jamais fetch
    initialDataUpdatedAt: 0,
}

export function defineQuery<N extends string, DefArgs extends object, QFN extends QueryFunctionAuth>(queryName: N, defFactory: (args: DefArgs) => QueryDef<QFN>) {
    const queryDef = {
        getKey: (args: DefArgs) => defFactory(args).key,
        getQueryFn: (args: DefArgs) => defFactory(args).queryFn,
        getDefaultOptions: (args: DefArgs) => defFactory(args).defaultOptions,
        use: (args: DefArgs, options?: UseQueryOptions<QueryFunctionResult<QFN>>) => {
            //eslint-disable-next-line
            const auth = useAuthContext()
            const def = defFactory(args)
            //eslint-disable-next-line
            const query = useQueryWithClient<QueryFunctionResult<QFN>>(def.key, ((qryCtx: any) => def.queryFn(qryCtx, auth)) as any, { ...GLOBAL_DEFAULT_OPTIONS, ...def.defaultOptions, ...options })
            //@ts-ignore
            def.getHelpers && Object.assign(query.helpers, def.getHelpers(query as any))
            return { [queryName as N]: query } as any as {
                [K in N]: UseQueryWithClientHookResult<QueryFunctionResult<QFN>>
            }
        },
        dataShape: undefined as QueryFunctionResult<QFN>,
    }

    return {
        ...queryDef,
        withHelpers<H extends object>(
            getHelpers: (query: UseQueryWithClientHookResult<QueryFunctionResult<QFN>>) => H
        ): Omit<typeof queryDef, "use"> & {
            use: (args: DefArgs, options?: UseQueryOptions<QueryFunctionResult<QFN>>) => { [K in N]: UseQueryWithClientHookResult<QueryFunctionResult<QFN>> & { helpers: H } }
        } {
            const defFactory2 = (args: DefArgs) => {
                const def = {
                    ...defFactory(args),
                    getHelpers,
                }
                return def
            }

            return defineQuery(queryName, defFactory2) as any // as Omit<typeof queryDef,'use'> & {use: (args: DefArgs, options?: UseQueryOptions<QueryFunctionResult<QFN>>) =>  { [K in N]: UseQueryWithClientHookResult<QueryFunctionResult<QFN>> & {helpers: H}} } // as
        },
    }
}

export function createCustomArray<T, U extends object>(array: T[], utils: U): T[] & Partial<U> {
    //@ts-ignore
    Object.assign(array, utils)
    return array as T[] & Partial<U>
}

export function arrayQueryHelpers<ITD>(query: UseQueryWithClientHookResult<ITD[]>) {
    const add = (item: ITD) => {
        query.queryClient.setQueryData<ITD[]>(query.queryKey, (data) => {
            const copy = [...(data ?? []), item]
            return copy
        })
    }

    const remove = (id: string | number | ((item: ITD) => boolean)) => {
        query.queryClient.setQueryData<ITD[]>(query.queryKey, (data) => {
            if (!data) return []

            if (typeof id === "function") {
                return data.filter(id)
            } else {
                //@ts-ignore
                return data.filter((item) => item.id === id)
            }
        })
    }

    const replace = (id: string | number | ((item: ITD) => boolean), newItem: ITD) => {
        query.queryClient.setQueryData<any[]>(query.queryKey, (data) => {
            if (!data) return []

            const copy = [...data]

            let index
            if (typeof id === "function") {
                index = copy.findIndex(id)
            } else {
                index = copy.findIndex((item) => item.id === id)
            }

            copy.splice(index, 1, newItem)
            return copy
        })
    }

    const patch = (id: string | number | ((item: ITD) => boolean), partialItem: Partial<ITD>) => {
        query.queryClient.setQueryData<any[]>(query.queryKey, (data) => {
            if (!data) return []

            const copy = [...data]

            let index
            if (typeof id === "function") {
                index = copy.findIndex(id)
            } else {
                index = copy.findIndex((item) => item.id === id)
            }
            const oldItem = copy[index]

            const newItem = Object.assign({}, oldItem, partialItem)
            copy.splice(index, 1, newItem)
            return copy
        })
    }

    const findByIndex = (idx: number) => {
        return query.data?.at(idx)
    }

    const findBy = (predicate: ListIterateeCustom<ITD, boolean>) => find(query.data, predicate)

    return {
        add,
        remove,
        replace,
        patch,
        findBy,
    }
}

//        use: { [queryName]: (args: DefArgs, options?: UseQueryOptions<QueryFunctionResult<QFN>>) => useQueryWithClient<QueryFunctionResult<QFN>>(defFactory(...args).key, defFactory(...args).queryFn as any, { ...defFactory(...args).defaultOptions, ...options }) } as unknown as {[K in N]:  (args: DefArgs, options?: UseQueryOptions<QueryFunctionResult<QFN>>) => UseQueryWithClientHookResult<QueryFunctionResult<QFN>>},

// export function defineQuery<DefArg extends any, QFN extends QueryFunction>(defFactory: (arg:DefArg)=>QueryDef<QFN>){
//     return {
//         getKey: (arg:DefArg)=>defFactory(arg).key,
//         getQueryFn: (arg:DefArg)=>defFactory(arg).queryFn,
//         getDefaultOptions: (arg:DefArg)=>defFactory(arg).defaultOptions,
//         use: (arg:DefArg,options?: UseQueryOptions<QueryFunctionResult<QFN>>)=>useQueryWithClient<QueryFunctionResult<QFN>>(defFactory(arg).key, defFactory(arg).queryFn as any,{...defFactory(arg).defaultOptions, ...options}),
//         dataShape: undefined as QueryFunctionResult<QFN>

//     }
// }
