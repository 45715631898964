import { Card, ScrollViewGrow } from "@core/atoms"
import { WarningAlert } from "@core/atoms/WarningAlert"
import { useAuthContext } from "@core/context/auth"
import { type DialogAwaitIO, useBoolean, useCatchToBoundary, useDialogAwait, useSpinner } from "@core/hooks"
import { ContentContainerColumn, ContentContainerRow } from "@core/misc-components/ContentContainer"
import { AppBar } from "@core/molecules/AppBar"
import { user_info_query, type UserInfoQueryData } from "@core/queries"
import { rasmik, Teacher } from "@core/services/rasmik"
import { displayDateRange, displayNextYearDispoAt, isDispoNextYear } from "@core/utils"
import { FontAwesome5 } from "@expo/vector-icons"
import { type NavigationProp, useNavigation } from "@react-navigation/native"
import type { StackScreenProps } from "@react-navigation/stack"
import { Button, Center, Heading, HStack, Icon, Switch, Text, VStack } from "native-base"
import React, { useCallback, useEffect } from "react"

import type { MainStackParamsList } from "../MainStack"
import { YearDispoPicker } from "./YearDispoPicker"

export type Dispo = {
    id: number
    dayId: number
    startTime: string
    endTime: string
}

export function EditYearDispoFormScreen({ navigation }: StackScreenProps<MainStackParamsList, "EditYearDispoForm">) {
    return (
        <VStack space={2} h="100%">
            <AppBar onBack={() => navigation.navigate("MainTabs", { screen: "User" })}>
                <ContentContainerRow flex={1}>
                    <Heading size="md">Mes disponibilités annuelles</Heading>
                </ContentContainerRow>
            </AppBar>
            <ScrollViewGrow>
                <ContentContainerColumn>
                    <EditYearDispoForm navigation={navigation} />
                </ContentContainerColumn>
            </ScrollViewGrow>
        </VStack>
    )
}

export type YearDispoDisplayProps = {
    navigation: NavigationProp<MainStackParamsList>
    isSplashScreen?: boolean
    onSnooze?(): void
}
export function EditYearDispoForm(props: YearDispoDisplayProps) {
    const navigation = useNavigation<NavigationProp<MainStackParamsList, "EditYearDispoForm">>()
    const spinner = useSpinner()
    const auth = useAuthContext()
    const { userInfoQuery } = user_info_query.use({ teacherId: auth.userId })

    const handleSubmit = useCatchToBoundary(async () => {
        try {
            spinner.start()
            await auth.ensureValidToken()

            await rasmik
                .pushOne(Teacher)
                .pushDef({
                    allow: "update",
                })
                .data({ id: auth.userId, yearDispoLastUpdatedAt: new Date(), dispoNextYearAt: new Date() })
                .run()

            await userInfoQuery.refetch()
        } finally {
            spinner.stop()
            if (!props.isSplashScreen) {
                navigation.navigate("MainTabs", { screen: "User" })
            }
        }
    })

    return (
        <>
            <YearDispoTable />

            {props.isSplashScreen ? (
                <HStack padding={2} justifyContent="right" h="60px" space={2}>
                    <Button variant="solid" colorScheme="gray" onPress={() => props.onSnooze?.()} leftIcon={<Icon as={FontAwesome5} name="clock" size="xs" />}>
                        Plus tard
                    </Button>
                    <Button isLoading={spinner.loading} colorScheme="pedagome" onPress={() => handleSubmit()} leftIcon={<Icon as={FontAwesome5} name="save" size="xs" />}>
                        Enregistrer
                    </Button>
                </HStack>
            ) : (
                <Center /* backgroundColor={logic.barBg} shadow="1"  */ h="60px">
                    <Button isLoading={spinner.loading} colorScheme="pedagome" onPress={() => handleSubmit()} leftIcon={<Icon as={FontAwesome5} name="save" size="xs" />}>
                        Enregistrer
                    </Button>
                </Center>
            )}
        </>
    )
}

export function YearDispoTable() {
    const auth = useAuthContext()
    const { userInfoQuery } = user_info_query.use({ teacherId: auth.userId })

    const yearDispoDialog = useDialogAwait<DialogAwaitIO<{ outcome: void; payload: UserInfoQueryData["YearDispos"][number] | null; status: "success" | "cancel" }>>()

    const startAddYearDispo = useCallback(async () => {
        const res = await yearDispoDialog.open(null)
    }, [yearDispoDialog])

    const startEditYearDispo = useCallback(
        async (yearDispo: UserInfoQueryData["YearDispos"][number]) => {
            const res = await yearDispoDialog.open(yearDispo)
        },
        [yearDispoDialog]
    )

    const [isChecked, { toggle: toggleisChecked, setValue: setIsChecked }] = useBoolean(false)

    useEffect(() => {
        setIsChecked(isDispoNextYear(userInfoQuery.data!))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInfoQuery.data?.dispoNextYearAt?.toISOString(), setIsChecked])

    const spinner = useSpinner()

    const handleSubmitSwitch = useCatchToBoundary(async (newIsChecked: boolean) => {
        try {
            spinner.start()
            await auth.ensureValidToken()

            await rasmik
                .pushOne(Teacher)
                .pushDef({
                    allow: "update",
                })
                .data({ id: auth.userId, dispoNextYearAt: new Date(), dispoNextYear: newIsChecked })
                .run()

            await userInfoQuery.refetch()
        } finally {
            spinner.stop()
        }
    })

    return (
        <VStack space={2} paddingTop="5">
            {userInfoQuery.helpers.currentYearDispos().length === 0 && <WarningAlert text="Aucune disponibilité renseignée pour cette année" />}
            {userInfoQuery.helpers.currentYearDispos().map((yearDispo) => (
                <Card key={yearDispo.id} onPress={() => startEditYearDispo(yearDispo)}>
                    <Text key={yearDispo.id}>{displayDateRange(yearDispo)}</Text>
                </Card>
            ))}
            <HStack mt="5" justifyContent="flex-end">
                <Button onPress={startAddYearDispo} leftIcon={<Icon as={FontAwesome5} name="plus" size="xs" />}>
                    Ajouter une période
                </Button>
            </HStack>
            <HStack mt="5">
                <Switch isChecked={isChecked} onToggle={(e) => handleSubmitSwitch(e)} marginRight="10px" />
                <Text>Disponible l'année prochaine ({displayNextYearDispoAt()})</Text>
            </HStack>
            <YearDispoPicker dialogLogic={yearDispoDialog} />
        </VStack>
    )
}
