import { Alert, Box, HStack, Text, VStack } from "native-base"

export function NotEnoughDispoWarning(props: { nbDispo: number }) {
    return (
        <>
            {props.nbDispo < 3 && (
                <Alert marginTop="5px" status="error" colorScheme="error">
                    <HStack space={1} alignItems="center" justifyContent="space-between">
                        <Alert.Icon />
                        <Box>
                            <Text>Renseigne encore {3 - props.nbDispo} créneaux</Text>
                        </Box>
                    </HStack>
                </Alert>
            )}
        </>
    )
}

export function ModifiedDataWarning() {
    return (
        <Box width="100%" textAlign="center" _text={{ color: "red.600" }}>
            Vous avez des informations non enregistrées
        </Box>
    )
}

export function InfoFooter() {
    return (
        <Alert status="info" variant="subtle" colorScheme="info" mt={4}>
            <VStack space={2} flexShrink={1} w="100%">
                <HStack flexShrink={1} space={2} alignItems="center" justifyContent="space-between">
                    <HStack flexShrink={1} space={2} alignItems="center">
                        <Alert.Icon />
                        <Text fontSize="md" fontWeight="medium">
                            Information
                        </Text>
                    </HStack>
                </HStack>
                <Text>Tu peux choisir entre 0 et 3 créneaux différents par jour.{"\n"}Il faut renseigner au minimum 3 créneaux sur la semaine.</Text>
            </VStack>
        </Alert>
    )
}
