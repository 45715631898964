import { useAuthContext } from "@core/context/auth"
import useLocalStorageSync from "@core/hooks/useLocalStorageSync"
import { openapi } from "@core/services/openapi"
import { FCMToken, rasmik } from "@core/services/rasmik"
import { type PermissionState, usePermission } from "@custom-react-hooks/use-permission"
import { type FirebaseApp, initializeApp } from "firebase/app"
import { getMessaging, getToken, type Messaging } from "firebase/messaging"
import { DateTime } from "luxon"
import { createContext, type Dispatch, type PropsWithChildren, useContext, useState } from "react"

export const NotificationIsSupported = !!(
    ((window && (window.Notification /* W3C Specification */ || "webkitNotifications" in window)) /* old WebKit Browsers */ || (navigator && "mozNotification" in navigator)) /* Firefox for Android and Firefox OS */
)

export type FCMStored = {
    date: string
    token: string
}

type FirebaseCtx = { app: FirebaseApp; messaging: Messaging }
export const FirebaseContext = createContext<{
    state: FirebaseCtx
    setState: Dispatch<FirebaseCtx>
    permission: UseNotificationPermissionResult
    storageFCM: FCMStored | null
    setupFirebaseToken: () => Promise<{ fcm: FCMStored | null; permission: NotificationPermission }>
}>(null as any)
export function useFirebaseContext() {
    return useContext(FirebaseContext)
}

const VAPID_KEY = "BMuaL8BT5Lo70KXxk9hZgE_DRlst-i_V2iFCokKFGzbPqzaMpk7vUplRUaQs_4aAs5gTZODZLo7bGqEfSNA1CYw" //Public key https://console.firebase.google.com/u/0/project/constant-bolt-298108/settings/cloudmessaging
const STORAGE_KEY = "firebase-token"
const firebaseConfig = {
    apiKey: "AIzaSyADaOHnmV9vzKoEhcz6DiyYoLc6o83d0xY",
    authDomain: "constant-bolt-298108.firebaseapp.com",
    projectId: "constant-bolt-298108",
    storageBucket: "constant-bolt-298108.appspot.com",
    messagingSenderId: "890882344302",
    appId: "1:890882344302:web:5fd205d5e6486cce0ded7f",
}

export function useNotificationPermission() {
    const permission = usePermission("notifications") as Omit<PermissionState, "state"> & { state: NotificationPermission }
    return {
        ...permission,
        async request() {
            if (!NotificationIsSupported) return "denied"
            let state = permission.state
            if (state !== "granted") {
                state = await Notification.requestPermission()
            }
            return state
        },
    }
}
type UseNotificationPermissionResult = ReturnType<typeof useNotificationPermission>

export function FirebaseProvider(props: PropsWithChildren) {
    const permission = useNotificationPermission()

    const [storageFCM, setStorageFCM] = useLocalStorageSync<FCMStored | null>(STORAGE_KEY, null)

    const [state, setState] = useState<FirebaseCtx>(() => {
        const app = initializeApp(firebaseConfig)
        const messaging = getMessaging(app)
        return {
            app,
            messaging,
        }
    })

    const auth = useAuthContext()

    /** A appeler après chaque login ou lors de l'activation des notifs depuis les réglages. Si un token est déjà valide dans le local storage, aucune requête n'a lieu. */
    const setupFirebaseToken = async (): Promise<{ fcm: FCMStored | null; permission: NotificationPermission }> => {
        let currentToken = storageFCM ?? null

        await auth.ensureValidToken()
        const permissionState = await permission.request()
        if (permissionState !== "granted") return { permission: permissionState, fcm: currentToken }

        // return

        //Subscribes the Messaging instance. Returns an Firebase Cloud Messaging registration token that can be used to send push messages to that Messaging instance.
        //Ask for permission if not already granted

        //Récupération de la liste des tokens du user stocké dans le back
        const fcmTokens = await rasmik.readMany(FCMToken).where({ Teacher: auth.userId }).run()

        const isInDbList = (tokenToCompare: string) => {
            return fcmTokens!.some((element) => {
                return tokenToCompare === element.token
            })
        }

        //Token dans localStorage mais pas dans la liste => suppression du localStorage
        if (storageFCM && !isInDbList(storageFCM.token)) {
            setStorageFCM(null)
            currentToken = null
        }

        //Dans le cas ou il n'y as pas de token dans le localStorage, on en demande un a firebase
        if (!currentToken) {
            //GetToken du user + insertion dans la BDD
            const token = await getToken(state.messaging, { vapidKey: VAPID_KEY })
            console.debug("new firebase cloud messaging token :", token)
            await openapi.notifications.upsertTokenNotification({ token, projectName: "teacher-app", teacherId: auth.userId! })
            currentToken = { token, date: new Date().toISOString() }
            setStorageFCM(currentToken)
            return { permission: permissionState, fcm: currentToken }
        }

        // Comparaison pour savoir si le token a plus d'une semaine
        const tokenOlderThanOneWeek = DateTime.fromISO(currentToken.date).plus({ week: 1 }).diffNow().toMillis() < 0

        //Si le token actuel est plus vieux que 1 semaine
        if (tokenOlderThanOneWeek) {
            //GetToken du users
            const oldToken = currentToken.token

            //Demande d'un nouveau token à firebase + insertion dans la BDD
            const newToken = await getToken(state.messaging, { vapidKey: VAPID_KEY })
            currentToken = { token: newToken, date: new Date().toISOString() }
            setStorageFCM(currentToken)

            //Ajoute le nouveau token dans la BDD ou bien modifie la date de validité s'il existe déjà (on vient d'avoir la confirmation que le token est valide de la part de firebase)
            await openapi.notifications.upsertTokenNotification({ token: newToken, projectName: "teacher-app", teacherId: auth.userId! })

            // Si ancien token dans la liste de la BDD alors on supprime l'ancien token
            if (isInDbList(oldToken)) {
                await openapi.notifications.deleteTokenNotification({ tokenNotifications: oldToken })
            }
        }
        return { permission: permissionState, fcm: currentToken }
    }

    // useEffect(() => {
    //   // Listen for push notifications when the app is in the foreground
    //   const unsubscribe = messaging().onMessage(handlePushNotification);

    //   // Clean up the event listeners
    //   return () => {
    //     unsubscribe();
    //     notificationClickSubscription.remove();
    //   };
    // })

    return <FirebaseContext.Provider value={{ state, setState, permission, storageFCM: storageFCM ?? null, setupFirebaseToken }}>{props.children}</FirebaseContext.Provider>
}

//https://medium.com/@ashoniaa/react-native-expo-push-notifications-with-fcm-a-step-by-step-guide-fa5cfc0372fd
// const requestUserPermission = async () => {

//     let permissionStatus = Notification.permission
//     if (permissionStatus !== "granted") {
//         permissionStatus = await Notification.requestPermission();
//     }
//     const permissionGranted = permissionStatus === "granted"

//     return { permissionGranted, permissionStatus }
// };

// useEffect(() => {

// if (requestUserPermission()) {
//       messaging()
//         .getToken()
//         .then(
//           token => console.log(token)
//         );
//     }
//   }, []);

//   // Set up the notification handler for the app
//   Notifications.setNotificationHandler({
//     handleNotification: async () => ({
//       shouldShowAlert: true,
//       shouldPlaySound: true,
//       shouldSetBadge: false,
//     }),
//   });

//   // Handle user clicking on a notification and open the screen
//   const handleNotificationClick = async (response) => {
//     const screen = response?.notification?.request?.content?.data?.screen;
//     if (screen !== null) {
//       navigation.navigate(screen);
//     }
//   };

//   // Listen for user clicking on a notification
//   const notificationClickSubscription =
//     Notifications.addNotificationResponseReceivedListener(
//       handleNotificationClick
//     );

//   // Handle user opening the app from a notification (when the app is in the background)
//   messaging().onNotificationOpenedApp((remoteMessage) => {
//     console.log(
//       "Notification caused app to open from background state:",
//       remoteMessage.data.screen,
//       navigation
//     );
//     if (remoteMessage?.data?.screen) {
//       navigation.navigate(`${remoteMessage.data.screen}`);
//     }
//   });

//   // Check if the app was opened from a notification (when the app was completely quit)
//   messaging()
//     .getInitialNotification()
//     .then((remoteMessage) => {
//       if (remoteMessage) {
//         console.log(
//           "Notification caused app to open from quit state:",
//           remoteMessage.notification
//         );
//         if (remoteMessage?.data?.screen) {
//           navigation.navigate(`${remoteMessage.data.screen}`);
//         }
//       }
//     });

//   // Handle push notifications when the app is in the background
//   messaging().setBackgroundMessageHandler(async (remoteMessage) => {
//     console.log("Message handled in the background!", remoteMessage);
//     const notification = {
//       title: remoteMessage.notification.title,
//       body: remoteMessage.notification.body,
//       data: remoteMessage.data, // optional data payload
//     };

//     // Schedule the notification with a null trigger to show immediately
//     await Notifications.scheduleNotificationAsync({
//       content: notification,
//       trigger: null,
//     });
//   });

//   // Handle push notifications when the app is in the foreground
//   const handlePushNotification = async (remoteMessage) => {
//     const notification = {
//       title: remoteMessage.notification.title,
//       body: remoteMessage.notification.body,
//       data: remoteMessage.data, // optional data payload
//     };

//     // Schedule the notification with a null trigger to show immediately
//     await Notifications.scheduleNotificationAsync({
//       content: notification,
//       trigger: null,
//     });
//   };

//   // Listen for push notifications when the app is in the foreground
//   const unsubscribe = messaging().onMessage(handlePushNotification);

//   // Clean up the event listeners
//   return () => {
//     unsubscribe();
//     notificationClickSubscription.remove();
//   };
// }, []);
