import type { Address, PickData } from "@core/services/rasmik-client"
import { Linking, Platform } from "react-native"

import { conditional } from "./textUtils"

export function openAddressInMaps(address: Pick<Address, "coordinates" | "fullStreet" | "cityName" | "postalCode">, label?: string) {
    const scheme = Platform.select({ ios: "maps:0,0", android: "geo:0,0" })
    const latLng = `${address?.coordinates?.lat},${address?.coordinates?.lng}`
    const fullAddress = `${address.fullStreet} ${address.postalCode} ${address.cityName}`
    if (!label) label = address.fullStreet

    const url = Platform.select({
        ios: `${scheme}?q=${label} - ${fullAddress}&address=${fullAddress}${conditional(`&ll=${latLng}`, !!address?.coordinates)}`,
        android: `${scheme}?q=${address?.coordinates ? latLng : fullAddress}(${label} - ${fullAddress})`,
        web: `https://www.google.com/maps/place/${fullAddress}`.replace(/\s/g, "+"),
    })

    Linking.openURL(url!)
}

export function openPhoneNumber(number: string) {
    Linking.openURL(`tel:${number}`)
}
